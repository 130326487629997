import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import Sidebar from "./themes/default/sidebar/sidebar";
import Dashboard from "./components/Dashboard";
import Itr24 from "./components/Service/Itr24";
import GstRegistration from "./components/Service/GstRegistration";
import GstReturn from "./components/Service/GstReturn";
import Dsc24 from "./components/Service/Dsc24";
import TdsReturn from "./components/Service/TdsReturn";
import Order from "./components/Trade/order";
import Invoice from "./components/Trade/invoice"
import Item from "./components/Manage/Item";
import Account from "./components/BankAndCash/account";
import Transactions from "./components/BankAndCash/cash/Transactions";
import Settings from "./components/Settings"
import IncomeExpenses from "./components/Service/IncomeExpenses"
import BalanceSheet from "./components/Service/BalanceSheet"
import TanNumberRegistration from "./components/Service/TanNumberRegistration";
import ImportExportCodeRegistration from "./components/Service/ImportExportCodeRegistration";
import HttpClient from "./http/HttpClient.js";
import Header from "./Header";
import Footer from "./Footer";
import Login from "./Login";
import { MessageBoard } from "./themes/default/MessageBoard";
import { GlobalProvider } from "./GlobalProvider";
import "./App.css";
import Capital from "./components/Manage/Capital";
import User from "./components/Manage/User"
class Root extends Component {
    constructor(props) {
        super(props);
        this.height = window.screen.height - 121 - 35;
        this.state = {
            wrapperClass: true,
            isLoggedIn: localStorage.getItem("token") ? true : false,
            isConnected: null//localStorage.getItem("connected") || true
        }
    }

    toggleClass = event => {
        this.setState({ wrapperClass: !this.state.wrapperClass })
    }
    componentDidMount() {
        HttpClient.send({ method: "GET" }, "/me")
            .then(result => {
                if (result == null) return;
                if (result.id) {
                    this.setState({ isLoggedIn: true, isConnected: true });
                }
            })
            .catch(error => {
                console.log("App Root error", error);
            });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //debugger;
        let isLoggedIn = localStorage.getItem("token") ? true : false;
        let isConnected = prevState.isConnected == null ? true : localStorage.getItem("connected") ? true : false;

        //isConnected = localStorage.getItem("isConnected");
        if (isLoggedIn !== prevState.isLoggedIn || isConnected !== prevState.isConnected) {
            return ({ isLoggedIn, isConnected })
        }
        return { ...prevState };
    }

    render() {
        //console.log(window.screen.height - 121);
        let { isLoggedIn, isConnected } = this.state;
        let classHome = this.state.wrapperClass ? "max-sidebar" : "min-sidebar"
        let connectionNotif = isConnected ? "" : (<div className={"app-notify"}><span>Unable to connect to the server</span></div>)
        let sideBar = null;
        let header = null;
        if (isLoggedIn) {
            sideBar = <Sidebar className={this.state.wrapperClass ? "max" : "min"} />;
            header = <Header toggleClass={this.toggleClass} isLoggedIn={isLoggedIn} />;
        }
        return (
            <div className="main">
                {sideBar}{/* side bar */}
                <div className={`wrapper ${isLoggedIn ? classHome : !isLoggedIn ? "login" : "home"}`} >
                    {header}
                    <div className="content" style={{ minHeight: this.height }}>
                        <div className="container">
                            {connectionNotif}
                            <Route exact path="/Login" component={Login} />

                            {isLoggedIn ? (
                                <GlobalProvider>
                                    <MessageBoard />
                                    <Route path="/" exact component={Dashboard} />
                                    <Route path="/itr24" component={Itr24} />
                                    <Route path="/gstregistration" component={GstRegistration} />
                                    <Route path="/gstreturns" component={GstReturn} />
                                    <Route path="/dsc24" component={Dsc24} />
                                    <Route path="/tdsreturns" component={TdsReturn} />
                                    <Route path="/tannoregistration" component={TanNumberRegistration} />
                                    <Route path="/importexportcoderegistration" component={ImportExportCodeRegistration} />
                                    <Route path="/sale/order" render={(props)=><Order type="0" {...props}/>} />
                                    <Route path="/sale/invoice" render={(props)=><Invoice type="0" {...props}/>} />
                                    <Route path="/purchase/order" render={(props)=><Order type="1" {...props}/>} />
                                    <Route path="/purchase/invoice" render={(props)=><Invoice type="1" {...props}/>} />
                                    <Route path="/capital" component={Capital} />
                                    <Route path="/income-expenses" component={IncomeExpenses} />
                                    <Route path="/balance-sheet" component={BalanceSheet} />
                                    <Route path="/manage/customer" render={(props)=><User type="customer" {...props} />} />
                                    <Route path="/manage/vendor" render={(props)=><User type="vendor" {...props} />} />
                                    <Route path="/manage/item" component={Item} />
                                    <Route path="/settings" component={Settings} />
                                    <Route path="/account" component={Account} />
                                    <Route path="/cash" component={Transactions} />
                                </GlobalProvider>
                            ) : (
                                    <Redirect from={this.props.location.pathname} to="/Login" />
                                )}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}


export default withRouter(Root);
