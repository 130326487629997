import { showMessage } from "../../../themes/default/MessageBoard";
import axios from "../../../http/Axios";
class GstReturnsService {
  async store(datas, that) {
    //append data fields
    let form = new FormData();
    for (let key in datas.data) {
      form.append(key, datas.data[key]);
    }
    for (let key in datas.files) {
      if (datas.files[key] !== null) {
        form.append(key, datas.files[key]);
      }
    }
    try {
      let resp = null;
      if (datas.data.id === 0) {
        resp = await axios.post("/gstreturns", form);
      } else {
        resp = await axios.post("/gstreturns/" + datas.data.id, form);
      }
      return resp.data;
    } catch (err) {
      if (err.hasOwnProperty("response")) {
        let errRep = err.response;
        if (errRep.status === 422) {
          for (let key in errRep.data.detail) {
            console.log(errRep.data.detail[key].join("\n"));
            showMessage(
              {
                title: "invalid field",
                message: errRep.data.detail[key].join("\n")
              },
              that.context
            );
          }
        } else if (errRep.status === 500) {
          showMessage(
            { title: errRep.data.title, message: errRep.data.detail },
            that.context
          );
        }
      }
    }
  }
  async getAll(obj = {}, that) {
    try {
      let res = await axios.get("/gstreturns", { params: obj });
      return res.data;
    } catch (err) {
      let data = err.response.data;
      showMessage({ title: data.title, message: data.detail }, that.context);
    }
    return null;
  }
  async get(id, that) {
    try {
      let res = await axios.get("/gstreturns/" + id);
      return res.data;
    } catch (err) {
      let data = err.response.data;
      showMessage({ title: data.title, message: data.detail }, that.context);
      return null;
    }
  }
  async delete(ids) {
    try {
      await axios.delete("/gstreturns", { data: { ids: ids } });
      return true;
    } catch (err) {
      console.log(err);
    }
  }
}

export default GstReturnsService;
