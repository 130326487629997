import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SearchBar from "../../../Common/SearchBar";
import List from "../../../../themes/default/ViewWrappers/List"
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Add, Import, Export } from "../../../../themes/default/Buttons/MultiButtons"
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow } from "../../../../themes/default/Table/Table";
import accountService from '../AccountService';
import { Store } from "../../../../GlobalProvider";
import Loder from "../../../../themes/default/Loder/Loder";
import ImportModal from "../../../../modals/ImportModal";
class AccountList extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            accounts: [],
            limit: sessionStorage.getItem("core-accounts-limit") || 30,
            offset: sessionStorage.getItem("core-accounts-offset") || 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            pageRangeDisplayed: 5,
            total_records: 0,
            message: "",
            isModal: props.isModal,
            loading: false,
            showModal: false,
            file: {},
        };
    }

    delete = async id => {

        let result = await accountService.delete(id);
        if (result) {
            this.getAll();
        }
    };

    async getAll() {
        this.setState({ loading: true });
        let { search, orderBy, inOrder, limit, offset } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset };
        let result = await accountService.getAll(obj, this);
        console.log(result);
        if (result) {
            this.setState({ accounts: result });
        }
        this.setState({ loading: false });
    }
    componentDidMount() {
        this.getAll();
    }

    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };

    handleSearch = event => {
        let search = event;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };

    selectedAccount = id => {
        this.setState({ id: id });
    };

    handleImport = async event => {
        let file = { ...this.state.file };
        file = event
        this.setState({ file: file });
        let form = new FormData();
        form.append("file", file)
        let result = await accountService.import(form)
        if (result) {
            this.setState({ message: "File Imported Succesfully" })
            this.getAll();
        } else {
            this.setState({ message: "File Import Failed" })
        }
    }
    handleExport = async () => {
        let { search, orderBy, inOrder, limit, offset } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset };
        let result = await accountService.export(obj, this);
        if (result) {
            this.getAll();
        }
    }
    handleImportModal = () => {
        this.setState({ showModal: !this.state.showModal })
    }

    render() {
        return (
            <List>
                <HeaderWrapper title="Accounts">
                    <SearchBar onClick={this.handleSearch} />
                    <Add onClick={() => { this.props.history.push(`${this.props.match.path}/add`) }} />
                    <Import onClick={this.handleImportModal} />
                    <ImportModal onHide={this.handleImportModal} show={this.state.showModal} handleImport={this.handleImport} message={this.state.message} />
                    <Export onClick={this.handleExport} />
                </HeaderWrapper>
                <Loder show={this.state.loading} />
                {/** Account List*/}
                <Table className={["account-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading id={["description"]} onClick={this.handleOrdering}>
                                Name
                            </TableHeading>
                            <TableHeading id={["bank_name"]} onClick={this.handleOrdering}>
                                Bank Name
                            </TableHeading>
                            <TableHeading id={["amount"]} onClick={this.handleOrdering}>
                                Balance
                            </TableHeading>
                            <TableHeading id={["action"]}>
                                Action
                            </TableHeading>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.accounts.map((account, index) => {
                                return (
                                    <TableRow key={account.id}>
                                        <TableCell onClick={() => this.selectedAccount(account.id)}><Link to={`${this.props.match.path}/transactions/${account.id}`}> {account.name} </Link> </TableCell>
                                        <TableCell>{account.bank_name}</TableCell>
                                        <TableCell>{account.balance}</TableCell>
                                        <TableCell><i className="fa fa-edit" onClick={() => { this.props.history.push(`${this.props.match.path}/edit/${account.id}`) }}></i>  | <i className="fa fa-trash" onClick={() => this.delete(account.id)}></i></TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </List>
        );
    }
}

export default withRouter(AccountList);
