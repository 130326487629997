import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../../Common/SearchBar";
import List from "../../../../themes/default/ViewWrappers/List"
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Add, Delete } from "../../../../themes/default/Buttons/MultiButtons"
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow, TableFooter } from "../../../../themes/default/Table/Table.js";
import Pagination from "../../../../themes/default/Pagination/Pagination";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import ApiService from '../ApiService'
import { Store } from "../../../../GlobalProvider";
let apiService = new ApiService();
class GstRegistrationList extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);

        this.state = {
            gstRegistrations: [],
            activePage: 1,
            isChecked: false,
            checkedAll: false,
            limit: sessionStorage.getItem("core-itrs-limit") || 30,
            offset: sessionStorage.getItem("core-itrs-offset") || 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            pageRangeDisplayed: 5,
            total_records: 0,
            message: ""
        };
    }


    selectAll = event => {
        let { gstRegistrations } = this.state;
        gstRegistrations.forEach(gstR => {
            gstR.isChecked = !this.state.checkedAll;
        });
        this.setState({ gstRegistrations: gstRegistrations, checkedAll: !this.state.checkedAll });
    };

    checkedClick = ({ target }) => {
        let { gstRegistrations, checkedAll } = this.state;
        if (Array.isArray(gstRegistrations) && target.dataset.index != null && gstRegistrations[target.dataset.index] != null) {
            //toggele the current checkbox
            gstRegistrations[target.dataset.index].isChecked = target.checked;
            //toggle the select all checkbox; 
            checkedAll = !gstRegistrations.some(gstR => !gstR.isChecked);
            this.setState({ gstRegistrations: gstRegistrations, checkedAll: checkedAll });
        }
    };

    delete = event => {
        event.preventDefault();
        let selectedGsts = this.state.gstRegistrations.filter(gstR => gstR.isChecked());
        if (selectedGsts.length === 0) {
            alert('Please select');
            return false;
        }
        let selectedIds = selectedGsts.map(gstR => gstR.id);
        let result = apiService.delete(selectedIds);
        if (result) {
            this.getAll();
        }
    };

    async getAll() {
        let { search, orderBy, inOrder, limit, offset } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset };
        let result = apiService.getAll(obj, this)
        this.setState({ gstRegistrations: result });

    }
    componentDidMount() {
        this.getAll();
    }
    OnChangePagination = (offset, limit) => {
        if (parseInt(this.state.offset) !== parseInt(offset) || parseInt(this.state.limit) !== parseInt(limit)) {
            this.setState({
                limit,
                offset,
            }, () => {
                // get List
                this.getAll();

                // Store Limit In sessionStorage
                sessionStorage.setItem("core-itr24-offset", this.state.offset);
                sessionStorage.setItem("core-itr24-limit", this.state.limit);
            });
        }
    };

    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };

    handleSearch = event => {
        let search = event;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };

    render() {
        const { gstRegistrations } = this.state;
        return (
            <List>
                <HeaderWrapper title="GST Registration">
                    <SearchBar onClick={this.handleSearch} />
                    <Add onClick={() => { this.props.history.push(`${this.props.match.path}/Add`); }} />
                    <Delete onClick={this.delete} />
                </HeaderWrapper>

                {this.state.message}

                <Table className={["itr-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading width="30">
                                <CheckBox id="select_all" index={999999} onClick={this.selectAll} checked={this.state.checkedAll} />
                            </TableHeading>
                            <TableHeading id={["name"]} onClick={this.handleOrdering}>
                                Name
                        </TableHeading>
                            <TableHeading id={["email"]} onClick={this.handleOrdering}>
                                Email
                        </TableHeading>
                            <TableHeading width="30" id={["id"]} onClick={this.handleOrdering} >
                                Id
                        </TableHeading>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Array.isArray(gstRegistrations) && gstRegistrations.map((gstRegistration, index) => {
                            if (typeof gstRegistration.isChecked === "undefined")
                                gstRegistration.isChecked = false;
                            return (
                                <TableRow key={gstRegistration.id}>
                                    <TableCell>
                                        <CheckBox
                                            id={`cid${gstRegistration.id}`}
                                            name="cid[]"
                                            value={gstRegistration.id}
                                            index={index}
                                            onClick={this.checkedClick}
                                            checked={gstRegistration.isChecked}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`${this.props.match.path}/edit/${gstRegistration.id}`}>
                                            {gstRegistration.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{gstRegistration.email}</TableCell>
                                    <TableCell>{gstRegistration.id}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={13}>
                                <Pagination
                                    total_recordsCount={this.state.total_records}
                                    onChange={() => { }}
                                    limit={this.state.limit}
                                    offset={this.state.offset}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </List>
        );
    }
}

export default GstRegistrationList;
