import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './views/List';
import Edit from './views/Edit';
const Invoice = (props) => {
    const type = props.type || 0;
    return (
        <Switch>
            <Route exact path={props.match.path} render={(props)=><List type={type} {...props} />} />
            <Route path={`${props.match.path}/edit/:id`} render={(props)=><Edit type={type} {...props} />} />
        </Switch>
    );
}
export default Invoice;