import React, { Component } from 'react';
import Form from "../../../../themes/default/ViewWrappers/Form"
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Submit, Exit } from "../../../../themes/default/Buttons/MultiButtons"
import TextField from "../../../../themes/default/Inputs/Inputs.js";
import File from "../../../../themes/default/Inputs/File.js";
import Calendar from "../../../../themes/default/DatePicker/DatePicker.js";
import Label from "../../../../themes/default/Label/Label.js";
import ApiService from '../ApiService';
import FormValidation from '../../../../themes/default/assets/js/FormValidation.js';

let apiService = new ApiService();

class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: {
                pan_card: null,
                aadhar_card: null,
                e_bill: null,    //new
                bank_detail: null,
                form_16: null,  //reduntant
                photo: null,
                other_bank_statement: null
            },
            data: {

                id: parseInt(this.props.match.params.id) || 0, //reduntant
                name: "",
                trade_name: "",  //new
                bussiness_type: "",  //new
                father_name: "",
                email: "",
                aadhar_no: "",
                mob_no: "",
                password: "",    //reduntant
                success: false,
                message: "",
                dob: new Date(),
                isRegistered: false,  //reduntant
                efilling_id: "",   //reduntant
                efilling_pass: "",  //reduntant
            }
        };
    }
    componentDidMount() {
        const { id } = this.state.data;
        if (id !== 0) {
            const data = apiService.getGstRegistration(id);
            console.log('getGstRegistration: ', data)
        }
    }

    handleChange = event => {
        let field = event.target.id;
        let data = this.state.data;
        data[field] = event.target.value;
        this.setState({ data: data });
    };

    handleDatechange = date => {
        let data = this.state.data;
        data.dob = date;
        this.setState({ data: data });

    };
    checkedClick = event => {
        let data = this.state.data;
        data.isRegistered = event.target.checked;
        this.setState({ data: data });
    };

    handleFile = event => {
        let id = event.target.id;
        let files = { ...this.state.files };
        files[id] = event.target.files[0];
        this.setState({ files: files }, () => { console.log(this.state.files); });
    };

    submitForm = event => {
        event.preventDefault();
        console.log('files: ', this.state.files);
        let validation = new FormValidation();
        for (let key in this.state.data) {
            let result = validation.validate(key, this.state.data[key]);
            if (!result.valid) {
                alert(result.message);
                return;
            }
        }
        // for(let key in this.state.files){
        //     let result = validation.validateFile(key, this.state.files[key]);
        //     console.log('file', this.state.files[key])
        //     if(!result.valid){
        //         alert(result.message);
        //         return;
        //     }
        // }
        apiService.post(this.state);

    };


    render() {
        return (
            <Form>
                <HeaderWrapper title={`GST Registrations (${this.state.data.id === 0 ? "New" : "Edit"})`}>

                    <Submit onClick={this.submitForm} />
                    <Exit onClick={() => { this.props.history.push("/gstregistration"); }} />
                </HeaderWrapper>

                {/* Left side div  */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]} id="name">
                            {"Name *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            name="name"
                            id="name"
                            placeholder="Enter Name"
                            onChange={this.handleChange}
                            value={`${this.state.data.name}`}
                        />
                    </div>

                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>{"Father Name *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="father_name"
                            placeholder="Enter Father Name"
                            value={this.state.data.father_name}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>{"Email *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="email"
                            placeholder="Enter Email"
                            value={this.state.data.email}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>{"Trade Name *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="trade_name"
                            placeholder="Enter Trade Name"
                            value={this.state.data.trade_name}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                {/* Right side div */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]} id="dob"> {"DOB as per Pan *"} </Label>
                        <Calendar
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="date"
                            name="calender"
                            onChange={this.handleDatechange}
                            selected={this.state.data.dob}
                        />
                    </div>

                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>
                            {"Adhar Number *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            name="aadhar_no"
                            id="aadhar_no"
                            placeholder="Adhar Number"
                            onChange={this.handleChange}
                            value={`${this.state.data.aadhar_no}`}
                        />
                    </div>

                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>
                            {"Mobile No. *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="mob_no"
                            placeholder="Enter mobile no."
                            onChange={this.handleChange}
                            value={this.state.data.mob_no}
                        />
                    </div>

                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>
                            {"Bussiness Type. *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="bussiness_type"
                            placeholder="Enter bussiness type."
                            onChange={this.handleChange}
                            value={this.state.data.bussiness_type}
                        />
                    </div>

                </div>

                <div className="col-sm-12 mb-3 bg-light p-2 mt-3">
                    {/* <div className="form-group col-sm-12 text-left mb-0">
                        <Label className={["px-0", "col-sm-3"]}>
                            {"Already Registered on E- filling"}
                        </Label>
                        <CheckBox
                            className={["align-middle"]}
                            id={`isRegistered`}
                            name="cid[]"
                            value={this.state.data.isRegistered}
                            index={1}
                            onClick={this.checkedClick}
                            checked={this.state.data.isRegistered}
                        />
                    </div> */}
                    <div className="col-sm-6 mt-3" hidden={!this.state.data.isRegistered}>

                        {/* <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Log in-ID"}
                            </Label>
                            <TextField
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="efilling_id"
                                placeholder="Enter login id"
                                onChange={this.handleChange}
                                value={this.state.data.efilling_id}
                            />
                        </div> */}
                    </div>
                    <div className="col-sm-6 mt-3" hidden={!this.state.data.isRegistered}>

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Password"}
                            </Label>
                            <TextField
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="efilling_pass"
                                placeholder="Enter password"
                                type="password"
                                onChange={this.handleChange}
                                value={this.state.data.efilling_pass}
                            />
                        </div>
                    </div>
                </div>
                <div className="border border-light float-left w-100 pt-3 pb-3">
                    <div className="col-sm-6">

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Pan Card"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="pan_card"
                                onChange={this.handleFile}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Bank Detail"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="bank_detail"
                                onChange={this.handleFile}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Electricity Bill"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="e_bill"
                                onChange={this.handleFile}
                            />
                        </div>

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Photo"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="photo"
                                onChange={this.handleFile}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Adhar Card"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="aadhar_card"
                                onChange={this.handleFile}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Other Bank satement(01/04/2019 to 31/03/2020)"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="other_bank_statement"
                                onChange={this.handleFile}
                            />
                        </div>


                    </div>
                </div>

            </Form>
        );
    }
}

export default Edit;