import React, { Component } from 'react';
import { Store } from '../../../../GlobalProvider';
import List from '../../../../themes/default/ViewWrappers/List';
import HeaderWrapper from '../../../../themes/default/HeaderWrapper';
import SearchBar from '../../../Common/SearchBar';
import Tabs from 'react-bootstrap/Tabs';
import Income from './Income'
import Expense from './Expense'
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom'
import Table, { TableHead, TableCell, TableRow } from '../../../../themes/default/Table/Table';
import IncomeExpensesService from '../IncomeExpensesService';
import Loder from '../../../../themes/default/Loder/Loder';
class IncomeExpenseList extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            incomes: [],
            expenses: [],
            totalIncome: 0,
            totalExpense: 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            loading: false
        }

    }
    componentDidMount(){
        this.get();
    }
    async get(){
        this.setState({loading: true});
        let { orderBy, inOrder, limit, offset } = this.state;
        let search = this.props.search ? this.props.search : this.state.search
        let obj = { search, orderBy, inOrder, limit, offset };
        let result = await IncomeExpensesService.get(obj, this);
        if (result) {
            this.setState({...result, loading:false});
        }
    }
    handleSearch = value => {
        let search = value;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };
    render() {
        return (
            <List>
                <HeaderWrapper title="Income and Expense">
                    <SearchBar onClick={this.handleSearch} />
                </HeaderWrapper>
                <Loder show={this.state.loading} />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="font-weight-bold">
                                Total Income: {this.state.totalIncome}
                            </TableCell>
                            <TableCell>
                                Total Expenses: {this.state.totalExpense}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>

                <Tabs defaultActiveKey="Income" transition={false} id="noanim-tab-example">
                    <Tab eventKey="Income" title="Income" >
                        <Income
                            incomelist={this.state.incomes}

                        />
                    </Tab>
                    <Tab eventKey="Expense" title="Expense">
                        <Expense
                            expenselist={this.state.expenses}
                        />
                    </Tab>
                </Tabs>
            </List>
        );

    }
}
export default withRouter(IncomeExpenseList);
