import React from "react";
import RootReducer, { initialState } from "./reducers/RootReducer";
const GlobalContext = React.createContext({});

class GlobalProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: initialState,
      dispatch: action => {
        if (action == null || typeof action.type === "undefined") {
          console.log("dispatch function requires action type to perform task");
          return;
        }
        var newState = RootReducer(this.state.state, action);
        this.setState({ state: newState });
      }
    };
  }
  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

// const GlobalProvider = props => {
//   const store = createStore(RootReducer, initialState);
//   return (
//     <GlobalContext.Provider value={store}>
//       {this.props.children}
//     </GlobalContext.Provider>
//   );
// };
//
// const createStore = (reducer, initialState) => {
//   let [state, dispatch] = React.useReducer(reducer, initialState);
//   return { state, dispatch };
// };
export { GlobalProvider, GlobalContext as Store };
