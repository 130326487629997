import React from "react"

const HeaderWrapper = ({title,children,...rest}) => {
    return (
        <div className="header_buttons">
            <div className="header_title">
                <h2>{title || "Title"}</h2>
            </div>
            <div className="button-wrapper">
                {children}
            </div>
        </div>
    );
}

export default HeaderWrapper