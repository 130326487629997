import React from "react";
import { NavLink, Link, withRouter } from "react-router-dom";

import Icon from "../Icons/Icon"

require("./sidebar.css");
let menu = require("../../../Sidemenu.json");

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.submenu = this.submenu.bind(this)
    this.state = {
      submenu: false,
      core: []
    }
  }

  submenu(submenu) {
    let core = menu.menus.filter(i => this.props.location.pathname.match(i.default));
    if (Array.isArray(core) && core.length > 0) {
      core.map(item => {
        item.menus.map(menu => {
          if (menu.id === submenu.id) {
            menu.show_submenu = !menu.show_submenu
          } else {
            menu.show_submenu = false
          }
          return item;

        })
        return item;
      })
    }
    this.setState({ submenu: !this.state.submenu, core })
  }

  renderSubMenu(menu) {

    let sub_menu = ""
    if (menu.submenu instanceof Object && menu.submenu.length > 0) {
      sub_menu = menu.submenu.map(submenu => {
        return (
          <li key={submenu.id}>
            <Link to={submenu.default}>
              <img
                src={require(`../../../media/${submenu.icon}`)}
                className="menu-icon"
                alt="Icon"
              /><span className="menu-title">{submenu.title}</span>
            </Link>
          </li>
        )
      })
    }

    return (
      <ul className="submenu-list" name={menu.title}>
        {sub_menu}
      </ul>
    )

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let core = menu.menus.filter(i => nextProps.location.pathname.match(i.default));
    if (core !== prevState.core) {
      return ({ core })
    }
    return ({ ...prevState });
  }

  componentDidMount() {
    let core = menu.menus.filter(i => this.props.location.pathname.match(i.default));
    if (Array.isArray(core) && core.length > 0) {
      core.map(item => (
        item.menus.map(menu => (
          menu.show_submenu = false
        ))
      ))
    }
    this.setState({ core })
  }

  render() {
    //const core = menu.menus.filter( i => (i.id == "Y29yZQ=="));

    let core = this.state.core;//menu.menus.filter(i => this.props.location.pathname.match(i.default));

    let menues = core.map(item => (
      <div id="sidebar" className={`sidebar ${this.props.className}`} key={item.id}>
        <h1 className="module-title" id="module-title">
          <img src={require("../../../media/" + item.icon)} alt="Icon" />
          <span className="text">{item.title}</span>
        </h1>
        <div className="dashboard-title">
          <NavLink to={item.default}>
            <Icon icon={"faTh"} />
            <span className="text">{"Dashboard"}</span>
          </NavLink>
        </div>
        <ul className="menu-list">
          {Array.isArray(item.menus) ? (item.menus.map(menu => (
            <li key={menu.id}>
              {(Array.isArray(menu.submenu) && menu.submenu.length > 0) ? (
                <>
                  <NavLink
                  to="#">
                  <div onClick={() => { this.submenu(menu) }}>
                  <img
                  src={require(`../../../media/${menu.icon}`)}
                  className="menu-icon"
                  alt="Icon"
                  />
                  <span className="menu-title">{menu.title}</span>
                  <span className="submenu-button ml-2"><Icon icon={!menu.show_submenu ? "faAngleRight" : "faAngleDown"} /></span>
                  </div>
                  </NavLink>
                  {menu.show_submenu ? (this.renderSubMenu(menu)) : ""}
                </>
              ) : (
                  <NavLink
                    to={menu.default}
                    activeClassName="selected"
                    key={menu.id}
                    onClick={() => { this.submenu(menu) }}
                  >
                    <img
                      src={require(`../../../media/${menu.icon}`)}
                      className="menu-icon"
                      alt="Icon"
                    />
                    <span className="menu-title">{menu.title}</span>
                  </NavLink>
                )}

            </li>
          ))) : ""}
        </ul>
      </div>
    ))
    return (
      <>
        {(menues)}
      </>
    );
  }
}

export default withRouter(Sidebar);
