import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../../Common/SearchBar";
import List from "../../../../themes/default/ViewWrappers/List"
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Add, Convert, Delete, Import, Export } from "../../../../themes/default/Buttons/MultiButtons"
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow, TableFooter } from "../../../../themes/default/Table/Table.js";
import Pagination from "../../../../themes/default/Pagination/Pagination";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import saleOrderService from '../SaleOrderService.js';
import { Store } from "../../../../GlobalProvider";
import ImportModal from "../../../../modals/ImportModal";
class SaleList extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        console.log('props in list:',this.props);
        this.state = {
            sales: null,
            activePage: 1,
            isChecked: false,
            checkedAll: false,
            limit: "",
            offset: "",
            // limit: sessionStorage.getItem("core-sales-limit") || 30,
            // offset: sessionStorage.getItem("core-sales-offset") || 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            pageRangeDisplayed: 5,
            total_records: 0,
            type: parseInt(this.props.type) || 0,
            message: "",
            file: {
            },
            showModal: false,
        };
    }


    selectAll = event => {
        let rows = this.state.sales;
        rows.forEach(row => {
            row.isChecked = !this.state.checkedAll;
        });
        this.setState({ sales: rows, checkedAll: !this.state.checkedAll });
    };

    checkedClick = event => {
        let target = event.target;
        let { sales, checkedAll } = this.state;
        if (Array.isArray(this.state.sales) && target.dataset.index != null && this.state.sales[target.dataset.index] != null) {
            //toggele the current checkbox
            sales[target.dataset.index].isChecked = target.checked;
            //toggle the select all checkbox; 
            checkedAll = !sales.some(sale => !sale.isChecked);
            this.setState({ sales: sales, checkedAll: checkedAll });
        }
    };
    delete = async event => {
        event.preventDefault();
        let selectedSales = this.state.sales.filter(sale => {
            return sale.isChecked ? sale.id : null;
        });
        console.log(selectedSales)
        if (selectedSales.length === 0) {
            alert('Please select');
            return false;
        }
        let selectedIds = selectedSales.map(selectedCustomer => selectedCustomer.id);
        let result = await saleOrderService.delete(selectedIds);
        if (result) {
            this.getAll();
        }
    };

    async getAll() {
        let { search, orderBy, inOrder, limit, offset, type } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset, type };
        let result = await saleOrderService.getAll(obj, this);
        this.setState({ sales: result });

    }
    componentDidMount() {
        this.getAll();

    }
    OnChangePagination = (offset, limit) => {
        if (parseInt(this.state.offset) !== parseInt(offset) || parseInt(this.state.limit) !== parseInt(limit)) {
            this.setState({
                limit,
                offset,
            }, () => {
                // get List
                this.getAll();

                // Store Limit In sessionStorage
                sessionStorage.setItem("core-sale24-offset", this.state.offset);
                sessionStorage.setItem("core-sale24-limit", this.state.limit);
            });
        }
    };

    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };

    handleSearch = event => {
        let search = event;
        this.setState({ search: search }, () => {
            console.log(this.state.search)
            this.getAll();
        });
    };
    convertToInvoice = async (id) => {
        let status = await saleOrderService.convertToSale(id);
        if (status) {
            this.getAll();
        }
    }
    handleImport = async event => {
        let file = { ...this.state.file };
        file = event
        this.setState({ file: file });
        let form = new FormData();
        form.append("file", file)
        let result = await saleOrderService.import(form)
        if (result) {
            this.setState({ message: "File Imported Succesfully" })
            this.getAll();
        } else {
            this.setState({ message: "File Import Failed" })
        }
    }
    handleExport = async () => {
        let selectedSales = this.state.sales.filter(sale => {
            return sale.isChecked ? sale.id : null;
        });
        if (selectedSales.length === 0) {
            alert('Please Select')
            return false
        }
        let selectedIds = selectedSales.map(selectedSale => selectedSale.id);
        let result = await saleOrderService.export(selectedIds);
        if (result) {
            this.getAll();
        }
    }
    handleImportModal = () => {
        this.setState({ showModal: !this.state.showModal })
    }

    render() {
        const sales = this.state.sales;
        return (
            <List>
                <HeaderWrapper title="Sales">
                    <SearchBar onClick={this.handleSearch} />
                    <Add onClick={() => { this.props.history.push(`${this.props.match.path}/add`); }} />
                    <Delete onClick={this.delete} />
                    <Import onClick={this.handleImportModal} />
                    <ImportModal onHide={this.handleImportModal} show={this.state.showModal} handleImport={this.handleImport} message={this.state.message} />
                    <Export onClick={this.handleExport} />
                </HeaderWrapper>
                {this.state.message}
                <Table className={["sale-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading width="30">
                                <CheckBox id="select_all" index={999999} onClick={this.selectAll} checked={this.state.checkedAll} />
                            </TableHeading>
                            <TableHeading id={["name"]} onClick={this.handleOrdering}>
                                Title
                            </TableHeading>
                            <TableHeading id={["name"]} onClick={this.handleOrdering}>
                                Customer
                            </TableHeading>
                            <TableHeading id={["id"]} onClick={this.handleOrdering} >
                                Date
                            </TableHeading>
                            <TableHeading id={["id"]} onClick={this.handleOrdering} >
                                Order Amount
                            </TableHeading>
                            <TableHeading id={["id"]} onClick={this.handleOrdering} >
                                Action
                            </TableHeading>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Array.isArray(sales) && sales.map((sale, index) => {
                            if (typeof sale.isChecked === "undefined")
                                sale.isChecked = false;
                            return (
                                <TableRow key={sale.id}>
                                    <TableCell>
                                        <CheckBox
                                            id={`cid${sale.id}`}
                                            name="cid[]"
                                            value={sale.id}
                                            index={index}
                                            onClick={this.checkedClick}
                                            checked={sale.isChecked}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`${this.props.match.path}/edit/${sale.id}`}>
                                            {sale.title}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{`${sale?.customer?.name || ''} ${sale?.customer?.last_name || ''}`}</TableCell>
                                    <TableCell>{sale.created_at}</TableCell>
                                    <TableCell>{sale.amount}</TableCell>
                                    {!sale.invoice ? <TableCell><Convert onClick={() => this.convertToInvoice(sale.id)} ></Convert></TableCell> : <TableCell></TableCell>}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={13}>
                                <Pagination
                                    total_recordsCount={this.state.total_records}
                                    onChange={() => { }}
                                    limit={this.state.limit}
                                    offset={this.state.offset}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </List>
        );
    }
}

export default SaleList;
