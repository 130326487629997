import React, { Component } from "react";
import Root from "./Root";
import AppErrorBoundary from './AppErrorBoundary';


class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <AppErrorBoundary>
        <Root />
      </AppErrorBoundary>
    );
  }
}


export default App;
