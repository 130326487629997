import React, { Component } from "react"
import TextField from '../../../../themes/default/Inputs/Inputs.js';
import List from "../../../../themes/default/ViewWrappers/List"
import AccountService from '../../../BankAndCash/account/AccountService';
import saleInvoiceService from '../SaleInvoiceService';
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow } from "../../../../themes/default/Table/Table.js";
import HeaderWrapper from '../../../../themes/default/HeaderWrapper'
import { Exit, Pay, Submit } from '../../../../themes/default/Buttons/MultiButtons'
import Label from '../../../../themes/default/Label/Label.js';
import SelectList from '../../../../themes/default/SelectList/SelectList.js';
import {Store} from '../../../../GlobalProvider';
import Loder from "../../../../themes/default/Loder/Loder.js";
import Form from "../../../../themes/default/ViewWrappers/Form.js";
class InvoiceEdit extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        const url = parseInt(this.props.type) ? '/purchase/invoice' : '/sale/invoice';
        this.state = {
            id: parseInt(this.props.match.params.id) ||0,
            type: parseInt(this.props.type) ||0,
            accounts: [{id:0, title:'Cash'}],
            accountId: 0,
            amount: 0,
            balance_due: 0,
            discount_amount: 0,
            tax_amount: 0,
            paying: 0.00,
            items: [],
            customer:{name:'', last_name:''},
            cuurentTemplate: 2,
            loding: false,
            url: url,
        }


    }
    componentDidMount() {
        this.getDetails(this.state.id);
        this.getAccountList();
    }
    async getDetails(id) {
        this.setState({loding:true});
        const data = await saleInvoiceService.get(id, this);
        if (data !== null) {
            console.log(data);
            this.setState({ ...data });
        }
        this.setState({loding:false});
    }
    handleReceived = value => {
        const paying = parseFloat(value) || 0;
        paying > this.state.balance_due ? alert(`Can't pay greater ${this.state.balance_due}`) : this.setState({paying});
        
    }
    handleChange = (event)=>{
        const accountId = parseInt(event.target.value);
        this.setState({accountId});
    }
    pay = async (event) => {
        event.preventDefault();
        const description = this.state.type ? 'Made payment for purchase order' : 'Got payment of sale order';
        const type = this.state.type;
        //for purchase 2, for sale 1
        let paymethod_id = type ? 2 : 1;
        let data = {
            amount:this.state.paying,
            description,
            type,
            accountId: this.state.accountId,
            paymethod_id
        };
        let result = await saleInvoiceService.pay(data, this.state.id, this);
        if(result){
            this.setState({paying:0});
            this.getDetails(this.state.id);
        }

        
    }
    async getAccountList() {
        let result = await AccountService.getList(this);
        if(result){
            this.setState({accounts:[...this.state.accounts, ...result]});
        }
    }
    render() {
        let items = this.state.items, transactions = this.state.transactions
        return (
            <List>
                <HeaderWrapper title={`${this.state.customer.name} ${this.state.customer.last_name}`}>
                    <Exit onClick={() => this.props.history.push(`${this.state.url}`)} />
                </HeaderWrapper>
                <Loder show={this.state.loding} />
                <div className="col-sm-9">
                    <Table className={["invoice-table", "table", "table-striped text-center"]}>
                        <TableHead>
                            <TableRow>
                                <TableHeading width="125" id={["name"]} >
                                    ITEM
                            </TableHeading>
                                <TableHeading width="70" id={["email"]} >
                                    QTY
                            </TableHeading>
                                < TableHeading width="50" id={["amount"]}  >
                                    PRICE/UNIT
                                </TableHeading>
                                < TableHeading width="125" id={["discount"]}  >
                                    DISCOUNT
                                    <br />
                                    <span className="col-sm-4">%</span>
                                    <span className="col-sm-8">Amount</span>
                                </TableHeading>
                                < TableHeading width="150" id={["tax"]}  >
                                    TAX
                                    <br />
                                    <span className="col-sm-4">%</span>
                                    <span className="col-sm-8">Amount</span>
                                </TableHeading>
                                <TableHeading width="150" id={["props.totalAmount"]}  >
                                    AMOUNT
                            </TableHeading>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(items) && items.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {item.title}
                                        </TableCell>
                                        <TableCell>
                                            {item.quantity}
                                        </TableCell>
                                        <TableCell>
                                            {item.amount}
                                        </TableCell>
                                        <TableCell>
                                            <span className="col-sm-6">{item.discount}</span>
                                            <span className="col-sm-6"> {item.discount_amount}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="col-sm-6">{item.tax}</span>
                                            <span className="col-sm-6"> {item.tax_amount}</span>
                                        </TableCell>
                                        <TableCell>
                                            {item.amount * item.quantity}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            )}
                            <TableRow>
                                <TableCell colSpan="6">
                                    <Label className={['d-inline-block', 'col-sm-4', 'left']}>
                                        {'Total'}
                                    </Label>
                                    <span className={['right']}>{this.state.amount}</span>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <div className="col-sm-3">
                    <Form>
                        <div className="form-group col-sm-12 text-left">
                            <p>Balance Due: {this.state.balance_due}</p>
                            <p>Remaning: {this.state.balance_due - this.state.paying}</p>
                        </div>
                        <div className="form-group col-sm-12 text-left">
                            <Label className={['d-inline-block', 'col-sm-8']} id='title1'>
                                {'Amount'}
                            </Label>
                            <TextField
                                className={['form-control', 'col-sm-4', 'd-inline-block']}
                                id='paying'
                                placeholder='paying'
                                type="number"
                                value={this.state.paying}
                                onChange={(event)=>this.handleReceived(event.target.value)}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left">
                            <Label className={['d-inline-block', 'col-sm-12']} id='title1'>
                                {'Payment type'}
                            </Label>
                            <SelectList className={['d-inline-block', 'col-sm-12']}  list={this.state.accounts} onChange={this.handleChange}/>
                        </div>
                        <div className="form-group col-sm-12 text-left">
                            <Pay onClick={this.pay} disabled={!this.state.balance_due} />
                        </div>
                    </Form>
                </div>
            </List >
        );
    }
}
export default InvoiceEdit;