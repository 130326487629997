import React, {Component} from 'react';
import Table, {TableBody, TableCell, TableHead, TableRow, TableHeading} from '../../../themes/default/Table/Table'
import accountService from '../account/AccountService';
import {Store} from "../../../GlobalProvider";
import {Add, Exit} from '../../../themes/default/Buttons/MultiButtons'
import EditTransaction from '../../../modals/EditTransaction';
import List from '../../../themes/default/List/list';
import HeaderWrapper from '../../../themes/default/HeaderWrapper';
import transactionService from './TransactionService';
import Loder from '../../../themes/default/Loder/Loder';
class Transactions extends Component{
    static contextType = Store;
    state = {
        accountId: parseInt(this.props.match.params.id) || 0,
        accountdetails:{},
        transactions: [],
        editTran: false,
        deletetran: false,
        selectedTransaction: {
            id:0,
            description:'',
            amount:0,
            type:0
        },
        loding:false
    };
    componentDidMount(){

        this.getDetials();
    }
    async getDetials(){
        let result = {};
        let data = {};
        this.setState({loding:true});
        if(this.state.accountId){

            result = await accountService.get(this.state.accountId, this);
            if(result){
                data.accountdetails = {id:result.id, name:result.name, bank_name:result.bank_name, account_number:result.account_number,balance:result.balance}
                data.transactions = [...result.transactions];
                this.setState({...data});
            }
        }else{
            result = await transactionService.getAll();
            if(result){
                data.transactions = result;
                this.setState({...data});
            }
        }
        this.setState({loding:false});
    }
    edit = (index)=>{
        let data = {};
        data.editTran = true;
        if(index > -1){
           data.selectedTransaction = this.state.transactions[index];
        }
        this.setState(data);
    };
    hide = ()=>{
        this.setState({editTran:false,  selectedTransaction: {id:0,description:'', amount:0, type:'INC'}});
    };

    delete = async (transactionId)=>{
        this.setState({loding:true});
        let result;
        if(this.state.accountId){
            result = await accountService.deleteTransaction(this.state.accountId,transactionId)
        }else{

            result = await transactionService.delete(transactionId);
        }
        this.setState({loding:false});
        if(result){
            this.getDetials();
        }
    };

    handleChange = event => {
        let field = event.target.id;
        let data = {...this.state.selectedTransaction};
        data[field] = event.target.value;
        this.setState({ selectedTransaction:data });
    };

    store = async ()=>{
        let data = {...this.state.selectedTransaction};
        let result;
        if(this.state.accountId){
           result = await accountService.storeTransaction(this.state.accountId,this.state.selectedTransaction, this)
        }else{

            result = await transactionService.store(data, this);
        }
        if(result){
            this.setState({editTran:false});
            this.getDetials();
        }
    };

    render(){
        let trans = null;
        let editTransaction = null;
        let exit = null;
        let list = [{id:'0',name:'Add cash'}, {id:'1',name:'Reduce Cash'}];
        if(this.state.accountId){
            list = [{id:'0',name:'Depost Money'}, {id:'1',name:'Withdraw Money'}]
            exit = <Exit onClick={()=>this.props.history.push('/account')} />;
        }
        if(this.state.editTran){
            editTransaction = <EditTransaction onHide={this.hide} show={this.state.editTran} transaction={this.state.selectedTransaction} handleChange={this.handleChange} store={this.store} list={list} />;
        }
        if(this.state.transactions){
            trans =  this.state.transactions.map((transaction, index) => {
                return (
                    <TableRow key={transaction.id}>
                        <TableCell><i className="fa fa-edit" onClick={()=>this.edit(index)}></i> <i className="fa fa-trash" onClick={()=>this.delete(transaction.id)}></i></TableCell>
                        <TableCell>{transaction.description}</TableCell>
                        <TableCell>{transaction.type ? 'debit' : 'credit'}</TableCell>
                        <TableCell>{transaction.date}</TableCell>
                        <TableCell>{transaction.amount}</TableCell>
                    </TableRow>
                )
            });
        }

        return(
            <List>
                <HeaderWrapper title={this.state.accountId ? 'Transactions':'Cash'}>
                    <Add onClick={this.edit} title="Adjustment" />
                    {exit}
                </HeaderWrapper>
                <Loder show={this.state.loding} />
                <Table className={["account-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading id={["description"]} onClick={this.handleOrdering}>
                                Action
                            </TableHeading>
                            <TableHeading id={["description"]} onClick={this.handleOrdering}>
                                Description
                            </TableHeading>
                            <TableHeading id={["type"]} onClick={this.handleOrdering}>
                                Type
                            </TableHeading>
                            <TableHeading id={["date"]} onClick={this.handleOrdering}>
                                Date
                            </TableHeading>
                            <TableHeading id={["amount"]} onClick={this.handleOrdering}>
                                Amount
                            </TableHeading>
                        </TableRow>
                    </TableHead>
        
                    <TableBody>
                    {trans}
                    </TableBody>
                </Table>
                {/** edit Traansaction */}
                {editTransaction}
            </List>
        );
    }
}

export default Transactions;