import React, { useState, useEffect } from 'react';
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow } from "../../../../themes/default/Table/Table.js";
import List from "../../../../themes/default/ViewWrappers/List"
import TextField from '../../../../themes/default/Inputs/Inputs.js';
import ItemList from '../../../Manage/Item/views/List'
function AddItem(props) {
    const [showComponent, setShowComponent] = useState(props.showComponent || false);
    useEffect(show => {
        setShowComponent(show)
    }, [props.showComponent])

    const [cellEdit, setCellEdit] = useState(props.cellEdit || false);
    useEffect(cellEdit => {
        setCellEdit(cellEdit)
    }, [props.cellEdit]
    )
    const items = props.items;
    let flagPrice = 0;
    return (<>
        <List>
            <Table className={["item-table", "table", "table-striped text-center"]}>
                <TableHead>
                    <TableRow>
                        <TableHeading width="125" id={["name"]} >
                            ITEM
                        </TableHeading>
                        <TableHeading width="70" id={["email"]} >
                            QTY
                        </TableHeading>
                        <TableHeading width="70" id={["unit"]}  >
                            UNIT
                        </TableHeading>
                        < TableHeading width="50" id={["amount"]}  >
                            PRICE/UNIT
                            </TableHeading>
                        < TableHeading width="125" id={["discount"]}  >
                            DISCOUNT
                                <br />
                            <span className="col-sm-4">%</span>
                            <span className="col-sm-8">Amount</span>
                        </TableHeading>
                        < TableHeading width="150" id={["tax"]}  >
                            TAX
                                <br />
                            <span className="col-sm-4">%</span>
                            <span className="col-sm-8">Amount</span>
                        </TableHeading>
                        <TableHeading width="150" id={["props.totalAmount"]}  >
                            AMOUNT
                        </TableHeading>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(items) && items.map((item, index) => {
                        return (
                            <TableRow
                                className={'form-group'}
                                key={index}
                                onClick={(e) => { setCellEdit(true) }}
                                onBlur={() => setCellEdit(false)}
                            >
                                {cellEdit ? (<>
                                    <TableCell>
                                        <TextField
                                            className={['form-control-sm', 'col-sm-12', 'd-inline-block', 'input-sm']}
                                            name='title'
                                            defaultValue={item.title}
                                            autoComplete="off"
                                            onChange={props.setItemsList("title", item.id)} />
                                    </TableCell>
                                    <TableCell >
                                        <TextField
                                            className={['form-control-sm col-sm-12']}
                                            name='quantity'
                                            value={item.quantity}
                                            autoComplete="off"
                                            onChange={props.setItemsList("quantity", item.id)} />
                                    </TableCell>
                                    <TableCell>
                                        <select className="form-control-sm">
                                            <option value="1">NONE</option>
                                        </select>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            className={['form-control-sm', 'col-sm-9', 'd-inline-block']}
                                            name='price'
                                            defaultValue={item.price}
                                            autoComplete="off"
                                            onChange={props.setItemsList("price", item.id)} />
                                    </TableCell>
                                    <TableCell >
                                        <TextField
                                            className={['form-control-sm', 'd-inline-block', 'col-sm-4']}
                                            name='discount'
                                            defaultValue={item.discount}
                                            value={item.discount || 0}
                                            autoComplete="off"
                                            onChange={props.setItemsList("discount", item.id)}
                                        />
                                        <TextField
                                            className={['form-control-sm', 'd-inline-block', 'col-sm-8']}
                                            name='discount_amount'
                                            defaultValue={item.discount_amount}
                                            autoComplete="off"
                                            disabled
                                            onChange={props.setItemsList("discount_amount", item.id)}
                                        />
                                    </TableCell>
                                    <TableCell className="form-group">
                                        <select
                                            className='form-control-sm  col-sm-8'
                                            name='tax'
                                            defaultValue={item.tax}
                                            autoComplete="off"
                                            onChange={props.setItemsList("tax", item.id)}
                                        >
                                            <option value="0">None</option>
                                            <option value="0">IGST@0%</option>
                                            <option value="0">GST@0%</option>
                                            <option value="0.25">IGST@0.25%</option>
                                            <option value="0.25">GST@0.25%</option>
                                            <option value="3">IGST@3%</option>
                                            <option value="3">GST@3%</option>
                                            <option value="5">IGST@5%</option>
                                            <option value="5">GST@5%</option>
                                            <option value="12">IGST@12%</option>
                                            <option value="12">GST@12%</option>
                                            <option value="18">IGST@18%</option>
                                            <option value="18">GST@18%</option>
                                            <option value="28">IGST@28%</option>
                                            <option value="28">GST@28%</option>
                                            <option value="0">Exempted</option>
                                        </select>
                                        <TextField
                                            className={['form-control-sm', 'col-sm-4', 'd-inline-block']}
                                            name='tax_amount'
                                            defaultValue={item.tax_amount}
                                            autoComplete="off"
                                            disabled
                                            onChange={props.setItemsList("tax_amount", item.id)} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            className={['form-control-sm', 'col-sm-12', 'd-inline-block']}
                                            name='amountByQty'
                                            defaultValue={item.amount}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <button type="button" className="close" aria-label="Delete" onClick={() => props.deleteRow(item.id)}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </TableCell>
                                </>) :
                                    (<>
                                        <TableCell>
                                            {item.title}
                                        </TableCell>
                                        <TableCell>
                                            {item.quantity}
                                        </TableCell>
                                        <TableCell>
                                            <select className="border-0">
                                                <option value="1">NONE</option>
                                            </select>
                                        </TableCell>
                                        <TableCell>
                                            {item.price}
                                        </TableCell>
                                        <TableCell>
                                            <span className="col-sm-6">{item.discount}</span>
                                            <span className="col-sm-6"> {item.discount_amount}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="col-sm-6">{item.tax}</span>
                                            <span className="col-sm-6"> {item.tax_amount}</span>
                                        </TableCell>
                                        <TableCell>
                                            {item.price * item.quantity}
                                        </TableCell>
                                    </>)
                                }
                            </TableRow>

                        )

                    })}
                    <TableRow className='form-group'>
                        <TableCell width={"125"}>
                            <TextField
                                className={['form-control-sm']}
                                name='title'
                                placeholder={'Add Item'}
                                autoComplete="off"
                                onClick={() => { setShowComponent(!showComponent) }}
                                // onBlur={() => { setShowComponent(false) }}
                                onChange={(e) => props.handleSearch(e.target.value)}

                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            Total
                            </TableCell>
                        <TableCell>
                            {props.totalQuantity ? props.totalQuantity : 0}
                        </TableCell>
                        <TableCell>
                        </TableCell><TableCell>
                        </TableCell><TableCell>
                            {props.discount_amount}
                        </TableCell>
                        <TableCell>
                            {props.tax_amount}
                        </TableCell>
                        <TableCell>
                            {flagPrice}
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>Total after Discount and TAX</TableCell>
                        <TableCell colSpan="50">{props.amount ? props.amount : 0}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className={['form-control', 'col-sm-3', 'd-inline-block']}>
                {showComponent && (<ItemList isModal={true} setItems={props.setItems} search={props.search} setShowComponent={setShowComponent} />)}
            </div>
            <Table>


                <TableBody>
                    <TableRow>
                        {props.totalAmount ? <><TableCell>Total AMOUNT:</TableCell>
                            <TableCell><strong>{Number(props.totalAmount).toFixed(2)}</strong></TableCell></> : <TableCell />}
                    </TableRow>
                </TableBody>
            </Table>
        </List >
    </>);
}
export default AddItem;