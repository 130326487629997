import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Form from '../../../../themes/default/ViewWrappers/Form'
import { Store } from '../../../../GlobalProvider';
import HeaderWrapper from '../../../../themes/default/HeaderWrapper'
import { Submit, Exit, Update } from '../../../../themes/default/Buttons/MultiButtons'
import TextField from '../../../../themes/default/Inputs/Inputs.js';
import SelectList from '../../../../themes/default/SelectList/SelectList';
import Label from '../../../../themes/default/Label/Label.js';
import saleOrderService from '../SaleOrderService.js';
import FormValidation from '../../../../themes/default/assets/js/FormValidation.js';
import AddItem from './AddItem'
import AccountService from '../../../BankAndCash/account/AccountService';
class Edit extends Component {
    static contextType = Store;
    _isMount = false;
    constructor(props) {
        super(props);
        const url = parseInt(this.props.type) ? '/purchase/order' : '/sale/order' ;
        this.state = {
            id: parseInt(this.props.match.params.id) || 0,
            type: parseInt(this.props.type) || 0,
            title: '',
            customer_id: 0,
            customers: [],
            amount: '',
            advance: '',
            items: [],
            search: "",
            showComponent: false,
            cellEdit: false,
            tax_amount: 0,
            discount_amount: 0,
            totalQuantity: 0,
            url:url,
        };
    }
    componentDidMount() {
        this._isMount = true;
        if (this.state.id !== 0) {
            this.getDetails(this.state.id);
        }
        this.getList();
    }
    componentWillUnmount() {
        this._isMount = false
    }
    async getDetails(id) {
        const data = await saleOrderService.get(id, this);
        if (data !== null) {
            this._isMount && this.setState({ ...data });
            this.getTotal();
        }
    }
    async getList(){
        let customerType = this.state.type ? 'vendor': 'customer'; 
        let result = await saleOrderService.getList(customerType);
        if(result){
            this.setState({customers: result});
        }
    }
    handleSearch = value => {
        let search = value;
        this.setState({ search: search })
    };
    handleChange = event => {
        let field = event.target.id;
        let data = this.state;
        data[field] = event.target.value;
        this.setState({ data });
    };
    submitForm = async (event) => {
        event.preventDefault();
        let validation = new FormValidation();
        for (let key in this.state) {
            let result = validation.validate(key, this.state[key]);
            if (!result.flag) {
                alert(result.message);
                return;
            }
        }
        let { id, title, customer_id, amount, items, tax_amount, discount_amount,type } = this.state;
        let data = { id, title, customer_id, amount, items, tax_amount, discount_amount, type }
        let result = await saleOrderService.store(data, this)
        if (result) {
            console.log('Result of post: ', result);
            this.setState({ ...result }, () => {
                this.props.history.push(`${this.state.url}/edit/${this.state.id}`);
                this.getTotal();
            })
        }
    }
    setItems = (item) => {
        let items = [...this.state.items];
        let selectedItem = { ...item, discount: 0, discount_amount: 0, tax: 0, tax_amount: 0, amount: item.price };
        items.push(selectedItem);
        this.setState({
            items: items,
            showComponent: false,
        }, () => this.getTotal())
        console.log(this.state.items)
    }
    setItemsList = (type, id) => event => {
        let items = JSON.parse(JSON.stringify(this.state.items));
        let value = event.target.value;
        if (type !== 'title')
            value = parseFloat(value) || 0;
        items.find(item => {
            if (item.id === id) {
                item[type] = value;
                return true;
            }
            return null
        });
        this.setState({ items: items }, () => this.getTotal());
    }
    deleteRow = (id) => {
        const items = JSON.parse(JSON.stringify(this.state.items));
        const itemCopy = items.filter(item => item.id !== id)
        this.setState({
            items: itemCopy
        }, () => this.getTotal())
    }

    customer_idHandler = (value) => {
        this.setState({ customer_id: parseInt(value) })
    }

    getTotal = () => {
        console.log('Called from top of getTotal', this.state.items);
        let items = JSON.parse(JSON.stringify(this.state.items));
        let sItemQ = 0, sAmount = 0, sTaxAmount = 0, sDiscountAmount = 0, sPrice = 0;
        if (items.length) {
            items.forEach((item) => {
                //for single item
                let iAmount = 0, iDiscountAmount = 0, iTaxAmount = 0;
                iAmount = parseFloat(item.quantity * item.price);
                iDiscountAmount = parseFloat(iAmount * item.discount / 100);
                iAmount -= iDiscountAmount;
                iTaxAmount = parseFloat(iAmount * item.tax / 100);
                iAmount -= iTaxAmount;
                item.amount = parseFloat(iAmount.toFixed(2));
                item.discount_amount = parseFloat(iDiscountAmount.toFixed(2));
                item.tax_amount = parseFloat(iTaxAmount.toFixed(2));
                //for sale
                sItemQ += item.quantity;
                sAmount += iAmount;
                sDiscountAmount += iDiscountAmount;
                sTaxAmount += iTaxAmount;
                sPrice += parseFloat(item.quantity * item.price);

            });
        }
        this.setState({
            items: items,
            totalQuantity: sItemQ,
            amount: parseFloat(sAmount.toFixed(2)),
            tax_amount: parseFloat(sTaxAmount.toFixed(2)),
            discount_amount: parseFloat(sDiscountAmount.toFixed(2)),
            price: parseFloat(sPrice.toFixed(2))
        })
    }
    render() {
        let submitButton = null;
        if (this.state.id !== 0) {
            submitButton = <Update onClick={this.submitForm} />;
        } else {
            submitButton = <Submit onClick={this.submitForm} />;
        }
        return (
            <Form>
                <HeaderWrapper title={`Sale (${this.state.id ? 'Edit' : 'New'})`}>
                    {submitButton}
                    <Exit onClick={() => this.props.history.push(`${this.state.url}`)} />
                </HeaderWrapper>
                {/* Left side div  */}
                <div className='col-sm-6'>
                    <div className='form-group col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']} id='title1'>
                            {'Title *'}
                        </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            name='title'
                            id='title'
                            placeholder='Enter title'
                            onChange={this.handleChange}
                            defaultValue={this.state.title}
                        />
                    </div>

                </div>
                {/* Right side div */}
                <div className='col-sm-6'>
                    <div className='form-group  col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']}>{this.state.type ? 'Select Vendor *': 'Select Customer *'} </Label>
                        <SelectList
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            list={this.state.customers}
                            value={this.state.customer_id}
                            onChange={(e) => this.customer_idHandler(e.target.value)}
                        />
                    </div>
                </div>
                <div className={"p-0"}>
                    <AddItem
                        setItems={this.setItems}
                        setItemsList={this.setItemsList}
                        onBlur={this.onBlur}
                        deleteRow={this.deleteRow}
                        items={this.state.items}
                        showComponent={this.state.showComponent}
                        handleSearch={this.handleSearch}
                        search={this.state.search}
                        cellEdit={this.props.cellEdit}
                        amount={this.state.amount}
                        tax_amount={this.state.tax_amount}
                        discount_amount={this.state.discount_amount}
                        totalQuantity={this.state.totalQuantity}
                    />
                </div>
            </Form>
        );
    }
}
export default withRouter(Edit);