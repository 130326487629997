import { showMessage } from '../../../themes/default/MessageBoard';
import axios from '../../../http/Axios';
class SaleInvoiceService {

    async getAll(obj = {}, that) {
        try {
            let res = await axios.get('/invoice', { params: obj });
            return res.data;
        }
        catch (err) {
            let data = err.response.data;
            showMessage({ title: data.title, message: data.detail }, that.context);
        }
        return null;
    }
    async get(id, that) {
        try {
            let res = await axios.get('/invoice/' + id);
            return res.data;
        } catch (err) {
            showMessage({ title: '', message: 'Somthing went wrong' }, that.context);
            return null;
        }
    }

    async delete(ids) {
        try {
            await axios.delete('/invoice', { data: { ids: ids } });
            return true;
        } catch (err) {
            console.log(err);
        }
    }
    async export(ids) {
        try {
            let data = {};
            data.ids = ids;
            console.log(data)
            let result = await axios.post('/invoice/make/export', data);
            let dat = new Blob([JSON.stringify(result.data)], { type: 'json' });
            let csvURL = window.URL.createObjectURL(dat);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'salesInvoicesExported.json');
            tempLink.click();
            return true;
        } catch (err) {
            console.log(err);
        }
    }
    async import(file) {
        try {
            let result = await axios.post('/invoice/make/import', file);
            if (result) {
                return true
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    async pay(data,id, that){
        try{
            let res = await axios.post(`/invoice/make/payment/${id}`, data);
            if(res){
                showMessage({ title: `Payment`, message: `Payment is successfull` }, that.context);
                return true;
            }
        }catch(err){
            showMessage({ title: `Payment`, message: err.response.data.detial }, that.context);
            return false;
        }

    }
}
export default new SaleInvoiceService();