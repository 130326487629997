import React from "react";
import { Modal } from "react-bootstrap";
import Stock from "../components/Manage/Item/views/Stock";
// import Edit from "../components/account/Item/views/Edit";
const AdjustmentModel = props => {
  // console.log(props.handleStateChange);
  // console.log(props.modalData);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stock
          isModal={true}
          data={props.modalData}
          handleStateChange={props.handleStateChange}
          submitForm={props.submitForm}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AdjustmentModel;
