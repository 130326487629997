import React from "react";
import AjaxLoading from "../Loadings/AjaxLoading";

const Form = props => {
  return (
    <>
      <AjaxLoading show={props.ajaxLoading} />
      <form
        className={
          Array.isArray(props.className) ? props.className.join(" ") : ""
        }
        onSubmit={props.onSubmit}
        name={props.name}
        id={props.id}
        method={props.method}
        encType={props.encType}
      >
        <>
          {props.children}
        </>
      </form>
    </>
  );
};

export default Form;
