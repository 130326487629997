import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './views/List';
const Settings = (props) => {
    return (
        <Switch>
            <Route exact path={props.match.path} component={Main} />
        </Switch>
    )
}
export default Settings;