import React from 'react'
import {Modal} from 'react-bootstrap';
import Form from '../themes/default/Form/Form';
import Label from '../themes/default/Label/Label';
import TextField from '../themes/default/Inputs/Inputs';
import SelectList from '../themes/default/SelectList/SelectList';
import { Update, Add } from '../themes/default/Buttons/MultiButtons';
const EditTransaction = props =>{
    let button;
    if(props.transaction.id){
        button = <Update onClick={props.store} />;
    }else{
        button = <Add onClick={props.store} />
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Transaction
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>  
                <Form>
                    {/* Left side div  */}
                    <div className='col-sm-12'>
                        <div className='form-group col-sm-12 text-left'>
                            <Label className={['d-inline-block', 'col-sm-3']} id='Description'>
                                {'Description *'}
                            </Label>
                            <TextField
                                className={['form-control', 'col-sm-9', 'd-inline-block']}
                                name='description'
                                id='description'
                                placeholder='Enter desc'
                                onChange={props.handleChange}
                                value={`${props.transaction.description}`}
                            />
                        </div>

                        <div className='form-group  col-sm-12 text-left'>
                            <Label className={['d-inline-block', 'col-sm-3']}>{'Entry Type *'} </Label>
                            <SelectList
                                className={['form-control', 'col-sm-9', 'd-inline-block']}
                                id='type'
                                list={props.list}
                                onChange={props.handleChange}
                            />
                        </div>
                    </div>

                    {/* Right side div */}
                    <div className='col-sm-12'>
                        <div className='form-group col-sm-12 text-left'>
                            <Label className={['d-inline-block', 'col-sm-3']} id='amount'> {'Amount *'} </Label>
                            <TextField
                                className={['form-control', 'col-sm-9', 'd-inline-block']}
                                name='amount'
                                id='amount'
                                placeholder='Enter Amount'
                                onChange={props.handleChange}
                                value={`${props.transaction.amount}`}
                            />
                        </div>

                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {button}
            </Modal.Footer>
        </Modal>
    
    );
};

export default EditTransaction;