import React from 'react';
import { Button } from "react-bootstrap";
import { Nav, Row, Col } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template5 from "./Template5";
import PropTypes from 'prop-types'
function Invoice(props) {
    return (<>
        <Tab.Container id="left-tabs-example" defaultActiveKey={props.salesinvoice.currentTemplate}>
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey={0}>Template 1</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={1} > Template 2</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={2}> Template 3</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content className="col-sm-12">
                        <Tab.Pane eventKey={0}>
                            <Template1 salesinvoice={props.salesinvoice} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={1}>
                            <Template2 salesinvoice={props.salesinvoice} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={2}>
                            <Template5 salesinvoice={props.salesinvoice} />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container >
        {props.isModal ?
            <Button onClick={() => { alert("print") }}>Print</Button>

            : ''}
    </>);
}
Invoice.propTypes = {
    setTemplate: PropTypes.func.isRequired,
    salesinvoice: PropTypes.shape({
        id: PropTypes.isRequired,
        customer: PropTypes.isRequired,
        amount: PropTypes.isRequired,
        balance_due: PropTypes.isRequired,
        payment_type: PropTypes.isRequired,
        items: PropTypes.isRequired,
        created_at: PropTypes.string.isRequired,
        discount_amount: PropTypes.isRequired,
        tax_amount: PropTypes.isRequired

    }).isRequired
}
Invoice.defaultProps = {
}
export default Invoice;