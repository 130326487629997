import { showMessage } from "../../../themes/default/MessageBoard";
import axios from "../../../http/Axios";
class TanNumberRegistrationService {
  async store(datas, that) {
    //append data fields
    let form = new FormData();
    for (let key in datas.data) {
      form.append(key, datas.data[key]);
    }
    for (let key in datas.files) {
      if (datas.files[key] !== null) {
        form.append(key, datas.files[key]);
      }
    }
    try {
      let resp = null;
      if (datas.data.id === 0) {
        // console.log("Datas id" + datas.data.id);
        resp = await axios.post("/tannoregistration", form);
      } else {
        resp = await axios.post("/tannoregistration/" + datas.data.id, form);
      }
      // console.log("Resp data=>" + resp.data);
      return resp.data;
    } catch (err) {
      if (err.hasOwnProperty("response")) {
        let errRep = err.response;
        if (errRep.status === 422) {
          for (let key in errRep.data.detail) {
            console.log(errRep.data.detail[key].join("\n"));
            showMessage(
              {
                title: "invalid field",
                message: errRep.data.detail[key].join("\n")
              },
              that.context
            );
          }
        } else if (errRep.status === 500) {
          showMessage(
            { title: errRep.data.title, message: errRep.data.detail },
            that.context
          );
        }
      }
    }
  }
  async getAll(obj = {}, that) {
    // console.log("GetAll", obj);
    try {
      // console.log("try");
      let res = await axios.get("/tannoregistration", { params: obj });
      // console.log(res);
      return res.data;
    } catch (err) {
      let data = err.response.data;
      showMessage({ title: data.title, message: data.detail }, that.context);
    }
    return null;
  }
  async get(id, that) {
    try {
      let res = await axios.get("/tannoregistration/" + id);
      return res.data;
    } catch (err) {
      let data = err.response.data;
      showMessage({ title: data.title, message: data.detail }, that.context);
      return null;
    }
  }
  async delete(ids) {
    try {
      await axios.delete("/tannoregistration", { data: { ids: ids } });
      return true;
    } catch (err) {
      console.log(err);
    }
  }
}
export default TanNumberRegistrationService;
