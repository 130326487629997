import React from 'react';

const File = (props) => {
    return props.multiple === true ? (
        <input 
            ref={props.fileRef}  
            className={Array.isArray(props.className) ? props.className.join(' ') : ''} 
            onChange={props.onChange} 
            type="file" name={props.name} 
            id={props.id} placeholder={props.placeholder} 
            value={props.value} 
            accept={props.accept} 
            multiple 
        />
    ) :
    (
        <input 
            ref={props.fileRef}  
            className={Array.isArray(props.className) ? props.className.join(' ') : ''}
            onChange={props.onChange} 
            type="file" 
            name={props.name}
            id={props.id}
        />
    );
}

export default File;
