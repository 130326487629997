import React from "react"
import PageLoading from "../Loadings/PageLoading"
const List = (props) => {
    return (
        <>
            <PageLoading show={props != null && props.pageLoading} />
            <div className="tablelist col-sm-12">
                <div className="tablelist_inner">
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default List;
