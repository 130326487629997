import { showMessage } from "../../../themes/default/MessageBoard";
import axios from "../../../http/Axios";

class IncomeExpensesService {
  async get(obj = {}, that) {
    let fromDate = obj.fromDate || '2019-01-01';
    let toDate = obj.toDate || '2020-09-22';
    try {
      
      let res = await axios.get(`/income_expense/${fromDate}/${toDate}`, { params: obj });
      return res.data;
    } catch (err) {
      let data = err.response.data;
      showMessage({ title: data.title, message: data.detail }, that.context);
    }
    return null;
  }
  
}
export default new IncomeExpensesService;
