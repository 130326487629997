import React, { Component } from 'react';
import Data from './Data';
import Stock from './Stock';
import { Store } from '../../../../GlobalProvider';
import itemService from '../ItemService.js';
import FormValidation from '../../../../themes/default/assets/js/FormValidation.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { withRouter } from 'react-router-dom'
import Form from '../../../../themes/default/ViewWrappers/Form';
import { Update, Submit, Exit } from '../../../../themes/default/Buttons/MultiButtons';
import HeaderWrapper from "../../../../themes/default/HeaderWrapper";
class Edit extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {

            data: {
                id: parseInt(this.props.match.params.id) || 0,
                title: '',
                category: '',
                price: 0,
                wholesale_price: 0,
                quantity: 0,
                quantity_required: 0,
                available: 0,
                stock_minimum: 0,
                stock_maximum: 0
            },
            successs: null,
            resMsg: null,
            errorMsg: null,
            errorStockMinimum: false,
            errorStockMaximum: false,
            isModal: this.props.isModal
        };

    }
    componentDidMount() {
        if (this.state.data.id !== 0) {
            this.getDetails(this.state.data.id);
        }
    }
    async getDetails(id) {
        const result = await itemService.get(id, this);
        if (result) {
            this.setState({ data: result });
        }
    }
    handleChange = event => {
        let field = event.target.id;
        let data = this.state.data;
        data[field] = event.target.value;
        this.setState({ data: data });
    };
    submitForm = async event => {
        event.preventDefault();
        let validation = new FormValidation();
        for (let key in this.state.data) {
            let result = validation.validate(key, this.state.data[key]);
            if (!result.flag) {
                alert(result.message);
                return;
            }
        }
        let data = {...this.state.data};
        let result = await itemService.store(data, this);
        if (result) {
            this.setState({ data: result }, () =>
                this.props.history.push('/manage/item/edit/' + this.state.data.id)
            );
        }

    }

    handleStateChange = (type) => (event) => {
        let value = event.target.value
        // Non Negative Number Restrictions
        if (type === "quantity_required" || type === "stock_minimum" || type === "stock_maximum") {
            if (Math.sign(value) === -1) {
                alert("Invalid Number");
                return;
            }
        }
        // Error Handle 
        if (type === "stock_minimum" && this.state.errorStockMinimum) {
            this.setState({
                errorStockMinimum: false,
                errorMsg: null
            });
        }
        if (type === "stock_maximum" && this.state.errorStockMaximum) {
            this.setState({
                errorStockMaximum: false,
                errorMsg: null
            });
        }
        this.setState({
            data: { ...this.state.data, [type]: value }
        });
    }
    render() {
        let submitButton = <Submit onClick={this.submitForm} />;
        if(this.state.data.id){
            submitButton = <Update onClick={this.submitForm} />
        }

        return (
            <Form>
                <HeaderWrapper title={`Item (${this.state.data.id ? "Edit" : "New"})`}>
                    {submitButton}
                    <Exit onClick={() => { this.props.history.push("/manage/item"); }} />
                </HeaderWrapper>
                <Tabs defaultActiveKey="Data" transition={false} id="noanim-tab-example">
                    <Tab eventKey="Data" title="Data" disabled={this.state.isModal ? "disabled" : ""}>
                        <Data
                            data={this.state.data}
                            handlerExit={this.handlerExit}
                            handleChange={this.handleChange}
                        />
                    </Tab>

                    <Tab eventKey="Stock" title="Stock" disabled={this.state.data.id == null ? "disabled" : ""}>
                        <Stock
                            data={this.state.data}
                            handlerExit={this.handlerExit}
                            handleStateChange={this.handleStateChange}
                            handleRadioBtn={this.handleRadioBtn}
                        />
                    </Tab>
                </Tabs>
            </Form>
        );
        // }
    }
}
export default withRouter(Edit);
