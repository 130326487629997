import { showMessage } from "../../../themes/default/MessageBoard";
import axios from "../../../http/Axios";

class Dsc24Service {
  async store(datas, that) {
    //append data fields
    let form = new FormData();
    for (let key in datas.data) {
      form.append(key, datas.data[key]);
    }
    for (let key in datas.files) {
      if (datas.files[key] !== null) {
        form.append(key, datas.files[key]);
      }
    }
    try {
      let resp = null;
      if (datas.data.id === 0) {
        resp = await axios.post("/dsc24", form);
      } else {
        resp = await axios.post("/dsc24" + datas.data.id, form);
      }

      return resp.data;
    } catch (err) {
      if (err.hasOwnProperty("response")) {
        let errRep = err.response;
        if (errRep.status === 422) {
          for (let key in err.Rep.data.detail) {
            showMessage(
              {
                title: "invalid field",
                message: errRep.data.detail[key].join("\n")
              },
              that.context
            );
          }
        } else if (errRep.status === 500) {
          showMessage(
            { title: errRep.data.title, message: errRep.data.detail },
            that.context
          );
        }
      }
    }
  }

  async getAll(obj = {}, that) {
    try {
      let res = await axios.get("/dsc24", { params: obj });
      return res.data;
    } catch (err) {
      let data = err.response.data;
      showMessage({ title: data.title, message: data.detail }, that.context);
    }
    return null;
  }
  async get(id, that) {
    try {
      let res = await axios.get("/dsc24/" + id);
      return res.data;
    } catch (err) {
      showMessage({ title: "", message: "Somthing went wrong" }, that.context);
      return null;
    }
  }

  async delete(ids) {
    try {
      await axios.delete("/dsc24", { data: { ids: ids } });
      return true;
    } catch (err) {
      console.log(err);
    }
  }

  async test(obj = {}, that) {
    console.log("test function has been called before await");
    try {
      let res = await axios.get("/dsc24");
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }

    // if(res.statusText ==='OK'){
    // 	return res.data;
    // }else{

    // }
    // console.log('test function has been called after await');
    // return 'Failed';
  }
}

export default Dsc24Service;

//   //Create new registrations
//   async post({ data, files }) {
//     //append data fields
//     let postBody = new FormData();
//     for (let key in data) {
//       postBody.append(key, data[key]);
//     }
//     for (let key in files) {
//       if (files[key] !== null) {
//         postBody.append(key, files[key]);
//       }
//     }

//     const response = axios.post("/dsc24", postBody);
//     const json = await response;
//     console.log(json);
//     // let obj = await HttpClient.send(options, '/itr24');
//     console.log(response);
//   }

//   //Get all registrations
//   async getAll(obj = {}, that) {
//     let endpoint = "";
//     endpoint += obj.limit ? "offset=" + obj.offset + "&limit=" + obj.limit : "";
//     endpoint += obj.search ? "&search=" + obj.search : "";
//     endpoint += obj.orderBy
//       ? "&orderBy=" + obj.orderBy + "&dir=" + obj.inOrder
//       : "";

//     // const response = axios.get(`/gst_registration`, { params : 		})
//     // const json = await response;
//     // let result = await HttpClient.send(options, endpoint,true);
//     // console.log('result: ',json);
//     // if (result instanceof Response) {
//     // 	if (result.ok) {
//     // 		return result.json();
//     // 	} else if(result.status>299 && result.status<499){
//     // 		result = await result.json();
//     // 		console.log(result)
//     // 		showMessage({ title: result.title, message: result.detail },that.context);
//     // 	}
//     // }
//     // return null;
//   }
//   async getDsc24(id) {
//     axios.get();
//     // let response = axios.get('');
//     // return response;
//     // return HttpClient.send(options, "/itr24/" + id);
//     return {};
//   }

//   async delete(ids) {
//     let options = { method: "delete" };
//     options.body = JSON.stringify(ids);
//     return HttpClient.send(options, "/core/users");
//   }
// }

// export default Dsc24Service;
