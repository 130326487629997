import React, { Component } from 'react';
import { Store } from '../../../../GlobalProvider';
import List from '../../../../themes/default/ViewWrappers/List';
import HeaderWrapper from '../../../../themes/default/HeaderWrapper';
import SearchBar from '../../../Common/SearchBar';
import { withRouter } from 'react-router-dom'
import Table, { TableCell, TableRow, TableHeading } from '../../../../themes/default/Table/Table';
import balanceSheetService from '../BalanceSheetService';
import Loder from '../../../../themes/default/Loder/Loder';

class BalanceSheetList extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            totalSaleAmount: 0,
            totalSaleAmountBalanceDue: 0,
            totalPurchaseAmount: 0,
            totalPurchaseAmountBalanceDue: 0,
            totalCreditCash: 0,
            totalDebitCash: 0,
            totalCreditCashAccount: 0,
            totalDebitCashAccount: 0,
            loading: true,
        }
    }
    async get() {
        let obj = {};
        let result = await balanceSheetService.get(obj, this);
        if(result){
            this.setState({...result});
        }
        this.setState({loading:false});
    }
    componentDidMount(){
        this.get();
    }

    handleSearch = () => {

    };
    render() {
        return (
            <List>
                <HeaderWrapper title="Balance Sheet">
                    <SearchBar onClick={this.handleSearch} />
                </HeaderWrapper>
                <Loder show={this.state.loading} />
                <Table className={["balnce-sheet-table", "table", "table-striped", "col-sm-5"]}>
                    <TableRow>
                        <TableHeading colSpan={2}>
                            Assets
                        </TableHeading>
                    </TableRow>
                    <TableRow >
                        <TableCell>
                            Credit Cash at Bank
                        </TableCell>
                        <TableCell >
                            {this.state.totalCreditCashAccount}
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>
                        Credit Cash
                        </TableCell>
                        <TableCell >
                            {this.state.totalCreditCash}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Total Sale Amount
                        </TableCell>
                        <TableCell >
                            {this.state.totalSaleAmount}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Creditors Balance
                        </TableCell>
                        <TableCell >
                            {this.state.totalSaleAmountBalanceDue}
                        </TableCell>
                    </TableRow>
                </Table>
                <Table className={["balnce-sheet-table", "table", "table-striped", "col-sm-5", "ml-2"]}>
                    <TableHeading colSpan={2} className="text-center">
                        Liabilities
                        </TableHeading>
                        <TableRow >
                        <TableCell>
                            Debit Cash from Bank
                        </TableCell>
                        <TableCell >
                            {this.state.totalCreditCashAccount}
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>
                        Debit Cash
                        </TableCell>
                        <TableCell >
                            {this.state.totalCreditCash}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Total Purchase Amount
                        </TableCell>
                        <TableCell >
                            {this.state.totalPurchaseAmount}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Debtors Balance
                        </TableCell>
                        <TableCell >
                            {this.state.totalPurchaseAmountBalanceDue}
                        </TableCell>
                    </TableRow>
                </Table>

            </List>
        );

    }
}
export default withRouter(BalanceSheetList);
