import React, { Component } from "react";

import { Search, Clear } from '../../themes/default/Buttons/MultiButtons';


class SearchBar extends Component {

  state = {
    searchKey: "",
  }

  handlerStateChange = (type) => (event) => {
    this.setState({ [type]: event.target.value });
  }

  handlerClearData = () => {
    this.setState({
      searchKey: "",
    }, () => {
      if (typeof this.props.onClick === "function") {
        this.props.onClick(this.state.searchKey);
      }
    });
  }

  handlerSubmitData = (event) => {
    event.preventDefault();
    if (this.state.searchKey === "") {
      return;
    }
    if (typeof this.props.onClick === "function") {
      this.props.onClick(this.state.searchKey);
    }
  }

  render() {

    return (
      <>
        <form className="search-form float-left" action="" onSubmit={this.handlerSubmitData}>
          <div className="form-group form-group-buttons">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={this.state.searchKey}
                onChange={this.handlerStateChange('searchKey')}
              />
              <div className="input-group-append">
                <Search onClick={this.handlerSubmitData}/>
                <Clear onClick={this.handlerClearData} />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default SearchBar;
