import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './views/List';
const BalanceSheet = (props) => {
  return (
    <Switch>
      <Route exact path={props.match.path} component={List} />
    </Switch>
  )
}
export default BalanceSheet;