import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './views/List';
import Edit from './views/Edit';
import Transactions from '../cash/Transactions';
const Account = (props) => {
    return (
        <Switch>
            <Route exact path={props.match.path} component={List} />
            <Route path={`${props.match.path}/transactions/:id`} component={Transactions} />
            <Route path={[`${props.match.path}/add`, `${props.match.path}/edit/:id`]} component={Edit} />
        </Switch>
    );
}
export default Account;