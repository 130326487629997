import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Profile = (props) => {
  let items = '';
  if(Array.isArray(props.items) && props.items.length !== 0){
    items = props.items.map((key) => {
      return <Dropdown.Item href={key.link} onSelect={props.onSelect} key={key.link}><FontAwesomeIcon icon={key.icon}/>{key.title}</Dropdown.Item>
    });
  }
  return (
  <Dropdown className={Array.isArray(props.className)?props.className.join(' '):''}>
  <Dropdown.Toggle split variant={props.variant} id={props.id}>{props.icon}{props.title}</Dropdown.Toggle>
  <Dropdown.Menu>
    {items}
  </Dropdown.Menu>
  </Dropdown>
);
};


export default Profile
