import React from 'react';

const List = (props) => {
    return (
        <ul className={Array.isArray(props.className)?props.className.join(' '):''} id={props.id}>{props.children}
		</ul>        
    );
}

const ListItem = (props) => {
    return (
        <li className={Array.isArray(props.className)?props.className.join(' '):''} id={props.id} onClick={props.onClick}>{props.children}
		</li>        
    );
}


export default List;
export {ListItem};