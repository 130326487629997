import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../../Common/SearchBar";
import List from "../../../../themes/default/ViewWrappers/List";
import HeaderWrapper from "../../../../themes/default/HeaderWrapper";
import { Add, Delete } from "../../../../themes/default/Buttons/MultiButtons";
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow, TableFooter } from "../../../../themes/default/Table/Table.js";
import Pagination from "../../../../themes/default/Pagination/Pagination";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import TanNumberRegistrationService from "../TanNumberRegistrationService.js";
import { Store } from "../../../../GlobalProvider";
let tanNumberRegistrationService = new TanNumberRegistrationService();
class TanNumber extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            tans: [],
            activePage: 1,
            isChecked: false,
            checkedAll: false,
            limit: sessionStorage.getItem("core-tan-limit") || 5,
            offset: sessionStorage.getItem("core-tan-offset") || 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            pageRangeDisplayed: 5,
            total_records: 0,
            message: ""
        };
    }
    selectAll = event => {
        let rows = this.state.tans;
        rows.forEach(row => {
            row.isChecked = !this.state.checkedAll;
        });
        this.setState({ tans: rows, checkedAll: !this.state.checkedAll });
    };
    checkedClick = event => {
        let target = event.target;
        let { tans, checkedAll } = this.state;
        if (
            Array.isArray(this.state.tans) &&
            target.dataset.index != null &&
            this.state.tans[target.dataset.index] != null
        ) {
            //toggele the current checkbox
            tans[target.dataset.index].isChecked = target.checked;
            //toggle the select all checkbox;
            checkedAll = !tans.some(tan => !tan.isChecked);
            this.setState({ tans: tans, checkedAll: checkedAll });
        }
    };

    delete = event => {
        event.preventDefault();
        let selectedTans = this.state.tans.filter(tan => {
            return tan.isChecked ? tan.id : null;
        });
        if (selectedTans.length === 0) {
            alert("Please select");
            return false;
        }
        let selectedIds = selectedTans.map(selectedTan => selectedTan.id);
        let result = tanNumberRegistrationService.delete(selectedIds);
        if (result) {
            this.getAll();
        }
    };
    async getAll() {
        let { search, orderBy, inOrder, limit, offset } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset };
        let result = await tanNumberRegistrationService.getAll(obj, this);
        this.setState({ tans: result });
    }
    componentDidMount() {
        this.getAll();
    }
    OnChangePagination = (offset, limit) => {
        if (
            parseInt(this.state.offset) !== parseInt(offset) ||
            parseInt(this.state.limit) !== parseInt(limit)
        ) {
            this.setState(
                {
                    limit: limit,
                },
                () => {
                    sessionStorage.setItem("core-tans-offset", this.state.offset);
                    sessionStorage.setItem("core-tans-limit", this.state.limit);
                }
            );
        }
    };
    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };
    handleSearch = event => {
        let search = event;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };
    render() {
        const tans = this.state.tans;
        return (
            <List>
                <HeaderWrapper title="Tan Number Registration">
                    <SearchBar onClick={this.handleSearch} />
                    <Add
                        onClick={() => {
                            this.props.history.push(`${this.props.match.path}/Add`);
                        }}
                    />
                    <Delete onClick={this.delete} />
                </HeaderWrapper>
                {this.state.message}
                <Table className={["tan-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading width="30">
                                <CheckBox
                                    id="select_all"
                                    index={999999}
                                    onClick={this.selectAll}
                                    checked={this.state.checkedAll}
                                />
                            </TableHeading>
                            <TableHeading
                                width="30"
                                id={["id"]}
                                onClick={this.handleOrdering}
                            >
                                ID
                            </TableHeading>
                            <TableHeading
                                width="30"
                                id={["id"]}
                                onClick={this.handleOrdering}>
                                Attachments
                            </TableHeading>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(tans) &&
                            tans.slice(this.state.offset, this.state.limit).map((tan, index) => {
                                if (typeof tan.isChecked === "undefined") tan.isChecked = false;
                                return (
                                    <TableRow key={tan.id}>
                                        <TableCell>
                                            <CheckBox
                                                id={`cid${tan.id}`}
                                                name="cid[]"
                                                value={tan.id}
                                                index={index}
                                                onClick={this.checkedClick}
                                                checked={tan.isChecked}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`${this.props.match.path}/edit/${tan.id}`} >
                                                {tan.email}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {/* attachments */}
                                            {tan.medias.map(media => <img key={media.id} src={process.env.REACT_APP_SERVER_URL + "/media?location=" + media.url} width="80px" height="80px" alt="" />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={13}>
                                <Pagination
                                    onChange={(offset, limit) => { this.OnChangePagination(offset, limit) }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </List>
        );
    }
}

export default TanNumber;
