import { showMessage } from '../../../themes/default/MessageBoard';
import axios from '../../../http/Axios';

class AccountService {

	async store(datas, that) {
		try {
			let resp = null;
			if (datas.id === 0) {
				resp = await axios.post('/account', datas);
			} else {
				resp = await axios.post('/account/' + datas.id, datas);
			}
			return resp.data;

		} catch (err) {
			if (err.hasOwnProperty('response')) {
				let errRep = err.response;
				if (errRep.status === 422) {
					for (let key in errRep.data.detail) {
						showMessage({ title: '', message: errRep.data.detail[key].join('\n') }, that.context);
					}
				} else if (errRep.status === 500) {
					showMessage({ title: errRep.data.title, message: errRep.data.detail }, that.context);
				}
			}
			return null;
		}
	}

	async getAll(obj = {}, that) {
		try {
			let res = await axios.get('/account', { params: obj });
			return res.data;
		}
		catch (err) {
			let data = err.response.data;
			showMessage({ title: data.title, message: data.detail }, that.context);
		}
		return null;
	}
	async get(id, that) {
		try {
			let res = await axios.get('/account/' + id);
			return res.data;
		} catch (err) {
			let data = err.response.data;
			showMessage({ title: data.title, message: data.detail }, that.context);
			return null;
		}
	}

	async delete(id) {
		try {
			await axios.delete('/account/' + id);
			return true;
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	async storeTransaction(accountId, data, that) {

		try {
			let resp;
			resp = await axios.post(`/account/${accountId}/transaction`, data);
			return resp.data;

		} catch (err) {
			if (err.hasOwnProperty('response')) {
				let errRep = err.response;
				if (errRep.status === 422) {
					for (let key in errRep.data.detail) {
						showMessage({ title: '', message: errRep.data.detail[key].join('\n') }, that.context);
					}
				} else if (errRep.status === 500) {
					showMessage({ title: errRep.data.title, message: errRep.data.detail }, that.context);
				}
			}
			return null;
		}
	}

	async deleteTransaction(accountId, transactionId) {
		try {
			await axios.delete(`/account/${accountId}/transaction/${transactionId}`);
			return true;
		} catch (err) {
			return null;
		}
	}

	async test(obj = {}, that) {
		console.log('test function has been called before await');
		try {

			let res = await axios.get('/account');
			console.log(res);
			return res.data;
		}
		catch (error) {
			console.log(error);
			return null;
		}
	}
	async export(obj = {}, that) {
		try {
			let res = await axios.get('/account', { params: obj });
			let dat = new Blob([JSON.stringify(res.data)], { type: 'json' });
			let csvURL = window.URL.createObjectURL(dat);
			let tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', 'AccountsExported.json');
			tempLink.click();
			return true;
		}
		catch (err) {
			let data = err.response.data;
			showMessage({ title: data.title, message: data.detail }, that.context);
		}
	}
	async import(file) {
		try {
			let result = await axios.post('/account/make/import', file);
			if (result) {
				return true
			}
		}
		catch (err) {
			console.log(err)
		}
	}

	async getList(that){
		try{
			let result = await axios.get('/account/get/list');
			return result.data;
		}catch(err){
			console.log(err);
		}
	}

}

export default new AccountService();