import { showMessage } from '../../../themes/default/MessageBoard';
import axios from '../../../http/Axios';
class saleOrderService {
	async store(data, that) {
		try {
			let resp = null;
			if (data.id === 0) {
				resp = await axios.post('/order', data);
			} else {
				resp = await axios.post('/order/' + data.id, data);
			}
			return resp.data;
		} catch (err) {

			if (err.hasOwnProperty('response')) {
				let errRep = err.response;
				if (errRep.status === 422) {
					for (let key in errRep.detail) {
						console.log(errRep.detail[key].join('\n'));
						showMessage({ title: 'invalid field', message: errRep.data.detail[key].join('\n') }, that.context);
					}
				} else if (errRep.status === 500) {
					showMessage({ title: errRep.data.title, message: errRep.data.detail }, that.context);
				}
			}
		}
	}

	async getAll(obj = {}, that) {
		try {
			let res = await axios.get('/order', { params: obj });
			return res.data;
		}
		catch (err) {
			let data = err.response.data;
			showMessage({ title: data.title, message: data.detail }, that.context);
		}
		return null;
	}
	async get(id, that) {
		try {
			let res = await axios.get('/order/' + id);
			return res.data;
		} catch (err) {
			let data = err.response.data;
			showMessage({ title: data.title, message: data.detail }, that.context);
			return null;
		}
	}

	async delete(ids) {
		try {
			await axios.delete('/order', { data: { ids: ids } });
			return true;
		} catch (err) {
			console.log(err);
		}
	}
	async convertToSale(id) {
		let result = null;
		result = axios.post('/order/make/invoice/' + id);
		return result;
	}
	async export(ids) {
		try {
			let data = {};
			data.ids = ids;
			let result = await axios.post('/order/make/export', data);
			let dat = new Blob([JSON.stringify(result.data)], { type: 'json' });
			let csvURL = window.URL.createObjectURL(dat);
			let tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', 'salesOrderExported.json');
			tempLink.click();
			return true;
		} catch (err) {
			console.log(err);
		}
	}
	async import(file) {
		try {
			let result = await axios.post('/order/make/import', file);
			if (result) {
				return true
			}
		}
		catch (err) {
			console.log(err)
		}
	}

	async getList(type){
		try{
			let result = await axios.get('user/get/list', {params:{type}});
			return result.data;
		}catch(err){
			console.log(err);
			return null;
		}
	}
}

export default new saleOrderService();
