import React from "react";
function Template5(props) {
    let invoice = props.salesinvoice
    console.log(invoice)
    const temp5 = (
        <div className="container">
            <div className="row invoice-header px-3 py-2">
                <div className="col-4">
                    <p>Company Name</p>
                    <h1>INVOICE</h1>
                </div>
                <div className="col-4 text-right">
                    <p>(011)-123-1243</p>
                    <p>email@adress.com</p>
                    <p>personal-website.com</p>
                </div>
                <div className="col-4 text-right">
                    <p>Street Adress</p>
                    <p>City, State Adress, ZIP</p>
                    <p>VAT ID / PID</p>
                </div>
            </div>

            <div className="invoice-content row px-5 pt-5">
                <div className="col-3">
                    <h5 className="almost-gray mb-3">Invoiced to:</h5>
                    <p className="gray-ish">Client Name</p>
                    <p className="gray-ish">Client Adress spanning on two rows hopefully.</p>
                    <p className="gray-ish">VAT ID: 12091803</p>
                </div>
                <div className="col-3">
                    <h5 className="almost-gray">Invoice number:</h5>
                    <p className="gray-ish"># {invoice.id}</p>

                    <h5 className="almost-gray">Date of Issue:</h5>
                    <p className="gray-ish">{invoice.created_at}</p>

                </div>
                <div className="col-6 text-right total-field">
                    <h4 className="almost-gray">Invoice Total</h4>
                    <h1 className="gray-ish">{invoice.amount} {/*<span className="curency">&euro;</span>*/}</h1>
                    <h5 className="almost-gray due-date">Due Date: 01 / 01 / 20 20</h5>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-10 offset-1 invoice-table pt-1">
                    <table className="table table-hover">
                        <thead className="thead splitForPrint">
                            <tr>
                                <th scope="col gray-ish">NO.</th>
                                <th scope="col gray-ish">Item</th>
                                <th scope="col gray-ish">Qty.</th>
                                <th scope="col gray-ish">Price</th>
                                <th scope="col gray-ish">TAX</th>
                                <th scope="col gray-ish">Discount</th>
                                <th className="text-right" scope="col gray-ish">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(invoice.items) && invoice.items.map((item, index) => {
                                return (
                                    <tr key={index++}>
                                        <th scope="row">{index}</th>
                                        <td className="item">
                                            {item.title}
                                        </td>
                                        <td>
                                            {item.quantity}
                                        </td>
                                        <td>
                                            {item.price}
                                        </td>
                                        <td>
                                            {item.tax_amount}
                                        </td>
                                        <td>
                                            {item.discount_amount}
                                        </td>
                                        <td className="text-right">{item.amount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row invoice_details">
                <div className="col-4 offset-1 pt-3">
                    <h6 className="gray-ish">Invoice Summary and Notes</h6>
                    {/* <p className="pt-3 almost-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras purus sapien, ullamcorper quis orci eu, consectetur congue nulla. In a fermentum est, ornare maximus neque. Phasellus metus risus, mattis ac sapien in, volutpat laoreet lectus. Maecenas tincidunt condimentum quam, ut porttitor dui ultricies nec.</p> */}
                </div>
                <div className="offset-1 col-5 mb-3 pr-4 sub-table">
                    <table className="table table-borderless">
                        <tbody>
                            <tr>
                                <th scope="row gray-ish">Subtotal</th>
                                <td className="text-right">{invoice.amount} <span className="currency ">&euro;</span></td>
                            </tr>
                            <tr>
                                <th scope="row gray-ish">Taxes</th>
                                <td className="text-right">{invoice.tax_amount} <span className="currency">&euro;</span></td>
                            </tr>

                            <tr>
                                <th scope="row gray-ish">Discounts</th>
                                <td className="text-right">{invoice.discount_amount} <span className="currency">&euro;</span></td>
                            </tr>
                            <tr className="last-row">
                                <th scope="row"><h4>Total</h4></th>
                                <td className="text-right"><h4>{/*<span className="currency">&euro;</span>*/} {invoice.amount}</h4></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <p className="text-center pb-3"><em> Taxes will be calculated in &euro; regarding transport and other taxable services.</em></p> */}
        </div>
    )
    return (
        <>
            {temp5}
        </>
    )
}

export default Template5