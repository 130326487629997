class FormValidation {
  constructor() {
    this.message = {
      name: "Please Enter Name",
      email: "Enter a valid email id",
      mob_no: "Enter valid mobile no.",
      father_name: "Enter father name",
      aadhar_no: "Enter aadhar number",
      trade_name: "Enter trade name", //new
      bussiness_type: "Enter bussiness_type", //new
      address: "Enter Address as per Adhar", //new
      dob: "Enter dob as per Pan", //new
      pan_number: "Enter PAN number", //new
      title: "Enter a valid Title",
      customer: "Enter a valid cusotmer",
      amount_status: "Enter a valid Status",
      transaction_date: "Enter a valid transaction date"
    };

    this.fileMessage = {
      pan_card: null,
      aadhar_card: null,
      e_bill: null, //new
      bank_detail: null,
      form_16: null, //reduntant
      photo: null,
      other_bank_statement: null
    };
  }

  validateFile(field, data) {
    let valid = false;
    // console.log("validateFile: ", data);
    return { valid, message: this.fileMessage[field] };
  }

  validate(field, data) {
    // console.log("adssa", data);
    let flag = false;
    let regExp = null;
    switch (field) {
      case "id":
        flag = true;
        break;
      case "title":
        flag = data !== "" ? true : false;
        break;
      case "category":
        flag = data !== "" ? true : false;
        break;
      case "customer":
        flag = data !== "" ? true : false;
        break;
      case "name":
        flag = data !== "" ? true : false;
        break;
      case "father_name":
        flag = data !== "" ? true : false;
        break;
      case "mob_no":
        regExp = /^\d{10}$/;
        flag = regExp.test(data) ? true : false;
        break;
      case "email":
        regExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        flag = regExp.test(data) ? true : false;
        break;

      case "aadhar_no":
        regExp = /^\d{12}$/;
        flag = regExp.test(data) ? true : false;
        break;

      case "trade_name":
        flag = data !== "" ? true : false;
        break;
      case "bussiness_type":
        flag = data !== "" ? true : false;
        break;
      case "amount":
        flag = data !== "" ? true : false;
        break;
      default:
        flag = true;
    }

    return { flag, message: this.message[field] };
  }
}

export default FormValidation;
