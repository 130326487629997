import React from 'react';
import { withRouter } from "react-router-dom";
import Form from '../../../../themes/default/ViewWrappers/Form'
import TextField from '../../../../themes/default/Inputs/Inputs.js';
import Label from '../../../../themes/default/Label/Label.js';
function data(props) {

    return (
        <>
            {props.data.buttons}
            <Form>
                {/* Left side div  */}
                <div className='col-sm-6'>
                    <div className='form-group col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']} id='Title'>
                            {'Title *'}
                        </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            name='title'
                            id='title'
                            placeholder='Enter Title'
                            onChange={props.handleChange}
                            defaultValue={`${props.data.title}`}
                        />
                    </div>

                    <div className='form-group  col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']}>{'Category *'} </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            id='category'
                            placeholder='Enter Category'
                            defaultValue={props.data.category}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className='form-group  col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']}>{'Quantity *'} </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            id='quantity'
                            placeholder='Enter Quantity'
                            defaultValue={props.data.quantity}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>

                {/* Right side div */}
                <div className='col-sm-6'>
                    <div className='form-group col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']} id='price'> {'Price *'} </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            name='price'
                            id='price'
                            placeholder='Enter price'
                            onChange={props.handleChange}
                            value={`${props.data.price}`}
                        />
                    </div>

                    <div className='form-group col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']}>
                            {'Wholesale Price'}
                        </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            name='wholesale_price'
                            id='wholesale_price'
                            placeholder='Enter Wholesale Price'
                            onChange={props.handleChange}
                            value={`${props.data.wholesale_price}`}
                        />
                    </div>

                </div>
            </Form>
        </>
    );
}
// }
export default withRouter(data);