import React, { Component } from "react";
import SearchBar from "../../../Common/SearchBar";
import List from "../../../../themes/default/ViewWrappers/List"
import { Link, withRouter } from "react-router-dom";
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Delete, Pay, Import, Export } from "../../../../themes/default/Buttons/MultiButtons"
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow, TableFooter } from "../../../../themes/default/Table/Table.js";
import Pagination from "../../../../themes/default/Pagination/Pagination";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import saleInvoiceService from '../SaleInvoiceService';
import { Store } from "../../../../GlobalProvider";
import ImportModal from "../../../../modals/ImportModal";
import InvoiceModal from "../../../../modals/InvoiceModal";
import Loder from "../../../../themes/default/Loder/Loder";
class InvoiceList extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            saleinvoices: null,
            type: parseInt(props.type) || 0,
            activePage: 1,
            isChecked: false,
            checkedAll: false,
            limit: sessionStorage.getItem("core-saleinvoices-limit") || 30,
            offset: sessionStorage.getItem("core-saleinvoices-offset") || 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            pageRangeDisplayed: 5,
            total_records: 0,
            message: "",
            showInvoiceModal: false,
            showImportModal: false,
            loding: false
        };
    }
    selectAll = event => {
        let rows = this.state.saleinvoices;
        rows.forEach(row => {
            row.isChecked = !this.state.checkedAll;
        });
        this.setState({ saleinvoices: rows, checkedAll: !this.state.checkedAll });
    };
    checkedClick = event => {
        let target = event.target;
        let { saleinvoices, checkedAll } = this.state;
        if (Array.isArray(this.state.saleinvoices) && target.dataset.index != null && this.state.saleinvoices[target.dataset.index] != null) {
            //toggele the current checkbox
            saleinvoices[target.dataset.index].isChecked = target.checked;
            //toggle the select all checkbox; 
            checkedAll = !saleinvoices.some(invoice => !invoice.isChecked);
            this.setState({ saleinvoices: saleinvoices, checkedAll: checkedAll });
        }
    };
    delete = event => {
        event.preventDefault();
        let selectedItrs = this.state.saleinvoices.filter(invoice => {
            return invoice.isChecked ? invoice.id : null;
        });
        if (selectedItrs.length === 0) {
            alert('Please select');
            return false;
        }
        let selectedIds = selectedItrs.map(selectedItr => selectedItr.id);
        let result = saleInvoiceService.delete(selectedIds);
        if (result) {
            this.getAll();
        }
    };
    async getAll() {
        this.setState({loding:true});
        let { search, orderBy, inOrder, limit, offset, type } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset, type };

        let result = await saleInvoiceService.getAll(obj, this);
        if (result) {
            this.setState({ saleinvoices: result });
        }
        this.setState({loding:false});
    }
    componentDidMount() {
        this.getAll();
    }
    OnChangePagination = (offset, limit) => {
        if (parseInt(this.state.offset) !== parseInt(offset) || parseInt(this.state.limit) !== parseInt(limit)) {
            this.setState({
                limit,
                offset,
            }, () => {
                // get List
                this.getAll();
                // Store Limit In sessionStorage
                sessionStorage.setItem("core-saleinvoice-offset", this.state.offset);
                sessionStorage.setItem("core-saleinvoice-limit", this.state.limit);
            });
        }
    };
    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };
    handleSearch = event => {
        let search = event;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };
    handlePrint(invoice) {
        this.setState({
            showInvoiceModal: true,
            modalData: invoice
        })
    }

    handleExport = async () => {
        let selectedInvoices = this.state.saleinvoices.filter(invoice => {
            return invoice.isChecked ? invoice.id : null;
        });
        if (selectedInvoices.length === 0) {
            alert('Please Select')
            return false
        }
        let selectedIds = selectedInvoices.map(selectedInvoice => selectedInvoice.id);
        let result = await saleInvoiceService.export(selectedIds);
        if (result) {
            this.getAll();
        }
    }
    handleInvoiceModal = () => {
        this.setState({ showInvoiceModal: !this.state.showInvoiceModal });
    }
    handleImportModal = () => {
        this.setState({ showImportModal: !this.state.showImportModal })
    }
    handleImport = async event => {
        let file = { ...this.state.file };
        file = event
        this.setState({ file: file });
        let form = new FormData();
        form.append("file", file)
        let result = await saleInvoiceService.import(form)
        if (result) {
            this.setState({ message: "File Imported Succesfully" })
            this.getAll();
        } else {
            this.setState({ message: "File Import Failed" })
        }
    }
    render() {
        const saleinvoices = this.state.saleinvoices;
        return (
            <List>
                <HeaderWrapper title="Sale Invoices">
                    <SearchBar onClick={this.handleSearch} />
                    <Delete onClick={this.delete} />
                    <InvoiceModal onHide={this.handleInvoiceModal} show={this.state.showInvoiceModal} modalData={this.state.modalData} />
                    <Import onClick={this.handleImportModal} />
                    <ImportModal onHide={this.handleImportModal} show={this.state.showImportModal} handleImport={this.handleImport} message={this.state.message} />
                    <Export onClick={this.handleExport} />
                </HeaderWrapper>
                <Loder show={this.state.loding} />
                {this.state.message}
                <Table className={["invoice-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading width="30">
                                <CheckBox id="select_all" index={999999} onClick={this.selectAll} checked={this.state.checkedAll} />
                            </TableHeading>
                            <TableHeading id={["Customer"]} onClick={this.handleOrdering}>
                                Customer
                        </TableHeading>
                            <TableHeading id={["Date"]} onClick={this.handleOrdering}>
                                Date
                        </TableHeading>
                            <TableHeading id={["amount"]} onClick={this.handleOrdering} >
                                Amount
                            </TableHeading>
                            <TableHeading id={["amount"]} onClick={this.handleOrdering} >
                                Balance Due
                            </TableHeading>
                            <TableHeading id={["action"]} onClick={this.handleOrdering} >
                                Action
                            </TableHeading>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(saleinvoices) && saleinvoices.map((invoice, index) => {
                            if (typeof invoice.isChecked === "undefined")
                                invoice.isChecked = false;
                            return (

                                <TableRow key={index}>
                                    <TableCell>
                                        <CheckBox
                                            id={`cid${invoice.id}`}
                                            name="cid[]"
                                            value={invoice.id}
                                            index={index}
                                            onClick={this.checkedClick}
                                            checked={invoice.isChecked}
                                        />
                                    </TableCell>
                                    <TableCell><Link to={`${this.props.match.path}/edit/${invoice.id}`}>{`${invoice.customer?.name} ${invoice.customer?.last_name}`}</Link></TableCell>
                                    <TableCell>{invoice.created_at}</TableCell>
                                    <TableCell>{invoice.amount}</TableCell>
                                    <TableCell>{invoice.balance_due ? invoice.balance_due : <span className="Paid">paid</span>}</TableCell>
                                    <TableCell onClick={() => this.handlePrint(invoice)}><span>print</span></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={13}>
                                <Pagination
                                    total_recordsCount={this.state.total_records}
                                    onChange={() => { }}
                                    limit={this.state.limit}
                                    offset={this.state.offset}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>

            </List>
        );
    }
}
export default withRouter(InvoiceList);
