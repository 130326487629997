import React from "react";
import {  Link } from "react-router-dom";

import Button from "./themes/default/Buttons/Buttons.js";
import Checkbox from "./themes/default/Inputs/Checkbox.js";
import TextField from "./themes/default/Inputs/Inputs.js";
import Form from "./themes/default/Form/Form.js";
import Label from "./themes/default/Label/Label.js";
import axios from "./http/Axios";
import Validation from "./components/Common/Helper";
let img = "/assets/images/ym.png";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem("email") || "",
      password: localStorage.getItem("password") || "",
      forgot:false,
      remember_me:false,
      meessage: ""
    };
  }
  forgot = event => {
    event.preventDefault();
    this.setState({forgot:!this.state.forgot})
  }
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };
  back = event => {
    event.preventDefault();
    this.setState({forgot:false})
    // this.setState({ [event.target.id]: event.target.value });
  };
  submitForm = event => {
    event.preventDefault();
    let email = this.state.email;
    if (!email) {
      alert("Email  is empty");
      return false;
    } else {
      if (!Validation.validateEmail(email)) {
        alert("Email is not valid");
        return false;
      }
    }
      if(!this.state.forgot){

      let password = this.state.password;

        if (!password) {
          alert(" Password is empty");
          return false;
        }

        let data = {
          email: email,
          password: password
        };
        
      axios.post('login', data)
      .then(res=>{
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_id", res.data.user_id);
        if(this.state.remember_me){
          localStorage.setItem("email",this.state.email);
          localStorage.setItem("password",this.state.password);
        }
        window.location.href = "/";
      })
      .catch(error=>{
        //console.log(error.response);
        this.setState({ message: "Invalid email or password" });
      });

    }else{
      this.setState({message:"A link is sent to your email. You can reset your password with that link."})
    }
  };
  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push("/");
    }
  }
  render() {
    let forgot = this.state.forgot;
    return (
      <>
        <div id="before-content-div" className="login_form">
          {this.state.message}
          <Form id="login_form" name="login_form" className={["from_login"]}>
            <div className="login_form_inner">
              <div className="form-group col-sm-12 text-center">
                <div className="logo-icon">
                  <img src={img} alt="Logo" />
                </div>
                <h1>{!this.state.forgot?"Sign In To Admin":"Forgot Password"}</h1>
              </div>
              <div className="form-group col-sm-12 text-left">
                <TextField
                  type="text"
                  className={["form-control", "col-sm-12", "d-inline-block"]}
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={this.handleChange}
                  value={`${this.state.email}`}
                />
              </div>
			  {!this.state.forgot?(
              <div className="form-group col-sm-12 text-left">
                <TextField
                  type="password"
                  className={["form-control", "col-sm-12", "d-inline-block"]}
                  name="password"
                  id="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                  value={`${this.state.password}`}
                />
              </div>
			  ):""}
              <div className="form-group col-sm-12 text-left">
                <div className="row">
				{!this.state.forgot?(
                  <div className="form-group col-sm-6 text-left form-group-remember">
                    <Label className={["rm-checkbox"]}><Checkbox onClick={() => {
                        this.setState({remember_me:!this.state.remember_me})
                    }} checked={this.state.remember_me || false}/>
					{"Remember me"} <span></span></Label>
                  </div>
				):""}
                  <div className={`form-group text-right ${this.state.forgot?"col-sm-12":"col-sm-6"}`}>
                  {
                    this.state.forgot ?(<Link to="#" onClick={this.forgot}>{"Back to Login"}</Link>):(
                    <Link to="#" onClick={this.forgot}>{"Forgot Password ?"}</Link>)
                  }
                  <Link to="/user">{"Sign Up"}</Link>
                  </div>
                </div>
              </div>
              <div className="form-group col-sm-12 text-center form-group-submit">
                <Button
                  type="submit"
                  className={["btn", "btn-primary"]}
                  onClick={this.submitForm}
                >
				{!forgot?"Sign In":"Submit"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
export default Login;
