// const api_base_url = process.env.REACT_APP_ROOT_URL;
const api_base_url = process.env.REACT_APP_SERVER_URL;

class HttpClient {
  /**
   * Get token
   */
  getToken() {
    return localStorage.getItem("token");
  }

  async send(options, endpoint, responseFlag = false, context = null) {
    const token = this.getToken();
    const url = api_base_url + "" + endpoint;
    let obj = {};
    obj.headers = {};
    /**
     * Set headers
     *
     * Set Content-type
     */
    if (typeof options.file === "undefined") {
      obj.headers = {
        "Content-type": "application/json"
      };
    }

    /**
     * Set token
     */
    if (token) {
      obj.headers.Authorization = "Bearer " + token;
    }

    /**
     * Set user send options headers
     */
    if (typeof options.headers !== "undefined" && options.headers) {
      obj.headers = { ...obj.headers, ...options.headers };
    }
    /**
     * End headers
     */

    /**
     * Set method
     */
    obj.method = options.method;

    /**
     * Set body
     */
    if (typeof options.body !== "undefined" && options.body) {
      obj.body = options.body;
    }

    /**
     * Hit api
     *
     * And return response
     */
    return fetch(url, obj)
      .then(response => {
        localStorage.setItem("connected", true);
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          return Promise.reject("Session Expired! Please Log In Again");
        }
        if (responseFlag) {
          return response;
        }
        if (response.status === 204) return response;
        else return response.json();
      })
      .catch(error => {
        console.log(error);
        if (error.name === "SyntaxError") {
        } else {
          localStorage.removeItem("connected");
        }
        return Promise.reject(error);
      });
  }
  // async delete(id) {
  //   alert("http delete");
  // }
  async download(options, endpoint) {
    const token = this.getToken();
    const url = api_base_url + "" + endpoint;
    let obj = {};
    obj.headers = {};
    if (typeof options.file === "undefined") {
      obj.headers = {
        "Content-type": "application/json"
      };
    }
    if (token) {
      obj.headers.Authorization = "Bearer " + token;
    }
    if (typeof options.headers !== "undefined" && options.headers) {
      obj.headers = { ...obj.headers, ...options.headers };
    }
    obj.method = options.method;
    if (typeof options.body !== "undefined" && options.body) {
      obj.body = options.body;
    }

    let filename = options.filename;
    //hit api
    let response = await fetch(url, obj);
    console.log(response);
    return null;
    if (response.status) {
      let blob = await response.blob();
      const durl = window.URL.createObjectURL(
        new Blob([blob], { type: "text/bin" })
      );
      const link = document.createElement("a");
      link.href = durl;
      link.setAttribute("download", filename);
      link.click();
      return { success: true };
    } else {
      return response;
    }
  }

  extractMessage(response) {
    console.log(response);
    var message = {
      body: "Oops!! Something went wrong.",
      dataType: "string",
      type: "error"
    };
    if (response != null) {
      let body = null;
      if (
        response.body != null &&
        response.contextType === "application/problem+json"
      ) {
        body = response.body;
      } else {
        body = response;
      }
      if (body.title != null) {
        message = {};
        message.title = body.title;
        if (body.detail == null) {
          message.title = null;
        }
        message.body = body.detail ? body.detail : body.title;
        // message.dataType = "string";
        message.type = "error";
      }
    }
    return message;
  }
}

export default new HttpClient();
