import React from 'react';

const TextArea = (props) => {
    return (
        <textarea className={Array.isArray(props.className)?props.className.join(' '):''} onChange={props.onChange}  name={props.name} id={props.id} placeholder={props.placeholder} value={props.value} rows={props.rows} cols={props.cols}/>        
    );
}


export default TextArea;
