import React from "react";
function Template2(props) {
    let invoice = props.salesinvoice
    console.log(invoice)
    const temp2 = (
        <div className="invoice-box">
            <table cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr className="top">
                        <td colSpan="5">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="title">
                                            <img src="https://www.sparksuite.com/images/logo.png" style={{ "width": "100%", "maxWidth": "300px" }} alt="" />
                                        </td>
                                        <td>
                                            Invoice #: {invoice.id}<br />
                                            Created: {invoice.created_at}<br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    {/* information not needed yet */}
                    {/* <tr className="information">
                        <td colSpan="2">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            Sparksuite, Inc.<br />
                                            12345 Sunny Road<br />
                                            Sunnyville, CA 12345
                        </td>

                                        <td>
                                            Acme Corp.<br />
                                            John Doe<br />
                                            john@example.com
                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr> */}
                    <tr className="heading" >
                        <td colSpan="2">
                            Payment Method
                        </td>

                        <td colSpan="3">
                            {invoice.payment_type} #
                        </td>
                    </tr>

                    <tr className="details">
                        <td colSpan="2">
                            {invoice.payment_type}
                        </td>

                        <td colSpan="2">
                            {/* Paying amount */}
                            Paid:  {invoice.pay}
                        </td>
                    </tr>

                    <tr className="heading">
                        <td>
                            Item
                        </td>

                        <td>
                            Price
                        </td>

                        <td>
                            Discount
                        </td>
                        <td>
                            Tax
                        </td>
                    </tr>

                    {Array.isArray(invoice.items) && invoice.items.map((item, index) => {

                        return (
                            <tr className="item" key={index}>
                                <td>
                                    {item.title}
                                </td>
                                <td>
                                    {item.price}
                                </td>

                                <td>
                                    {invoice.discount_amount}
                                </td>
                                <td>
                                    {invoice.tax_amount}
                                </td>
                            </tr>
                        )
                    })}

                    <tr className="total">
                        <td></td>

                        <td>
                            Total: {invoice.amount}
                        </td>
                    </tr>
                </tbody>
            </table >
        </div >
    )
    return (
        <>
            {temp2}
        </>
    )
}

export default Template2