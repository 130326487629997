import React from "react";

import Button from "./Buttons";
import Icon from "../Icons/Icon";
import UploadButton from "../UploadButton/UploadButton.js";

const Add = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faPlus"} /> {!iconOnly ? title || "Add" : ""}
    </Button>
  );
};

const Adjustment = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button onClick={onClick} variant={"success"} className={["ml-2"]}>
      <Icon icon={"faPlus"} /> {!iconOnly ? title || "Adjustment" : ""}
    </Button>
  );
};

const Convert = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button
      className={["btn-sm", "btn-success"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faPlus"} /> {!iconOnly ? title || "Invoice" : ""}
    </Button>
  );
};
const LeftArrow = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faArrowLeft"} /> {!iconOnly ? title : ""}
    </Button>
  );
};

const RightArrow = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faArrowRight"} /> {!iconOnly ? title : ""}
    </Button>
  );
};

const Edit = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button className={["btn", "btn-info", "ml-2"]} onClick={onClick} {...rest}>
      <Icon icon={"faEdit"} /> {!iconOnly ? title || "Edit" : ""}
    </Button>
  );
};

const Update = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faSyncAlt"} /> {title || "Update"}
    </Button>
  );
};

const Save = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faCheck"} /> {!iconOnly ? title || "Save" : ""}
    </Button>
  );
};

const Delete = ({ onClick, title, iconOnly, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-danger", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faTimes"} /> {!iconOnly ? title || "Delete" : ""}
    </Button>
  );
};

const Submit = ({ onClick, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faPaperPlane"} /> {title || "Submit"}
    </Button>
  );
};

const Exit = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn", "btn-dark", "ml-2"]} onClick={onClick} {...rest}>
      <Icon icon={"faAngleLeft"} /> {title || "Exit"}
    </Button>
  );
};

const Search = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn", "btn-secondary"]} onClick={onClick} {...rest}>
      <Icon icon={"faSearch"} /> {title || ""}
    </Button>
  );
};

const Clear = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn", "btn-danger"]} onClick={onClick} {...rest}>
      <Icon icon={"faTimes"} /> {title || ""}
    </Button>
  );
};

const View = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn", "btn-info", "ml-2"]} onClick={onClick} {...rest}>
      <Icon icon={"faTh"} /> {title || "View"}
    </Button>
  );
};

const Download = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn", "btn-info", "ml-2"]} onClick={onClick} {...rest}>
      <Icon icon={"faDownload"} /> {title || "Download"}
    </Button>
  );
};

const Share = ({ onClick, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-primary", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faShare"} /> {title || "Share"}
    </Button>
  );
};

const Move = ({ onClick, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-warning", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faCopy"} /> {title || "Move"}
    </Button>
  );
};

const Copy = ({ onClick, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-warning", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faCopy"} /> {title || "Copy"}
    </Button>
  );
};

const Rename = ({ onClick, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faTimes"} /> {title || "Rename"}
    </Button>
  );
};

const Upload = ({ onChange, title, ...rest }) => {
  return (
    <UploadButton
      className={["btn", "btn-success", "ml-2"]}
      onChange={onChange}
      {...rest}
    >
      <Icon icon={"faUpload"} /> {title || "Upload"}
    </UploadButton>
  );
};

const Restore = ({ onClick, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-primary", "ml-2"]}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faUndo"} /> {title || "Restore"}
    </Button>
  );
};

const Import = ({ onChange, title, onClick, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-success", "ml-2"]}
      onChange={onChange}
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faFileImport"} /> {title || "Import"}
    </Button>
  )
};

const Export = ({ onChange, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-primary", "ml-2"]}
      onChange={onChange}
      {...rest}
    >
      <Icon icon={"faFileExport"} /> {title || "Export"}
    </Button>
  );
};

const Close = ({ onClick, title, ...rest }) => {
  return (
    <Button
      className={["btn", "btn-danger", "ml-2"]}
      variant="secondary"
      onClick={onClick}
      {...rest}
    >
      <Icon icon={"faTimes"} /> {title || "Close"}
    </Button>
  );
};

const Calendar = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn"]} variant="primary" onClick={onClick} {...rest}>
      <Icon icon={"faCalendar"} /> {title || ""}
    </Button>
  );
};

const Refresh = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn"]} variant="primary" onClick={onClick} {...rest}>
      <Icon icon={"faReload"} /> {title || "Refresh"}
    </Button>
  );
};

const Favourite = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn"]} variant="primary" onClick={onClick} {...rest}>
      <Icon icon={"faStar"} /> {title || "Favourite"}
    </Button>
  );
};

const Select = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn", "ml-2"]} variant="primary" onClick={onClick} {...rest}>
      <Icon icon={"faCut"} /> {title || "Select"}
    </Button>
  );
};

const Pay = ({ onClick, title, ...rest }) => {
  return (
    <Button className={["btn", "ml-2"]} variant="primary" onClick={onClick} {...rest}>
      <Icon icon={"faRupeeSign"} /> {title || "Pay"}
    </Button>
  );
};

export {
  Add,
  Adjustment,
  Convert,
  LeftArrow,
  RightArrow,
  Save,
  Edit,
  Update,
  Delete,
  Import,
  Export,
  Favourite,
  Submit,
  Exit,
  Search,
  Clear,
  View,
  Move,
  Copy,
  Share,
  Rename,
  Download,
  Restore,
  Upload,
  Close,
  Calendar,
  Refresh,
  Select,
  Pay
};
