import React from "react"
import PageLoading from "../Loadings/PageLoading"

const Form = (props) => {
    return (
        <>
            <PageLoading show={props!=null && props.pageLoading} />
            <div className="uform col-sm-12">
                <div className="uform_inner user_form">
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default Form;