import React, { Component } from "react";
import Form from "../../../../themes/default/ViewWrappers/Form";
import { Store } from "../../../../GlobalProvider";
import HeaderWrapper from "../../../../themes/default/HeaderWrapper";
import { Submit, Exit, Update } from "../../../../themes/default/Buttons/MultiButtons";
import TextField from "../../../../themes/default/Inputs/Inputs.js";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import File from "../../../../themes/default/Inputs/File.js";
import Label from "../../../../themes/default/Label/Label.js";
import GstReturnsService from "../GstReturnsService.js";
import FormValidation from "../../../../themes/default/assets/js/FormValidation.js";
let gstReturnsService = new GstReturnsService();
class Edit extends Component {
  static contextType = Store;
  constructor(props) {
    super(props);

    this.state = {
      files: {
        bank_detail: null,
        sales_excel_sheet: null,
        purchase_excel_sheet: null,
      },
      data: {
        id: parseInt(this.props.match.params.id) || 0,
        isRegistered: false,
        gst_id: "",
        gst_password: "",
      }
    };
  }
  componentDidMount() {
    if (this.state.data.id !== 0) {
      this.getDetails(this.state.data.id);
    }
  }

  async getDetails(id) {
    const result = await gstReturnsService.get(id, this);
    if (result !== null) {
      this.setState({ data: result });
    }
  }

  handleChange = event => {
    let field = event.target.id;
    let data = { ...this.state.data };
    data[field] = event.target.value;
    this.setState({ data: data });
  };

  checkedClick = event => {
    let data = this.state.data;
    data.isRegistered = event.target.checked;
    this.setState({ data: data });
  };

  handleFile = event => {
    let id = event.target.id;
    let files = { ...this.state.files };
    files[id] = event.target.files[0];
    this.setState({ files: files });
  };
  submitForm = event => {
    event.preventDefault();
    let validation = new FormValidation();
    for (let key in this.state.data) {
      let result = validation.validate(key, this.state.data[key]);
      if (!result.flag) {
        alert(result.message);
        return;
      }
    }
    let datas = this.state;
    console.log("datas=>", datas);
    gstReturnsService.store(datas, this).then(resp => {
      this.setState({ data: resp }, () => {
      });
      this.props.history.push('/gstreturns/edit/' + this.state.data.id);
    });

  };

  render() {
    let submitButton = null;
    if (this.state.data.id !== 0) {
      submitButton = <Update onClick={this.submitForm} />;
    } else {
      submitButton = <Submit onClick={this.submitForm} />;
    }
    return (
      <Form>
        <HeaderWrapper
          title={`Gst Returns (${this.state.data.id ? "Edit" : "New"})`}
        >
          {submitButton}
          <Exit
            onClick={() => {
              this.props.history.push("/gstreturns");
            }}
          />
        </HeaderWrapper>

        {/* Left side div  */}

        <div className="col-sm-12 mb-3 bg-light p-2 mt-3">
          <div className="form-group col-sm-12 text-left mb-0">
            <Label className={["px-0", "col-sm-3"]}>
              {"Already Registered on E- filling"}
            </Label>
            <CheckBox
              className={["align-middle"]}
              id={`isRegistered`}
              name="cid[]"
              value={this.state.data.isRegistered}
              index={1}
              onClick={this.checkedClick}
              checked={this.state.data.isRegistered}
            />
          </div>
          <div className="col-sm-6 mt-3" hidden={this.state.data.isRegistered}>
            <div className="form-group col-sm-12 text-left">
              <Label className={["d-inline-block", "col-sm-3"]}>
                {"Log in-ID"}
              </Label>
              <TextField
                className={["form-control", "col-sm-9", "d-inline-block"]}
                id="gst_id"
                placeholder="Enter login id"
                onChange={this.handleChange}
                value={this.state.data.gst_id}
              />
            </div>
          </div>
          <div className="col-sm-6 mt-3" hidden={this.state.data.isRegistered}>
            <div className="form-group col-sm-12 text-left">
              <Label className={["d-inline-block", "col-sm-3"]}>
                {"Password"}
              </Label>
              <TextField
                className={["form-control", "col-sm-9", "d-inline-block"]}
                id="gst_password"
                placeholder="Enter password"
                type="password"
                onChange={this.handleChange}
                value={this.state.data.gst_password}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group col-sm-12 text-left">
            <Label className={["d-inline-block", "col-sm-6"]}>

              {"Bank Statement / Deposit of the month"}
            </Label>
            {/* <img src={this.state.files.bank_detail ? (process.env.REACT_APP_SERVER_URL + "/media?location=" + this.state.files.bank_detail) : ""} width="80px" height="80px" alt="" /> */}
            <File
              className={["form-control", "col-sm-9", "d-inline-block"]}
              id="bank_detail"
              onChange={this.handleFile}
            />
          </div>

          <div className="form-group col-sm-12 text-left">
            <Label className={["d-inline-block", "col-sm-6"]}>
              {"Sales Excel Sheet"}
            </Label>
            {/* attachments */}
            {/* <object data={process.env.REACT_APP_SERVER_URL + "/media?location=" + this.state.files.sales_excel_sheet} width="80px" height="80px" type="image/jpg">
            </object> */}
            {/* <img src={this.state.files.sales_excel_sheet ? (process.env.REACT_APP_SERVER_URL + "/media?location=" + this.state.files.sales_excel_sheet) : ""} width="80px" height="80px" alt="" /> */}

            <File
              className={["form-control", "col-sm-9", "d-inline-block"]}
              id="sales_excel_sheet"
              onChange={this.handleFile}
            />

          </div>
          <div className="form-group col-sm-12 text-left">
            <Label className={["d-inline-block", "col-sm-6"]}>
              {"Purchase Excel Sheet"}
            </Label>
            {/* <img src={this.state.data.id ? (process.env.REACT_APP_SERVER_URL + "/media?location=" + this.state.files.purchase_excel_sheet) : (this.state.files.purchase_excel_sheet ? (process.env.REACT_APP_SERVER_URL + "/media?location=gstReturns/" + this.state.files.purchase_excel_sheet.name) : "")} width="80px" height="80px" alt="" /> */}

            <File
              className={["form-control", "col-sm-9", "d-inline-block"]}
              id="purchase_excel_sheet"
              onChange={this.handleFile}
            />

          </div>
        </div>

      </Form >
    );
  }
}

export default Edit;
