import React, { Component } from 'react'
import HeaderWrapper from '../../../../themes/default/HeaderWrapper';
import SearchBar from '../../../Common/SearchBar';
import { Add, Delete } from '../../../../themes/default/Buttons/MultiButtons';
import List from '../../../../themes/default/ViewWrappers/List';
import { TableCell, TableBody, TableRow, TableHeading, TableHead } from '../../../../themes/default/Table/Table';
import { Link } from 'react-router-dom';
import CheckBox from '../../../../themes/default/Inputs/Checkbox';
import { Table } from 'react-bootstrap';
import userService from '../UserService';
class userList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            isChecked: false,
            checkedAll: false,
            search: "",
            message: "",
            type: this.props.type
        }
    }
    selectAll = event => {
        let { users } = this.state;
        users.forEach(userdata => {
            userdata.isChecked = !this.state.checkedAll;
        });
        this.setState({ users: users, checkedAll: !this.state.checkedAll });
    };

    checkedClick = event => {
        let target = event.target;
        let { users, checkedAll } = this.state;
        if (Array.isArray(this.state.users) && target.dataset.index != null && this.state.users[target.dataset.index] != null) {
            //toggele the current checkbox
            users[target.dataset.index].isChecked = target.checked;
            //toggle the select all checkbox; 
            checkedAll = !users.some(user => !user.isChecked);
            this.setState({ users: users, checkedAll: checkedAll });
        }
    };

    delete = async id => {
        let result = await userService.delete(id);
        if (result) {
            this.getAll();
        }
    };


    async getAll() {
        let { search, orderBy, inOrder, limit, offset, type } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset, type };
        let result = await userService.getAll(obj, this);
        this.setState({ users: result });

    }
    componentDidMount() {
        this.getAll();
    }

    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };

    handleSearch = event => {
        let search = event;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };
    render() {
        const users = this.state.users
        return (
            <List>
                <HeaderWrapper title={`${this.state.type.charAt(0).toUpperCase()}${this.state.type.slice(1)}`}>
                    <SearchBar onClick={this.handleSearch} />
                    <Add onClick={() => { this.props.history.push(`${this.props.match.path}/add`); }} />
                </HeaderWrapper>
                <Table className={["user-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading id={['id']} onClick={this.handleOrdering}>
                                Id
                            </TableHeading>
                            <TableHeading id={["name"]} onClick={this.handleOrdering}>
                                Name
                            </TableHeading>
                            <TableHeading id={["contact"]} onClick={this.handleOrdering}>
                                Phone
                            </TableHeading>
                            <TableHeading id={["email"]} onClick={this.handleOrdering}>
                                Email
                            </TableHeading>
                            <TableHeading id={["id"]} onClick={this.handleOrdering} >
                                Address
                            </TableHeading>
                            <TableHeading id={["action"]} onClick={this.handleOrdering}>
                                Action
                            </TableHeading>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(users) && users.map((user, index) => {
                            return (
                                <TableRow key={user.id}>
                                    <TableCell>
                                        {user.id}
                                    </TableCell>
                                    <TableCell>
                                        {user.name}
                                    </TableCell>
                                    <TableCell>
                                        {user.contact_no}
                                    </TableCell>
                                    <TableCell>
                                        {user.email}
                                    </TableCell>
                                    <TableCell>
                                        {user.address}
                                    </TableCell>
                                    <TableCell><Link to={`${this.props.match.path}/edit/${user.id}`}><i className="fa fa-edit" ></i></Link>| <i className="fa fa-trash" onClick={()=>this.delete(user.id)}></i></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </List >
        );
    }
}

export default userList;

