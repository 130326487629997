import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../../Common/SearchBar";
import List from "../../../../themes/default/ViewWrappers/List"
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Add, Delete } from "../../../../themes/default/Buttons/MultiButtons"
import Table, {
    TableHead,
    TableHeading,
    TableBody,
    TableCell,
    TableRow,
    TableFooter
} from "../../../../themes/default/Table/Table.js";
import Pagination from "../../../../themes/default/Pagination/Pagination";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import Dsc24Service from '../Dsc24Service'
import { Store } from "../../../../GlobalProvider";
let dsc24Service = new Dsc24Service();
class Dsc24List extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            dscs: [],
            activePage: 1,
            isChecked: false,
            checkedAll: false,
            limit: sessionStorage.getItem("core-itrs-limit") || 30,
            offset: sessionStorage.getItem("core-itrs-offset") || 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            pageRangeDisplayed: 5,
            total_records: 0,
            message: ""
        };
    }

    selectAll = event => {
        let { dscs } = this.state;
        dscs.forEach(dscdata => {
            dscdata.isChecked = !this.state.checkedAll;
        });
        this.setState({ dscs: dscs, checkedAll: !this.state.checkedAll });
    };

    checkedClick = ({ target }) => {
        let { dscs, checkedAll } = this.state;
        if (Array.isArray(dscs) && target.dataset.index != null && dscs[target.dataset.index] != null) {
            //toggele the current checkbox
            dscs[target.dataset.index].isChecked = target.checked;
            //toggle the select all checkbox; 
            checkedAll = !dscs.some(dscdata => !dscdata.isChecked);
            this.setState({ dscs: dscs, checkedAll: checkedAll });
        }
    };

    delete = async event => {
        event.preventDefault();
        let selectedDscs = this.state.dscs.filter(dscdata => dscdata.isChecked());

        if (selectedDscs.length === 0) {
            alert('Please select');
            return;
        }

        let selectedIds = selectedDscs.map(dscdata => dscdata.id);
        let result = await dsc24Service.delete(selectedIds);
        if (result) {
            this.getAll();
        }
    };

    async getAll() {
        let { search, orderBy, inOrder, limit, offset } = this.state;
        let obj = { search, orderBy, inOrder, limit, offset };
        let result = await dsc24Service.getAll(obj, this);
        this.setState({ dscs: result });

    }
    componentDidMount() {
        this.getAll();
    }
    OnChangePagination = (offset, limit) => {
        if (parseInt(this.state.offset) !== parseInt(offset) || parseInt(this.state.limit) !== parseInt(limit)) {
            this.setState({
                limit: limit,
                // offset,
            }, () => {
                // get List
                // this.getAll();

                // Store Limit In sessionStorage
                sessionStorage.setItem("core-dsc24-offset", this.state.offset);
                sessionStorage.setItem("core-dsc24-limit", this.state.limit);
            });
        }
    };

    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };

    handleSearch = event => {
        let search = event;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };

    render() {
        const dscs = this.state.dscs;
        return (
            <List>
                <HeaderWrapper title="Dsc24">
                    <SearchBar onClick={this.handleSearch} />
                    <Add onClick={() => { this.props.history.push(`${this.props.match.path}/Add`); }} />
                    <Delete onClick={this.delete} />
                </HeaderWrapper>

                {this.state.message}

                <Table className={["dsc-table", "table", "table-striped"]}> {/*to be change*/}
                    <TableHead>
                        <TableRow>
                            <TableHeading width="30">
                                <CheckBox id="select_all" index={999999} onClick={this.selectAll} checked={this.state.checkedAll} />
                            </TableHeading>
                            <TableHeading id={["name"]} onClick={this.handleOrdering}>
                                Name
                        </TableHeading>
                            <TableHeading id={["email"]} onClick={this.handleOrdering}>
                                Email
                        </TableHeading>
                            <TableHeading id={["id"]} onClick={this.handleOrdering} >
                                DSC Registered On
                            </TableHeading>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Array.isArray(dscs) && dscs.slice(this.state.offset, this.state.limit).map((dsc24, index) => {
                            if (typeof dsc24.isChecked === "undefined")
                                dsc24.isChecked = false;
                            return (
                                <TableRow key={dsc24.id}>
                                    <TableCell>
                                        <CheckBox
                                            id={`cid${dsc24.id}`}
                                            name="cid[]"
                                            value={dsc24.id}
                                            index={index}
                                            onClick={this.checkedClick}
                                            checked={dsc24.isChecked}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`${this.props.match.path}/edit/${dsc24.id}`}>
                                            {dsc24.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{dsc24.email}</TableCell>
                                    <TableCell>{dsc24.created_at}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={13}>
                                <Pagination
                                    // total_recordsCount={this.state.total_records}
                                    // onChange={() => { }}
                                    // limit={this.state.limit}
                                    // offset={this.state.offset}
                                    onChange={(offset, limit) => { this.OnChangePagination(offset, limit) }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </List>
        );
    }
}

export default Dsc24List;
