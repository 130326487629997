import React from "react";
import Button from "../../../themes/default/Buttons/Buttons";
import Icon from "../../../themes/default/Icons/Icon";
import { withRouter } from "react-router-dom";
import { Store } from "../../../GlobalProvider";
import connect from "../../../reduxutils/Connect";
import PropTypes from "prop-types";
import * as ActionTypes from "./actions";
/*
 * message format
   {
   title:"Message Title 1",
   dataType:"list",
   type:"error"
   body:["Email is invalid","Spare part name already taken"]
  }
  or
  {
  title:"Message Title 2",
  dataType: "string",
  type:"success"
  body:"Message to be displayed"
  }
  { body: "Test Message 1", type: "error", dataType: "string" },
  {
    body: ["Test Message 2", "Test Message 3"],
    type: "error",
    dataType: "list"
  }
*/
class MessageBoard extends React.PureComponent {
  static contextType = Store;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showAll: false
    };
  }

  _format = (message, index) => {
    if (message == null) return;

    let inc = 1;
    return (
      <div
        key={"MSG_B-container-" + index}
        className={"msg-box " + message.type || "success"}
      >
        {message.title != null ? <strong>{message.title}</strong> : ""}
        {typeof message.body === "string" ? (
          message.body
        ) : Array.isArray(message.body) ? (
          <>
            <ul>
              {message.body.map(m => (
                <li key={index + "-" + inc++}>{m}</li>
              ))}
            </ul>
          </>
        ) : (
              ""
            )}
        <Icon
          key={"MSG-B-container-close-" + index}
          onClick={this._close(index)}
          icon="faCheck"
        />
      </div>
    );
  };
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this._closeAll();
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  static getDerivedStateFromProps(props, state) {
    return { show: props.show, messages: props.messages };
  }

  _closeAll = () => {
    this.context.dispatch({ type: ActionTypes.MSB_RESET_BOARD });
  };
  _close = index => () => {
    let m = this.state.messages.filter((message, i) => {
      return i !== index;
    }, index);
    this.context.dispatch({
      type: ActionTypes.MSB_OVERWRITE_MESSAGE,
      message: m,
      show: m.length < 1 ? false : true
    });
  };

  render() {
    let { messages } = this.state;
    let i = 0;

    return this.state.show ? (
      <div className="MSG-B main">
        {messages.map((message, index) => {
          return i++ > 1 && !this.state.showAll
            ? null
            : this._format(message, index);
        })}
        <Button
          key={"MSG-B-main-showAll"}
          onClick={() => {
            this.setState(prevState => {
              return { showAll: !prevState.showAll };
            });
          }}
        >
          Show All
        </Button>
        <Button key={"MSG-B-main-close"} onClick={this._closeAll}>
          Clear All
        </Button>
      </div>
    ) : null;
  }
}
function mapStateToProps(state, props) {
  let messages =
    state != null && state.messages != null ? state.messages._messages : [];

  return {
    messages,
    show: messages.length > 0 ? true : false
  };
}
MessageBoard.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataType: PropTypes.oneOf(["list", "string"]),
      type: PropTypes.oneOf(["success", "error"]),
      body: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    })
  ).isRequired
};
MessageBoard = connect(mapStateToProps)(withRouter(MessageBoard));


const showMessage = ({ title, message, dataType, messageType, type }, context) => {
  // let = args;
  if (context == null || typeof context.dispatch !== "function") {
    debugMessage("context is undefined, you need to pass this as second argument");
    return;
  }
  if (title == null && message == null) {
    message = "Oops!! Something went wrong.";
  }
  context.dispatch({
    type: type || ActionTypes.MSB_ADD_MESSAGE,
    message: {
      title,
      dataType: dataType || "string",
      type: messageType || "success",
      body: message
    }
  });
}

const debugMessage = (message) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.trace(message);
  }
}
export { MessageBoard, showMessage, debugMessage };

