import React from "react";
import { Refresh as RefreshButton } from "./themes/default/Buttons/MultiButtons"

class MainErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        }
    }
    componentDidCatch(error, info) {
        console.log(error, info);
        this.setState({ hasError: true });
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<div>
                <h1>Oops! something went Wrong.</h1>
                <h2>Click OK to refresh</h2>
                <RefreshButton onClick={() =>
                    (window.location != null && typeof window.location.reload === "function" && window.location.reload(true))
                } />
            </div>);
        }
        return this.props.children;
    }
}

export default MainErrorBoundary;