import React, { Component } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Invoice from './Invoice'
class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 123,
            customer: "John",
            amount: 385.00,
            balance_due: 100.00,
            payment_type: "Check",
            items: [{
                id: 7,
                tax: 3,
                price: 30,
                title: "Watch",
                amount: 54,
                discount: 2,
                quantity: 2,
                tax_amount: 4,
                discount_amount: 2
            }, {
                id: 1,
                tax: 3,
                price: 30,
                title: "Shoe",
                amount: 54,
                discount: 2,
                quantity: 2,
                tax_amount: 4,
                discount_amount: 2
            }],
            created_at: "January 1, 2015",
            discount_amount: 1000,
            tax_amount: 100,
            currentTemplate: 2
        }
    }
    render() {
        console.log(this.state.currentTemplate)
        return (<>
            <div id="content-div" className="user_form">
                <div className="container">
                    <div className="uform col-sm-12">
                        <div className="uform_inner">
                            <div className="header_buttons">
                                <div className="header_title">
                                    <h2>Settings</h2>
                                </div>
                            </div>
                            <Tabs defaultActiveKey="Invoice Templates" transition={false} id="noanim-tab-example">
                                <Tab eventKey="Invoice Templates" title="Invoice Templates">
                                    <Invoice salesinvoice={this.state}
                                        setTemplate={(e) => { this.setState({ currentTemplate: e }) }}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}
export default Settings;
