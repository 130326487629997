import React, { Component } from "react";

import { Link } from "react-router-dom";
import SearchBar from "../../../Common/SearchBar";

import List from "../../../../themes/default/ViewWrappers/List";

import HeaderWrapper from "../../../../themes/default/HeaderWrapper";
import {
  Add,
  Delete
} from "../../../../themes/default/Buttons/MultiButtons";
import Table, {
  TableHead,
  TableHeading,
  TableBody,
  TableCell,
  TableRow,
  TableFooter
} from "../../../../themes/default/Table/Table.js";

import Pagination from "../../../../themes/default/Pagination/Pagination";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import GstReturnsService from "../GstReturnsService.js";
import { Store } from "../../../../GlobalProvider";
let gstReturnsService = new GstReturnsService();
class GstReturns extends Component {
  static contextType = Store;
  constructor(props) {
    super(props);

    this.state = {
      gsts: [],
      activePage: 1,
      isChecked: false,
      checkedAll: false,
      limit: sessionStorage.getItem("core-gsts-limit") || 5,
      offset: sessionStorage.getItem("core-gsts-offset") || 0,
      orderBy: "id",
      inOrder: "DESC",
      search: "",
      pageRangeDisplayed: 5,
      total_records: 0,
      message: ""
    };
  }

  selectAll = event => {
    let rows = this.state.gsts;
    rows.forEach(row => {
      row.isChecked = !this.state.checkedAll;
    });
    this.setState({ gsts: rows, checkedAll: !this.state.checkedAll });
  };

  checkedClick = event => {
    let target = event.target;
    let { gsts, checkedAll } = this.state;
    if (
      Array.isArray(this.state.gsts) &&
      target.dataset.index != null &&
      this.state.gsts[target.dataset.index] != null
    ) {
      //toggele the current checkbox
      gsts[target.dataset.index].isChecked = target.checked;
      //toggle the select all checkbox;
      checkedAll = !gsts.some(gst => !gst.isChecked);
      this.setState({ gsts: gsts, checkedAll: checkedAll });
    }
  };

  delete = event => {
    event.preventDefault();
    let selectedGsts = this.state.gsts.filter(gst => {
      return gst.isChecked ? gst.id : null;
    });
    if (selectedGsts.length === 0) {
      alert("Please select");
      return false;
    }
    let selectedIds = selectedGsts.map(selectedGst => selectedGst.id);
    let result = gstReturnsService.delete(selectedIds);
    if (result) {
      this.getAll();
    }
  };

  async getAll() {
    let { search, orderBy, inOrder, limit, offset } = this.state;
    let obj = { search, orderBy, inOrder, limit, offset };
    let result = await gstReturnsService.getAll(obj, this);
    // console.log(obj);
    this.setState({ gsts: result });
  }
  componentDidMount() {
    this.getAll();
  }
  OnChangePagination = (offset, limit) => {
    // console.log(limit);
    // console.log(offset)
    if (
      parseInt(this.state.offset) !== parseInt(offset) ||
      parseInt(this.state.limit) !== parseInt(limit)
    ) {
      this.setState(
        {
          limit: limit,
          // offset: ,
        },
        () => {
          // get List
          // this.getAll();

          // Store Limit In sessionStorage
          sessionStorage.setItem("core-gsts-offset", this.state.offset);
          sessionStorage.setItem("core-gsts-limit", this.state.limit);
        }
      );
    }
  };

  handleOrdering = event => {
    let target = event.target;
    if (this.state.inOrder === "DESC") {
      this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
        this.getAll();
      });
    } else {
      this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
        this.getAll();
      });
    }
  };

  handleSearch = event => {

    let search = event;
    this.setState({ search: search }, () => {
      this.getAll();
    });
  };

  render() {
    const gsts = this.state.gsts;
    return (
      <List>
        <HeaderWrapper title="Gst Returns">
          <SearchBar onClick={this.handleSearch} />
          <Add
            onClick={() => {
              this.props.history.push(`${this.props.match.path}/Add`);
            }}
          />
          <Delete onClick={this.delete} />
        </HeaderWrapper>

        {this.state.message}

        <Table className={["gst-table", "table", "table-striped"]}>
          <TableHead>
            <TableRow>
              <TableHeading width="30">
                <CheckBox
                  id="select_all"
                  index={999999}
                  onClick={this.selectAll}
                  checked={this.state.checkedAll}
                />
              </TableHeading>
              {/* <TableHeading id={["gst_id"]} onClick={this.handleOrdering}>
                GST ID
              </TableHeading>
              <TableHeading id={["gst_password"]} onClick={this.handleOrdering}>
                Password
              </TableHeading> */}
              <TableHeading
                width="30"
                id={["id"]}
                onClick={this.handleOrdering}
              >
                ID
              </TableHeading>
              <TableHeading
                width="30"
                id={["id"]}
                onClick={this.handleOrdering}
              >
                Attachments
              </TableHeading>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(gsts) &&
              gsts.slice(this.state.offset, this.state.limit).map((gst, index) => {
                if (typeof gst.isChecked === "undefined") gst.isChecked = false;
                // for (let i = 0; i < this.state.limit; i++) {
                //   console.log(i)
                return (

                  <TableRow key={gst.id}>
                    <TableCell>
                      <CheckBox
                        id={`cid${gst.id}`}
                        name="cid[]"
                        value={gst.id}
                        index={index}
                        onClick={this.checkedClick}
                        checked={gst.isChecked}
                      />
                    </TableCell>
                    <TableCell>
                      <Link to={`${this.props.match.path}/edit/${gst.id}`} /*gstdat={gst}*/>
                        {gst.gst_id}
                      </Link>
                    </TableCell>
                    {/* <TableCell>{gst.gst_password}</TableCell>
                      <TableCell>{gst.id}</TableCell> */}

                    <TableCell>
                      {/* attachments */}
                      {gst.medias.map(media => <img key={media.id} src={process.env.REACT_APP_SERVER_URL + "/media?location=" + media.url} width="80px" height="80px" alt="" />
                      )}
                    </TableCell>
                  </TableRow>
                );

                // }
                // console.log(this.state.total_records)
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={13}>
                <Pagination
                  // total_recordsCount={this.state.total_records}


                  onChange={(offset, limit) => { this.OnChangePagination(offset, limit) }}
                // limit={this.state.limit}
                // offset={this.state.offset}


                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </List>
    );
  }
}

export default GstReturns;
