import React from 'react';

const Label = (props) => {
    return (
        <label
            className={Array.isArray(props.className) ? props.className.join(' ') : ''}
            name={props.name} id={props.id} htmlFor={props.for}
            onClick={props.onClick}
        >
            {props.children}
        </label>
    );
}

export default Label;