import React from "react";

// const TextField = ({ props., className, ...rest }) => {
const TextField = props => {
  return (
    <input
      className={
        Array.isArray(props.className) ? props.className.join(" ") : ""
      }
      onChange={props.onChange}
      // {...rest}
      onClick={props.onClick}
      onBlur={props.onBlur}
      type={props.type || "text"}
      name={props.name}
      id={props.id}
      required={props.required}
      pattern={props.pattern}
      title={props.title}
      placeholder={props.placeholder}
      value={props.value ? props.value : props.defaultValue}
      autoComplete={props.autoComplete}
      readOnly={props.readOnly || false}
      disabled={props.disabled || false}
    />
  );
};

export default TextField;
