import React from "react";
import Table, { TableBody, TableCell, TableRow } from "../../../../src/themes/default/Table/Table.js"
function Template1(props) {
    let invoice = props.salesinvoice
    const temp1 = (
        <div className="invoice-box" style={{ color: "black" }}>
            <Table cellSpacing="0">
                <TableBody>
                    <TableRow className="top">
                        <TableCell colSpan="2">
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="title">
                                            {/* Company Name */}
                                            Company name
                                        </TableCell>
                                        <TableCell>
                                            {/* Invoice Details */}
                                            Invoice #: {invoice.id}<br />
                                            Created: {invoice.created_at}<br />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow className="information">
                        <TableCell colSpan="2">
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {/* Company Details */}
                                            Sparksuite, Inc.<br />
                                            12345 Sunny Road<br />
                                            Sunnyville, CA 12345
                                        </TableCell>
                                        <TableCell>
                                            {/* Customer details */}
                                            {invoice.customer}<br />
                                            John Doe<br />
                                            john@example.com
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow className="heading">
                        <TableCell>
                            Payment Method
                        </TableCell>

                        <TableCell>
                            {/* Payment_type */}
                            {invoice.payment_type}
                        </TableCell>
                    </TableRow>

                    <TableRow className="details">
                        <TableCell>
                            {invoice.payment_type}
                            {/* payment_type */}
                        </TableCell>

                        <TableCell>
                            {invoice.amount}
                            {/* amount */}
                        </TableCell>
                    </TableRow>

                    <TableRow className="heading">

                        <TableCell>
                            Item
                            {/* items */}
                        </TableCell>

                        <TableCell>
                            Price
                            {/* Price */}
                        </TableCell>
                    </TableRow>
                    {/* items */}


                    {Array.isArray(invoice.items) && invoice.items.map((item, index) => {

                        return (
                            <TableRow className="item" key={index}>
                                <TableCell>
                                    {item.title}
                                </TableCell>
                                <TableCell>
                                    {item.price}
                                </TableCell>
                            </TableRow>
                        )
                    })}

                    <TableRow>
                        <TableCell className="total">
                            {/* total */}
                            Total: {invoice.amount}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div >
    )
    return (<>{temp1}</>)
}

export default Template1