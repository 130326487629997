import {showMessage} from '../../../themes/default/MessageBoard';
import axios from '../../../http/Axios';

class TransactionService {

	async store(data, that) {
		try{
			let resp = null;
			resp = await axios.post('/transaction', data);
			return resp.data;
			
		}catch(err){
			if(err.hasOwnProperty('response')){
				let errRep = err.response;
				if(errRep.status === 422){
					for( let key in errRep.data.detail){
						showMessage({title:'', message:errRep.data.detail[key].join('\n')}, that.context);
					}
				}else if(errRep.status === 500){
					showMessage({title:errRep.data.title, message:errRep.data.detail}, that.context);
				}
			}
			return null;
		}
	}

	async getAll(obj = {},that) {
		console.log('GetAll', obj);
		try{
			let res = await axios.get('/transaction',{params:obj});
			return res.data;
		}
		catch(err){
			let data = err.response.data;
			showMessage({title:data.title, message:data.detail}, that.context);
		}
		return null;
	}
	async delete(id) {
		try{
			await axios.delete('/transaction/'+id);
			return true;
		}catch(err){
			console.log(err);
			return null;
		}
	}


}

export default new TransactionService;