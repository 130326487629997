import React from 'react';
import Table from 'react-bootstrap/Table';
import { TableRow, TableHeading, TableHead, TableBody, TableCell } from '../../../../themes/default/Table/Table';
function Expense(props) {
    let expenselist = props.expenselist
    return (
        <Table className={["expense-table", "table", "table-striped", "col-sm-12"]}>
            <TableHead>
                <TableRow className={"text-center"}>
                    <TableHeading colSpan={4}>EXPENSES</TableHeading>
                </TableRow>
                <TableRow>
                    <TableHeading width="30">
                        S.No.
                    </TableHeading>
                    <TableHeading>
                        Description
                    </TableHeading>
                    <TableHeading>
                        Expense
                    </TableHeading>
                    <TableHeading>
                        Date
                    </TableHeading>
                </TableRow>
            </TableHead>
            <TableBody>
                {Array.isArray(expenselist) && expenselist.map((expense, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell>
                                {expense.id}
                            </TableCell>
                            <TableCell>
                                {expense.description}
                            </TableCell>
                            <TableCell>
                                {expense.amount}
                            </TableCell>
                            <TableCell>
                                {expense.created_at}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
export default Expense