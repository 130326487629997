import * as ActionTypes from "./actions";
import merge from "lodash/merge";
const initialState = {
  _messages: []
};
const messages = (state = initialState, action) => {
  const { type, message } = action;
  if (typeof message !== "object" && type !== ActionTypes.MSB_RESET_BOARD) { console.log("\"message\" variable should be of object type, " + typeof message + "given"); return state };

  switch (type) {
    case ActionTypes.MSB_ADD_MESSAGE:
      if (Array.isArray(message)) {
        return merge({}, state, {
          _messages: [...message, ...state._messages]
        });
      } else {
        return merge({}, state, { _messages: [message, ...state._messages] });
      }

    case ActionTypes.MSB_OVERWRITE_MESSAGE:
      if (Array.isArray(message)) {
        return merge({}, { _messages: message });
      } else {
        return merge([], { _messages: [message] });
      }

    case ActionTypes.MSB_RESET_BOARD:
      return { _messages: [] };
    default:
      return state;
  }
};
export default messages;
