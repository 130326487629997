import axios from 'axios';

const apiBaseUrl= process.env.REACT_APP_SERVER_URL;
const token = getToken();


function getToken() {
    return localStorage.getItem("token")
}


const instance = axios.create({
    baseURL: apiBaseUrl
});
  
// Alter defaults after instance has been created
instance.defaults.headers.common['Authorization'] = 'Bearer '+ token;

export default instance;