import React from 'react';
import Button from '../../../../themes/default/Buttons/Buttons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
function stock(props) {
    let buttons = "";
    let _MSG = "";
    if (props.isModal) {
        buttons = (<>
            <Button
                onClick={props.submitForm}
                variant={"success"}
            >
                <FontAwesomeIcon icon={Icons.faSyncAlt} /> Update
        </Button>
        </>)
    }
    if (!props.isModal) {
        buttons = props.data.buttons;
        _MSG = props.data._MSG;
    } else {

    }
    return (
        <>
            {buttons}
            {_MSG}
            <form action="">

                <div className="form-group col-sm-6">
                    <label className="col-sm-4 col-form-label" htmlFor="Quantity required">{"Quantity required"}</label>

                    <input
                        className="form-control d-inline-block col-sm-6"
                        type="number"
                        value={props.data.quantity_required}
                        onChange={props.handleStateChange('quantity_required')}

                    />
                </div>
                <div className="form-group col-sm-6">
                    <label className="col-sm-4 col-form-label" htmlFor="Available">{"Available"}</label>

                    <input
                        className="form-control d-inline-block col-sm-7 mt-2"
                        type="number"
                        value={props.data.available}
                        disabled
                    />
                </div>
                <>
                    <div className="form-group col-sm-6">
                        <label className="col-sm-4 col-form-label" htmlFor="Stock Minimum">{"Stock Minimum *"}</label>
                        <input
                            className="form-control d-inline-block col-sm-7 mt-2"
                            type="number"
                            value={props.data.stock_minimum}
                            onChange={props.handleStateChange('stock_minimum')}
                        />
                        <label className="col-sm-2 col-form-label" htmlFor="error">{(props.data.errorStockMinimum) ? props.data.errorMsg : null}</label>
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="col-sm-4 col-form-label" htmlFor="Stock Maximum">{"Stock Maximum *"}</label>
                        <input
                            className="form-control d-inline-block col-sm-7 mt-2"
                            type="number"
                            value={props.data.stock_maximum}
                            onChange={props.handleStateChange('stock_maximum')}
                        />
                        <label className="col-sm-2 col-form-label" htmlFor="error">{(props.data.errorStockMaximum) ? props.data.errorMsg : null}</label>
                    </div>
                </>
            </form>
        </>
    );
}
export default stock;
