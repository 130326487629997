import React from 'react'
import Button from 'react-bootstrap/Button'
require('./UploadButton.css')

const UploadButton = (props) => {
  return (
    <Button
    className="upload-btn-wrapper"
    variant={props.variant}
    >
        {props.children}
        <input
        type="file"
        name="myfile"
        onChange={props.onChange}
         />
    </Button>
  );
}

export default UploadButton;
