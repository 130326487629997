import React, {Component} from 'react';

import Form from "../../../../themes/default/ViewWrappers/Form"
import { Store } from '../../../../GlobalProvider';
import * as ActionTypes from '../../../../themes/default/MessageBoard/actions';
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Submit, Exit, Update } from "../../../../themes/default/Buttons/MultiButtons"
import TextField from "../../../../themes/default/Inputs/Inputs.js";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import File from "../../../../themes/default/Inputs/File.js";
import Calendar from "../../../../themes/default/DatePicker/DatePicker.js";
import Label from "../../../../themes/default/Label/Label.js";
import Helper,{dateFormat} from "../../../Common/Helper";
import itr24Service from '../Itr24Service.js';
import FormValidation from '../../../../themes/default/assets/js/FormValidation.js';


class Edit extends Component{
    static contextType = Store;
    constructor(props){
        super(props);
        this.state = {
            files: {
                pan_card:null,
                aadhar_card:null,
                bank_detail:null,
                form_16:null,
                other_bank_statement:null
            },
            data:{
                id: parseInt(this.props.match.params.id) || 0,
                name: "",
                father_name:"",
                email: "",
                aadhar_no:"",
                mob_no: "",
                password: "",
                success: false,
                message: "",
                dob:new Date(),
                isRegistered:false,
                efilling_id:"",
                efilling_pass:"",
            }
        };
    }
    componentDidMount(){
        if(this.state.data.id !== 0){
            this.getDetails(this.state.data.id);
        }
    }
    
    async getDetails(id){
        const result = await itr24Service.get(id, this);
        if(result !== null){
            console.log(result);
            let files = JSON.parse(JSON.stringify(this.state.files));
            result.medias.forEach(media=>{
                files[media.file_type] = media.file_name;
            });
            result.dob = new Date(result.dob);
            this.setState({data:result, files:files});
        }

    }

    handleChange = event => {
        let field = event.target.id;
        let data = this.state.data;
        data[field] = event.target.value;
        this.setState({ data:data });
    };

    handleDatechange = date => {
        let data = this.state.data;
        data.dob=date;
        this.setState({data:data});

    };
    checkedClick = event=>{
        let data = this.state.data; 
        data.isRegistered = event.target.checked;
        this.setState({data:data});
    };

    handleFile = event=>{
        let id = event.target.id;
        let files = {...this.state.files};
        files[id] = event.target.files[0];
        this.setState({files:files},()=>{console.log(this.state.files);});
    };

    submitForm = async event =>{
        event.preventDefault();
        let validation = new FormValidation();
        for(let key in this.state.data){
            let result = validation.validate(key, this.state.data[key]);
            if(!result.flag){
                alert(result.message);
                return;
            }
        }
        let datas = {data:{...this.state.data},files:{...this.state.files}};
        datas.data.dob = dateFormat(datas.data.dob, 'isoDate');
        let resp = await itr24Service.store(datas, this);
        resp.dob = new Date(resp.dob);
        this.setState({data: resp});
        this.props.history.push('/itr24/edit/'+this.state.data.id);

    };


    render() { 
        console.log(this.props);
        let submitButton = null;
        if(this.state.data.id !=0){
            submitButton = <Update onClick={this.submitForm} />;
        }else{
            submitButton = <Submit onClick={this.submitForm} />;
        }
        return (
            <Form>
                <HeaderWrapper title={`ITR24 (${this.state.id ? "Edit" : "New"})`}>
                    {submitButton}
                    <Exit onClick={() => { this.props.history.push("/itr24"); }} />
                </HeaderWrapper>

                {/* Left side div  */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]} id="name">
                            {"Name *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            name="name"
                            id="name"
                            placeholder="Enter Name"
                            onChange={this.handleChange}
                            value={`${this.state.data.name}`}
                        />
                    </div>

                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>{"Father Name *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="father_name"
                            placeholder="Enter Father Name"
                            value={this.state.data.father_name}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>{"Email *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="email"
                            placeholder="Enter Email"
                            value={this.state.data.email}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                {/* Right side div */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]} id="dob"> {"DOB as per Pan *"} </Label>
                        <Calendar 
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="date"
                            name="calender"
                            onChange={this.handleDatechange} 
                            selected={this.state.data.dob}

                        />
                    </div>

                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>
                            {"Adhar Number *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            name="aadhar_no"
                            id="aadhar_no"
                            placeholder="Adhar Number"
                            onChange={this.handleChange}
                            value={`${this.state.data.aadhar_no}`}
                        />
                    </div>

                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>
                            {"Mobile No. *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="mob_no"
                            placeholder="Enter mobile no."
                            onChange={this.handleChange}
                            value={this.state.data.mob_no}
                        />
                    </div>

                </div>

                <div className="col-sm-12 mb-3 bg-light p-2 mt-3">
                    <div className="form-group col-sm-12 text-left mb-0">
                        <Label className={["px-0", "col-sm-3"]}>
                            {"Already Registered on E- filling"}
                        </Label>
                        <CheckBox
                            className={["align-middle"]}
                            id={`isRegistered`}
                            name="cid[]"
                            value={this.state.data.isRegistered}
                            index={1}
                            onClick={this.checkedClick}
                            checked={this.state.data.isRegistered}
                        />
                    </div>
                    <div className="col-sm-6 mt-3" hidden ={!this.state.data.isRegistered}>

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Log in-ID"}
                            </Label>
                            <TextField
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="efilling_id"
                                placeholder="Enter login id"
                                onChange={this.handleChange}
                                value={this.state.data.efilling_id}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 mt-3" hidden ={!this.state.data.isRegistered}>

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Password"}
                            </Label>
                            <TextField
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="efilling_pass"
                                placeholder="Enter password"
                                type="password"
                                onChange={this.handleChange}
                                value={this.state.data.efilling_pass}
                            />
                        </div>
                    </div>
                </div>
                <div className="border border-light float-left w-100 pt-3 pb-3">
                    <div className="col-sm-6">

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Pan Card"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="pan_card"
                                onChange={this.handleFile}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Bank Detail"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="bank_detail"
                                onChange={this.handleFile}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Form 16 (For Employee only)"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="form_16"
                                onChange={this.handleFile}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Adhar Card"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="aadhar_card"
                                onChange={this.handleFile}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Other Bank satement(01/04/2019 to 31/03/2020)"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="other_bank_statement"
                                onChange={this.handleFile}
                            />
                        </div>
                    </div>
                </div>

            </Form>
        );
    }
}

export default Edit;