import React, { Component } from "react";
import Form from "../../../../themes/default/ViewWrappers/Form";
import { Store } from "../../../../GlobalProvider";
import HeaderWrapper from "../../../../themes/default/HeaderWrapper";
import { Submit, Exit, Update } from "../../../../themes/default/Buttons/MultiButtons";
import TextField from "../../../../themes/default/Inputs/Inputs.js";
import File from "../../../../themes/default/Inputs/File.js";
import Calendar from "../../../../themes/default/DatePicker/DatePicker.js";
import Label from "../../../../themes/default/Label/Label.js";
import { dateFormat } from "../../../Common/Helper";
import TanNumberRegistrationService from "../TanNumberRegistrationService.js";
import FormValidation from "../../../../themes/default/assets/js/FormValidation.js";
let tanNumberRegistrationService = new TanNumberRegistrationService();

class Edit extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);

        this.state = {
            files: {
                pan_card: null,
                aadhar_card: null,
                voter_card: null,
                photo: null,
            },
            data: {
                id: parseInt(this.props.match.params.id) || 0, //reduntant
                name: "",
                father_name: "",
                email: "",
                mob_no: "",
                success: false,
                message: "",
                dob: new Date(),

            }
        };
    }
    componentDidMount() {
        if (this.state.data.id !== 0) {
            this.getDetails(this.state.data.id);
        }
    }

    async getDetails(id) {
        const result = await tanNumberRegistrationService.get(id, this);
        if (result !== null) {
            let files = JSON.parse(JSON.stringify(this.state.files));
            result.medias.forEach(media => {
                files[media.file_type] = media.file_name;
            });
            result.dob = new Date(result.dob);
            this.setState({ data: result, files: files });

        }
    }

    handleChange = event => {
        let field = event.target.id;
        let data = { ...this.state.data };
        data[field] = event.target.value;
        this.setState({ data: data }, () => {
        });
    };

    checkedClick = event => {
        let data = this.state.data;
        data.isRegistered = event.target.checked;
        this.setState({ data: data });
    };

    handleFile = event => {
        let id = event.target.id;
        let files = { ...this.state.files };
        files[id] = event.target.files[0];
        this.setState({ files: files }, () => {
        });
    };
    submitForm = async event => {
        event.preventDefault();
        let validation = new FormValidation();
        for (let key in this.state.data) {
            let result = validation.validate(key, this.state.data[key]);
            if (!result.flag) {
                alert(result.message);
                return;
            }
        }
        let datas = { data: { ...this.state.data }, files: { ...this.state.files } };
        datas.data.dob = dateFormat(datas.data.dob, 'isoDate');
        let resp = await tanNumberRegistrationService.store(datas, this)
        resp.dob = new Date(resp.dob);
        this.setState({ data: resp });
        this.props.history.push('/tannoregistration/edit/' + this.state.data.id);


    };

    render() {

        let submitButton = null;
        if (this.state.data.id !== 0) {
            submitButton = <Update onClick={this.submitForm} />;
        } else {
            submitButton = <Submit onClick={this.submitForm} />;
        }
        return (
            <Form>
                <HeaderWrapper
                    title={`TAN Number Registration (${this.state.data.id ? "Edit" : "New"})`}
                >
                    {submitButton}
                    <Exit
                        onClick={() => {
                            this.props.history.push("/tannoregistration");
                        }}
                    />
                </HeaderWrapper>

                {/* Left side div  */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]} id="name">
                            {"Name *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            name="name"
                            id="name"
                            placeholder="Enter Name"
                            onChange={this.handleChange}
                            value={`${this.state.data.name}`}
                        />
                    </div>

                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>{"Father Name *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="father_name"
                            placeholder="Enter Father Name"
                            value={this.state.data.father_name}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>{"Email *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="email"
                            placeholder="Enter Email"
                            value={this.state.data.email}
                            onChange={this.handleChange}
                        />
                    </div>

                </div>

                {/* Right side div */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]}>
                            {"Mobile No. *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="mob_no"
                            placeholder="Enter mobile no."
                            onChange={this.handleChange}
                            value={this.state.data.mob_no}
                        />
                    </div>

                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-3"]} id="dob"> {"DOB as per PAN Card*"} </Label>
                        <Calendar
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="date"
                            name="calender"
                            onChange={this.handleDatechange}
                            selected={this.state.data.dob}
                        />
                    </div>
                </div>

                <div className="col-sm-12 mb-3 bg-light p-2 mt-3">
                    <div className="col-sm-6 mt-3" hidden={!this.state.data.isRegistered}>
                    </div>
                </div>
                <div className="border border-light float-left w-100 pt-3 pb-3">
                    <div className="col-sm-6">

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Pan Card"}
                            </Label>
                            <img src={this.state.data.id ? (process.env.REACT_APP_SERVER_URL + "/media?location=tan/" + this.state.files.pan_card) : (this.state.files.pan_card ? (process.env.REACT_APP_SERVER_URL + "/media?location=tan/" + this.state.files.pan_card.name) : "")} width="80px" height="80px" alt="" />

                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="pan_card"
                                onChange={this.handleFile}
                            />
                        </div>

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Photo"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="photo"
                                onChange={this.handleFile}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">

                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Adhar Card"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="aadhar_card"
                                onChange={this.handleFile}
                            />
                        </div>
                        <div className="form-group col-sm-12 text-left" >
                            <Label className={["d-inline-block", "col-sm-3"]}>
                                {"Voter ID"}
                            </Label>
                            <File
                                className={["form-control", "col-sm-9", "d-inline-block"]}
                                id="voter_card"
                                onChange={this.handleFile}
                            />
                        </div>

                    </div>
                </div>
            </Form>
        );
    }
}

export default Edit;
