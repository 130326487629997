import HttpClient from "../../../http/HttpClient";
import {showMessage} from "../../../themes/default/MessageBoard";
import axios from "../../../http/Axios";

class ApiService {

	//Create new registrations
	async post({data,files}) {
		//append data fields
		let postBody = new FormData();
		for (let key in data) {
			postBody.append(key, data[key]);
		}
		for (let key in files) {
			if (files[key] !== null) {
				postBody.append(key, files[key]);
			}
		}
		
		const response = axios.post('/gst_registration', postBody);
		const json = await response;
		console.log(json);
		// let obj = await HttpClient.send(options, '/itr24');
		console.log(response);
	}

	

	//Get all registrations
	async getAll(obj = {},that) {
		
 		let endpoint = '';
		endpoint += obj.limit ? "offset=" + obj.offset + "&limit=" + obj.limit : ''
		endpoint += obj.search ? '&search=' + obj.search : '';
		endpoint += obj.orderBy ? '&orderBy=' + obj.orderBy + '&dir=' + obj.inOrder : ''

		// const response = axios.get(`/gst_registration`, { params : ''		})
		// const json = await response;
		// // let result = await HttpClient.send(options, endpoint,true);
		// console.log('result: ',json);
		// if (result instanceof Response) {
		// 	if (result.ok) {
		// 		return result.json();
		// 	} else if(result.status>299 && result.status<499){
		// 		result = await result.json();
		// 		console.log(result)
		// 		showMessage({ title: result.title, message: result.detail },that.context);
		// 	}
		// }
		// return null;


	}
	async getGstRegistration(id) {
		axios.get()
		// let response = axios.get('');
		// return response;
		// return HttpClient.send(options, "/itr24/" + id);
		return {};
	}

	async delete(ids) {
		let options = { method: "delete" };
		options.body = JSON.stringify(ids);
		return HttpClient.send(options, "/core/users");
	}
}

export default ApiService;