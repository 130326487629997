import React from "react";
import { Store } from "../GlobalProvider";
const connect = (
  mapStateToProps = () => {},
  mapDispatchToProps = () => {}
) => WrappedComponent => {
  return props => {
    const { dispatch, state } = React.useContext(Store);
    return (
      <WrappedComponent
        dispatch={dispatch}
        {...mapStateToProps(state, props)}
      />
    );
  };
};

export default connect;
