import React from "react";

const CheckBox = props => {
  return (
    <input
      className={
        Array.isArray(props.className) ? props.className.join(" ") : ""
      }
      onChange={props.onClick}
      type="checkbox"
      name={props.name}
      id={props.id}
      data-index={props.index || 0}
      value={props.value}
      checked={props.checked}
      style={props.style}
    />
  );
};
// React.forwardRef((props, ref) => {
//   // const [isChecked, setIsChecked] = useState(true);
//   return (
//     <input
//       className={
//         Array.isArray(props.className) ? props.className.join(" ") : ""
//       }
//       ref={ref}
//       onChange={props.onClick}
//       type="checkbox"
//       name={props.name}
//       id={props.id}
//       value={props.value}
//       defaultChecked={false}
//       style={props.style}
//       checked={props.checked}
//     />
//   );
// });

export default CheckBox;
