import React from "react";

import { Redirect, withRouter } from "react-router-dom";
import Button from "./themes/default/Buttons/Buttons.js";
import Profile from "./themes/default/Profile/ProfileDD.js";
import axios from "./http/Axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faSignOutAlt,
  faBars
} from "@fortawesome/free-solid-svg-icons";

const menus = require("./Sidemenu.json");

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: ""
    };
  }

  logout = event => {
    if (event === "/Login") {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      sessionStorage.clear();
    }
  };

  openHome = () => {
    this.props.history.push("/");
  };

  componentDidMount(){

    axios.get('/me')
    .then(res=>{
      if(res.data.id){
        this.setState({user:res.data})
      }
    })
    .catch(error=>{
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      sessionStorage.clear();
    });
  }
  render() {
    let isLoggedIn = this.props.isLoggedIn;
    let hitmodule = false;
  
    // if (localStorage.getItem("token")) isLoggedIn = true;
    let loginButton = "";
    let logOutButton = "";
    for (var i of menus.menus) {
      if (this.props.location.pathname.match(i.default)) {
        hitmodule = true;
        break;
      }
    }
  
    if (!isLoggedIn) {
      let prevurl = this.props.location.pathname;

      if (prevurl !== "/Login") {
        localStorage.setItem("prevurl", prevurl);
      }
      loginButton = (
        <>
          <Button
            className={["btn", "btn-primary", "login"]}
            onClick={() => {
              this.props.history.push("/Login");
            }}
          >
            Login
          </Button>
          <Redirect from={this.props.location.pathname} to="/Login" />
        </>
      );
    } else {
      let user_id = parseInt(localStorage.getItem("user_id"));
      let items = [
        { link: "/core/User/Edit/" + user_id, title: "Profile" , icon:faUser },
        { link: "/Login", title: "LogOut", icon:faSignOutAlt }
      ];
      let img = (
        <img src={require("./media/man-user.png")} width="40" alt=""/>
      );
      logOutButton = (
        <>
          <Profile
            title={this.state.user.name}
            icon={img}
            items={items}
            onSelect={this.logout}
            className={["profile_option"]}
          />
        </>
      );
    }
    return (
      <>
 <div className={`header header-sidebar`}>
		<div className="container">
		<div className="logind col-sm-12">
          {isLoggedIn && hitmodule ? (
            <>
              <span className="menu-switch" onClick={this.props.toggleClass}>
                <FontAwesomeIcon
                  icon={faBars}
                />
              </span>
              <span className="home-switch" onClick={this.openHome}>
                <FontAwesomeIcon icon={faHome} />
              </span>
            </>
          ) : ""}
          {!isLoggedIn ? loginButton : logOutButton}
        </div>
		</div>
		</div>
        
      </>
    );
  }
}

export default withRouter(Header);
