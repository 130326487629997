import { showMessage } from '../../../themes/default/MessageBoard';
import axios from '../../../http/Axios';
class UserService {
	async store(data, that) {

		try {
			let resp = null;
			if (data.id == 0) {
				resp = await axios.post('/user', data);
			} else {
				resp = await axios.post('/user/' + data.id, data);
			}
			return resp.data;

		} catch (err) {
			if (err.hasOwnProperty('response')) {
				let errRep = err.response;
				if (errRep.status === 422) {
					for (let key in errRep.data.detail) {
						showMessage({ title: '', message: errRep.data.detail[key].join('\n') }, that.context);
					}
				} else if (errRep.status === 500) {
					showMessage({ title: errRep.data.title, message: errRep.data.detail }, that.context);
				}
			}
			return null;
		}
	}

	async getAll(obj = {}, that) {
		try {
			let res = await axios.get('/user', { params: obj });
			return res.data;
		}
		catch (err) {
			let data = err.response.data;
			showMessage({ title: data.title, message: data.detail }, that.context);
		}
		return null;
	}
	async get(id, that) {
		try {
			let res = await axios.get('/user/' + id);
			return res.data;
		} catch (err) {
			let data = err.response.data;
			showMessage({ title: '', message: 'Somthing went wrong' }, that.context);
			return null;
		}
	}

	async delete(id) {
		try {
			await axios.delete('/user', { data: {id} });
			return true;
		} catch (err) {
			console.log(err);
			return null;
		}
	}

}

export default new UserService();