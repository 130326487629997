import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const calendar = ({ id, selected, onChange, className, ...others }) => {
  return (
    <DatePicker
      id={id}
      className={Array.isArray(className) ? className.join(' ') : ''}
      selected={selected}
      onChange={onChange}
      showYearDropdown
      dateFormatCalendar="MMMM"
      yearDropdownItemNumber={15}
      scrollableYearDropdown
      showMonthDropdown
      useShortMonthInDropdown
      {...others}
    />
  )
}

export default calendar;
