import React from 'react';
import Table from 'react-bootstrap/Table';
import { TableRow, TableHeading, TableHead, TableBody, TableCell } from '../../../../themes/default/Table/Table';
function Income(props) {
    let incomelist = props.incomelist
    return (
        <Table className={["income-table", "table", "table-striped", "col-sm-12"]}>
            <TableHead>
                <TableRow className="text-center">
                    <TableHeading colSpan={4}>INCOME</TableHeading>
                </TableRow>
                <TableRow>
                    <TableHeading width="30">
                        S.No.
                    </TableHeading>
                    <TableHeading>
                        Description
                    </TableHeading>
                    <TableHeading>
                        Income
                    </TableHeading>
                    <TableHeading>
                        Date
                    </TableHeading>
                </TableRow>
            </TableHead>
            <TableBody>
                {Array.isArray(incomelist) && incomelist.map((income, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell>
                                {income.id}
                            </TableCell>
                            <TableCell>
                                {income.description}
                            </TableCell>
                            <TableCell>
                                {income.amount}
                            </TableCell>
                            <TableCell>
                                {income.created_at}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
export default Income;