import React, { Component } from 'react';

import Pagination from 'react-bootstrap/Pagination';

const limit = [
    { id: 5, title: 5 },
    { id: 10, title: 10 },
    { id: 20, title: 20 },
    { id: 50, title: 50 },
    { id: 100, title: 100 },
];

class pagination extends Component {

    state = {
        totalItemsCount: this.props.totalItemsCount || 0,
        activePage: this.props.activePage || 1,
        pageRangeDisplayed: this.props.pageRangeDispley || 5,

        /** Total Pages */
        pages: 0,
        rowArray: [],

        /** Total Pages */
        limit: this.props.limit || 5,
        offset: this.props.offset || 0,
    }

    async handleActivePage(activePage = 1) {
        await this.setState({ activePage: activePage });
        this.props.onChange(this.calculatePageToOffset(), this.state.limit);
        this.rowArraySet();
    }

    rowArraySet = () => {
        let arr = [];
        let leftLooping = parseInt(this.state.pageRangeDisplayed / 2);
        let rightLooping = parseInt(this.state.pageRangeDisplayed / 2);
        arr.push(this.state.activePage);
        if (this.state.pageRangeDisplayed % 2 === 0) { leftLooping--; }
        let i = 1;
        for (i; i <= leftLooping; i++) {
            if (this.state.pages > i) {
                if (arr[0] === 1) { this.rightPush(arr); }
                else { this.leftPush(arr); }
            }
        }
        for (let j = 1; j <= rightLooping; j++ , i++) {
            if (this.state.pages > i) {
                if (arr[arr.length - 1] < this.state.pages) { this.rightPush(arr); }
                else { this.leftPush(arr); }
            }
        }
        this.setState({ rowArray: arr });
    }

    leftPush = (arr) => arr.unshift(arr[0] - 1)

    rightPush = (arr) => arr.push(arr[arr.length - 1] + 1)

    setPages = () => {
        const floatValue = this.state.totalItemsCount / this.state.limit;
        const intValue = parseInt(floatValue);
        this.setState({ pages: (floatValue > intValue) ? intValue + 1 : intValue });
    }

    calculatePageToOffset = () => ((this.state.activePage - 1) * this.state.limit)

    calculateOffsetToPage = () => ((parseInt(parseInt(this.state.offset) / parseInt(this.state.limit))) + 1)

    setLimit = (event) => {
        this.setState({ limit: event.target.value },
            () => {
                this.setPages();
                this.handleActivePage();
            });
    }

    componentWillReceiveProps = (prevprops) => {
        if (this.state.totalItemsCount !== prevprops.totalItemsCount) {
            this.setState({ totalItemsCount: prevprops.totalItemsCount }, () => { this.setPages(); });
            this.handleActivePage(this.calculateOffsetToPage());
        }
    }

    componentDidMount(){
      this.setState({ totalItemsCount: this.props.totalItemsCount }, () => { this.setPages(); });
      this.handleActivePage(this.calculateOffsetToPage());
    }

    render() {
        let showData = null;
        if (this.state.activePage <= this.state.pages && this.state.activePage > 0 && this.state.totalItemsCount / this.state.limit > 1) {
            showData = (
                <Pagination>
                    <Pagination.First
                        disabled={parseInt(this.state.activePage) === 1 ? "disabled" : ""}
                        onClick={() => this.handleActivePage(1)}
                    />
                    <Pagination.Prev
                        disabled={parseInt(this.state.activePage) === 1 ? "disabled" : ""}
                        onClick={() => this.handleActivePage(this.state.activePage - 1)}
                    />

                    {this.state.rowArray.map((i) => {
                        return <Pagination.Item
                            key={i}
                            active={(parseInt(this.state.activePage) === i) ? "active" : ""}
                            onClick={() => this.handleActivePage(i)}
                        >{i}</Pagination.Item>
                    })}

                    <Pagination.Next
                        disabled={parseInt(this.state.activePage) === parseInt(this.state.pages) ? "disabled" : ""}
                        onClick={() => this.handleActivePage(this.state.activePage + 1)}
                    />
                    <Pagination.Last
                        disabled={parseInt(this.state.activePage) === parseInt(this.state.pages) ? "disabled" : ""}
                        onClick={() => this.handleActivePage(this.state.pages)}
                    />
                </Pagination>
            );
        }

        return (
            <>
                <div className="float-left">
                    {showData}

                </div>

                <div className="float-right">
                    <select
                        className={"form-control d-inline-block"}
                        value={this.state.limit}
                        onChange={this.setLimit}
                    >
                        {limit.map(data => (<option value={data.id} key={data.id}>{data.title}</option>))}
                    </select>
                </div>
            </>
        );
    }
}
export default pagination;
