import React from "react"
import { withRouter } from "react-router-dom";

class Footer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      width:''
    };
  }


  render() {
	  let isHome = false;
	  if(this.props.location.pathname.match("/home")){
		isHome = true;
	}
    return (
        <div className={!isHome?"footer footer-sidebar":"footer footer-home"} id="footer">Copyright © YManage360.com 2019 Company | All Rights Reserved</div>
    );
  }
}

export default withRouter(Footer);
