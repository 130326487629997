import React from 'react';

const selectList = (props) => {

  let option = <option value="" >No data found</option>;
  if (Array.isArray(props.list) && props.list.length !== 0) {
    option = props.list.map((key) => {
      return <option value={key.id} key={key.id}>{key.name ? key.name : key.title}</option>
    });
  }

  let optionSelectOne = null;
  if (!props.multiple)
    optionSelectOne = <option disabled={(props.disableOption) ? "disabled" : false} value={props.default || ""} >{"Select one..."}</option>;

  const demo = () => { }

  return (
    <select
      // disabled={(props.disabled) ? "disabled" : false}
      className={Array.isArray(props.className) ? props.className.join(' ') : ''}
      id={props.id}
      value={(props.value === null) ? "" : props.value}
      onChange={props.onChange || demo}
      onClick={props.onClick || demo}
      multiple={props.multiple ? "multiple" : null}
    >
      {optionSelectOne}
      {option}
    </select>
  );
}

export default selectList;
