import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

const Icon = props => {
  return !props.type || props.type === "FA" ? (
    <FontAwesomeIcon
      id={props.id}
      className={
        Array.isArray(props.className) ? props.className.join(" ") : ""
      }
      onClick={props.onClick}
      icon={Icons[props.icon]}
    >
      {props.children}
    </FontAwesomeIcon>
  ) : (
    <i
      id={props.id}
      className={
        Array.isArray(props.className) ? props.className.join(" ") : ""
      }
      onClick={props.onClick}
      icon={Icons[props.icon]}
    ></i>
  );
};

export default Icon;
