import React from 'react';

import Button from 'react-bootstrap/Button'
import AjaxButtonLoading from "../Loadings/AjaxButtonLoading"
const button = (props) => {
    return (
        <Button
            id={props.id}
            className={Array.isArray(props.className) ? props.className.join(' ') : ''}
            onClick={props.onClick}
            type={props.type || ''}
            variant={props.variant || ""}
            disabled={props.disabled || null}
        >
            {
                props.loading === true ? 
                <AjaxButtonLoading show={props.loading} /> :
                    <>{props.children}</>
            }
        </Button>
    );
}

export default button;
