import React, { Component } from 'react'
import { Update, Submit, Exit } from '../../../../themes/default/Buttons/MultiButtons';
import HeaderWrapper from '../../../../themes/default/HeaderWrapper';
import List from '../../../../themes/default/ViewWrappers/List';
import Label from '../../../../themes/default/Label/Label';
import TextField from '../../../../themes/default/Inputs/Inputs';
import File from '../../../../themes/default/Inputs/File';
import FormValidation from '../../../../themes/default/assets/js/FormValidation';
import userService from '../UserService';
import TextArea from '../../../../themes/default/Inputs/Textarea';
class VendorEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: parseInt(this.props.match.params.id) || 0,
            name: '',
            last_name: '',
            email: '',
            contact_no: '',
            type: this.props.type,
            address: ''
        }
    }
    submitForm = async event => {
        event.preventDefault();
        let validation = new FormValidation();
        for (let key in this.state) {
            let result = validation.validate(key, this.state[key]);
            if (!result.flag) {
                alert(result.message);
                return;
            }
        }
        let datas = this.state;
        let result = await userService.store(datas, this)
        if (result) {
            this.setState({ ...result }, () => {
                this.props.history.push(`/manage/${this.state.type}/edit/${this.state.id}`)
            })
        }
    }
    componentDidMount() {
        if (this.state.id !== 0) {
            this.getDetails(this.state.id);
        }
    }
    async getDetails(id) {
        const result = await userService.get(id, this)
        if (result !== null) {
            this.setState({...result});
        }
    }

    handleChange = event => {
        let field = event.target.id;
        let value = event.target.value;
        this.setState({ [field]:value });
    };

    handleFile = event => {
        let id = event.target.id;
        let files = { ...this.state.files };
        files[id] = event.target.files[0];
        this.setState({ files: files });
    };

    render() {
        let submitButton = null;
        if (this.state.id !== 0) {
            submitButton = <Update onClick={this.submitForm} />
        }
        else {
            submitButton = <Submit onClick={this.submitForm} />
        }
        return (
            <List>
                <HeaderWrapper title={`${this.state.type.charAt(0).toUpperCase()}${this.state.type.slice(1)} (${this.state.id === 0 ? "New" : "Edit"})`}>
                    {submitButton}
                    <Exit onClick={() => { this.props.history.push(`/manage/${this.state.type}`) }} />
                </HeaderWrapper>

                {/* Left side div  */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-4"]} id="name">
                            {"First Name *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-6", "d-inline-block"]}
                            name="name"
                            id="name"
                            placeholder="Enter First Name"
                            onChange={this.handleChange}
                            defaultValue={`${this.state.name}`}
                        />
                    </div>
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-4"]} id="name">
                            {"Last Name *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-6", "d-inline-block"]}
                            name="last_name"
                            id="last_name"
                            placeholder="Enter Last Name"
                            onChange={this.handleChange}
                            defaultValue={`${this.state.last_name}`}
                        />
                    </div>
                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-4"]}>{"Email *"} </Label>
                        <TextField
                            className={["form-control", "col-sm-6", "d-inline-block"]}
                            id="email"
                            placeholder="Enter Email"
                            defaultValue={this.state.email}
                            onChange={this.handleChange}
                        />
                    </div>

                </div>
                {/* Right side div */}
                <div className="col-sm-6">
                    <div className="form-group col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-4"]}>
                            {"Contact No. *"}
                        </Label>
                        <TextField
                            className={["form-control", "col-sm-6", "d-inline-block"]}
                            id="contact_no"
                            type="tel"
                            placeholder="Enter Contact no."
                            onChange={this.handleChange}
                            defaultValue={this.state.contact_no}
                        />
                    </div>
                    <div className="form-group  col-sm-12 text-left">
                        <Label className={["d-inline-block", "col-sm-4"]}>{"Address *"} </Label>
                        <TextArea
                            className={["form-control", "col-sm-6", "d-inline-block"]}
                            id="address"
                            placeholder="Enter Address"
                            defaultValue={this.state.email}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
            </List>
        );
    }
}

export default VendorEdit;