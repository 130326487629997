import React from 'react';
import { Switch } from 'react-router-dom';
// import List from './views/List';
const Capital = (props) => {
    console.log("incomeex")
    return (
        <Switch>
            {/* <Route exact path={props.match.path} component={List} /> */}
        </Switch>
    )
}
export default Capital;