import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SearchBar from "../../../Common/SearchBar";
import FormValidation from '../../../../themes/default/assets/js/FormValidation.js';
import itemService from '../ItemService.js';
import List from "../../../../themes/default/ViewWrappers/List"
import HeaderWrapper from "../../../../themes/default/HeaderWrapper"
import { Add, Delete, Adjustment } from "../../../../themes/default/Buttons/MultiButtons"
import Table, { TableHead, TableHeading, TableBody, TableCell, TableRow, TableFooter } from "../../../../themes/default/Table/Table.js";
import AdjustmentModel from '../../../../modals/AdjustmentModal'
import Pagination from "../../../../themes/default/Pagination/Pagination";
import CheckBox from "../../../../themes/default/Inputs/Checkbox.js";
import ItemService from '../ItemService.js';
import { Store } from "../../../../GlobalProvider";
class ItemList extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            activePage: 1,
            isChecked: false,
            checkedAll: false,
            limit: sessionStorage.getItem("core-items-limit") || 30,
            offset: sessionStorage.getItem("core-items-offset") || 0,
            orderBy: "id",
            inOrder: "DESC",
            search: "",
            pageRangeDisplayed: 5,
            total_records: 0,
            message: "",
            isModal: props.isModal || false,
            modalData: []
        };
    }

    submitForm = async event => {
        console.log(this.state.modalData)
        event.preventDefault();
        let validation = new FormValidation();
        for (let key in this.state.modalData) {
            let result = validation.validate(key, this.state.modalData[key]);
            if (!result.flag) {
                alert(result.message);
                return;
            }
        }
        let datas = this.state.modalData;
        let result = await itemService.store(datas, this);
        console.log(result)
        if (result) {
            this.setState({ data: result }, () =>
                this.props.history.push('/item')
            );
        }
    }

    selectAll = event => {
        let rows = this.state.items;
        rows.forEach(row => {
            row.isChecked = !this.state.checkedAll;
        });
        this.setState({ items: rows, checkedAll: !this.state.checkedAll });
    };

    checkedClick = event => {
        let target = event.target;
        let { items, checkedAll } = this.state;
        if (Array.isArray(this.state.items) && target.dataset.index != null && this.state.items[target.dataset.index] != null) {
            //toggele the current checkbox
            items[target.dataset.index].isChecked = target.checked;
            //toggle the select all checkbox; 
            checkedAll = !items.some(item => !item.isChecked);
            this.setState({ items: items, checkedAll: checkedAll });
        }
    };
    handleStateChange = (type) => (event) => {
        let value = event.target.value
        // Non Negative Number Restrictions
        if (type === "quantity_required" || type === "stock_minimum" || type === "stock_maximum") {
            if (Math.sign(value) === -1) {
                alert("Invalid Number");
                return;
            }
        }
        // Error Handle 
        if (type === "stock_minimum" && this.state.errorStockMinimum) {
            this.setState({
                errorStockMinimum: false,
                errorMsg: null
            });
        }
        if (type === "stock_maximum" && this.state.errorStockMaximum) {
            this.setState({
                errorStockMaximum: false,
                errorMsg: null
            });
        }
        this.setState({
            modalData: { ...this.state.modalData, [type]: value }
        });
        console.log(this.state.modalData)
    }
    delete = async event => {
        event.preventDefault();
        let selectedItems = this.state.items.filter(item => {
            return item.isChecked ? item.id : null;
        });
        if (selectedItems.length === 0) {
            alert('Please select');
            return false;
        }
        let selectedIds = selectedItems.map(selectedItem => selectedItem.id);
        let result = await ItemService.delete(selectedIds);
        if (result) {
            this.getAll();
        }
    };

    async getAll() {
        let { orderBy, inOrder, limit, offset } = this.state;
        let search = this.props.search ? this.props.search : this.state.search
        let obj = { search, orderBy, inOrder, limit, offset };
        let result = await ItemService.getAll(obj, this);
        if (result) {
            this.setState({ items: result });
        }

    }
    componentDidMount() {
        console.log('Called componentDidMount');
        this.getAll();

    }
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.getAll()
        }
    }
    OnChangePagination = (offset, limit) => {
        if (parseInt(this.state.offset) !== parseInt(offset) || parseInt(this.state.limit) !== parseInt(limit)) {
            this.setState({
                limit,
                offset,
            }, () => {
                // get List
                this.getAll();

                // Store Limit In sessionStorage
                sessionStorage.setItem("core-itr24-offset", this.state.offset);
                sessionStorage.setItem("core-itr24-limit", this.state.limit);
            });
        }
    };

    handleOrdering = event => {
        let target = event.target;
        if (this.state.inOrder === "DESC") {
            this.setState({ orderBy: target.id, inOrder: "ASC" }, () => {
                this.getAll();
            });
        } else {
            this.setState({ orderBy: target.id, inOrder: "DESC" }, () => {
                this.getAll();
            });
        }
    };

    handleSearch = value => {
        let search = value;
        this.setState({ search: search }, () => {
            this.getAll();
        });
    };
    handleModalHide = () => {
        this.setState({ showModal: false });
    }
    handleModalShow = async () => {
        let selectedItems = this.state.items.filter(item => {
            return item.isChecked ? item.id : null;
        });
        if (selectedItems.length === 0) {
            alert('Please select');
            return false;
        }
        else if (selectedItems.length > 1) {
            alert('Please select only one');
            return false;
        }
        let selectedIds = selectedItems.map(selectedItem => selectedItem.id);
        this.setState({ modalData: await ItemService.get(selectedIds) });
        this.setState({ showModal: true });

    }
    handleSelectItem = id => {
        alert(id);
    };
    titleClicked = item => {
        let selectedItem = { id: item.id, title: item.title, quantity: 1, price: item.price }
        this.props.setItems(selectedItem);
        this.props.setShowComponent(false);
    }
    render() {
        const items = this.state.items;
        let buttons = null;
        if (this.props.isModal) {
            return (<>
                <Table className={["itr-table", "table", "table-striped"]}>
                    <TableHead>
                        <TableRow>
                            <TableHeading>
                                Item
                                </TableHeading>
                            <TableHeading>
                                Qty
                            </TableHeading>
                            <TableHeading>
                                Price
                           </TableHeading>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className='col-sm-6' onClick={() => this.titleClicked({id:0, title: 'New Item', quantity:1, price:0})} key={'sdfsd3eq'}>
                            <TableCell >{'New Item'}</TableCell>
                            <TableCell >{'1'}</TableCell>
                            <TableCell >{'0'}</TableCell>
                        </TableRow>
                        {Array.isArray(items) && items.map((item, index) => {
                            return (
                                <TableRow className='col-sm-6' onClick={() => this.titleClicked(item)} key={item.id}>
                                    <TableCell >{item.title}</TableCell>
                                    <TableCell >{item.quantity}</TableCell>
                                    <TableCell >{item.price}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </>
            )
        } else {
            buttons = (<>
                <Add onClick={() => { this.props.history.push(`${this.props.match.path}/add`); }} />
                <Delete onClick={this.delete} />
                <Adjustment onClick={this.handleModalShow} />
                <div>
                    {this.state.showModal ? <AdjustmentModel onHide={this.handleModalHide} show={this.state.showModal} modalData={this.state.modalData} handleStateChange={this.handleStateChange} submitForm={this.submitForm} /> : ''}
                </div>
            </>)
            return (
                <List>
                    <HeaderWrapper title="Items">
                        <SearchBar onClick={this.handleSearch} />
                        {buttons}
                    </HeaderWrapper>

                    {this.state.message}

                    <Table className={["itr-table", "table", "table-striped"]}>
                        <TableHead>
                            <TableRow>
                                <TableHeading width="30">
                                    <CheckBox id="select_all" index={999999} onClick={this.selectAll} checked={this.state.checkedAll} />
                                </TableHeading>
                                <TableHeading id={["title"]} onClick={this.handleOrdering}>
                                    Title
                            </TableHeading>
                                <TableHeading id={["category"]} onClick={this.handleOrdering}>
                                    Category
                            </TableHeading>
                                <TableHeading id={["quantity"]} onClick={this.handleOrdering}>
                                    Quantity
                            </TableHeading>
                                <TableHeading id={["price"]} onClick={this.handleOrdering}>
                                    Price
                            </TableHeading>
                                <TableHeading width="30" id={["id"]} onClick={this.handleOrdering} >
                                    Id
                            </TableHeading>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Array.isArray(items) && items.map((item, index) => {
                                if (typeof item.isChecked === "undefined")
                                    item.isChecked = false;
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <CheckBox
                                                id={`cid${item.id}`}
                                                name="cid[]"
                                                value={item.id}
                                                index={index}
                                                onClick={this.checkedClick}
                                                checked={item.isChecked}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`${this.props.match.path}/edit/${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{item.category}</TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                        <TableCell>{item.price}</TableCell>
                                        <TableCell>{item.id}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={13}>
                                    <Pagination
                                        total_recordsCount={this.state.total_records}
                                        onChange={() => { }}
                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </List>
            );
        }
    }
}

export default withRouter(ItemList);
