import React from 'react';

import Table from 'react-bootstrap/Table';

const table = (props) => {
    let { hScroll = false, vScroll = false } = props;
    let styleClasses = []
    if (hScroll && vScroll) { styleClasses.push("auto-scroll") }
    else if (hScroll) { styleClasses.push("h-scroll") }
    else if (vScroll) { styleClasses.push("v-scroll") }
    if (Array.isArray(props.divClassNames)) styleClasses.concat(props.divClassNames)

    return (
        <div className={styleClasses}>
            <Table style={props.style || {}} className={Array.isArray(props.className) ? props.className.join(' ') : ''} responsive={props.responsive || "lg"}>
                {props.children}
            </Table>
        </div>
    );
}

const TableHead = (props) => {
    return (
        <thead className={Array.isArray(props.className) ? props.className.join(' ') : ''}>
            {props.children}
        </thead>
    );
}

const TableBody = (props) => {
    return (
        <tbody className={Array.isArray(props.className) ? props.className.join(' ') : ''}>
            {props.children}
        </tbody>
    );
}

const TableFooter = (props) => {
    return (
        <tfoot className={Array.isArray(props.className) ? props.className.join(' ') : ''}>
            {props.children}
        </tfoot>
    );
}

const TableHeading = (props) => {
    return (
        <th
            className={Array.isArray(props.className) ? props.className.join(' ') : ''}
            id={props.id}
            width={props.width || ''}
            onClick={props.onClick}
            rowSpan={props.rowSpan || ""}
            colSpan={props.colSpan || ""}
        >
            {props.children}
        </th>
    );
}

const TableRow = (props) => {
    return (
        <tr className={Array.isArray(props.className) ? props.className.join(' ') : ''}
            onClick={props.onClick}
            onBlur={props.onBlur}
            style={props.style}
            colSpan={props.colSpan || ""}
        >
            {props.children}
        </tr>
    );
}

const TableCell = ({ className, colSpan, children, ...rest }) => {
    return (
        <td
            className={Array.isArray(className) ? className.join(' ') : ''}
            colSpan={colSpan || ""}
            {...rest}
        >
            {children}
        </td>
    );
}

export default table;
export {
    TableHead,
    TableBody,
    TableFooter,
    TableHeading,
    TableRow,
    TableCell
};
