import React from 'react';
import { Modal } from 'react-bootstrap';
import { Import } from '../themes/default/Buttons/MultiButtons';
import Label from '../themes/default/Label/Label';
import File from '../themes/default/Inputs/File';
const ImportModal = props => {
    let value = [];
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Import
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.message}
                <div className="border border-light float-left w-100 pt-3 pb-3">
                    <div className="form-group col-sm-12 text-left" >
                        <Label className={["d-inline-block", "col-sm-3"]}>
                            {"Import File"}
                        </Label>
                        <File
                            className={["form-control", "col-sm-9", "d-inline-block"]}
                            id="import_file"
                            onChange={(event) => value = (event.target.files[0])}
                        />
                        <Import title={"Import"} onClick={() => props.handleImport(value)} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default ImportModal;