import React, { Component } from 'react';
import Form from '../../../../themes/default/ViewWrappers/Form'
import { Store } from '../../../../GlobalProvider';
import * as ActionTypes from '../../../../themes/default/MessageBoard/actions';
import HeaderWrapper from '../../../../themes/default/HeaderWrapper'
import { Submit, Exit, Update } from '../../../../themes/default/Buttons/MultiButtons'
import TextField from '../../../../themes/default/Inputs/Inputs';
import CheckBox from '../../../../themes/default/Inputs/Checkbox';
import File from '../../../../themes/default/Inputs/File';
import Calendar from '../../../../themes/default/DatePicker/DatePicker';
import Label from '../../../../themes/default/Label/Label';
import Helper, { dateFormat } from '../../../Common/Helper';
import AccountService from '../AccountService';
import FormValidation from '../../../../themes/default/assets/js/FormValidation';
class Edit extends Component {
    static contextType = Store;
    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: parseInt(this.props.match.params.id) || 0,
                name: '',
                bank_name: '',
                account_number: '',
                balance: ''
            }
        };
    }
    componentDidMount() {
        if (this.state.data.id !== 0) {
            this.getDetails(this.state.data.id);
        }
    }

    async getDetails(id) {
        const result = await AccountService.get(id, this);
        if (result) {
            this.setState({ data: result });
        }

    }

    handleChange = event => {
        let field = event.target.id;
        let data = this.state.data;
        data[field] = event.target.value;
        this.setState({ data: data });
    };

    submitForm = async event => {
        event.preventDefault();
        let validation = new FormValidation();
        for (let key in this.state.data) {
            let result = validation.validate(key, this.state.data[key]);
            if (!result.flag) {
                alert(result.message);
                return;
            }
        }
        let datas = this.state.data;
        let result = await AccountService.store(datas, this);
        if (result) {
            this.setState({ data: result });
            this.props.history.push('/account/edit/' + this.state.data.id);
        }

    };
    render() {
        let submitButton = null;
        if (this.state.data.id != 0) {
            submitButton = <Update onClick={this.submitForm} />;
        } else {
            submitButton = <Submit onClick={this.submitForm} />;
        }
        return (
            <Form>
                <HeaderWrapper title={`Account (${this.state.data.id ? 'Edit' : 'New'})`}>
                    {submitButton}
                    <Exit onClick={() => { this.props.history.push('/account'); }} />
                </HeaderWrapper>

                {/* Left side div  */}
                <div className='col-sm-6'>
                    <div className='form-group col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']} id='Name'>
                            {'Name *'}
                        </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            name='name'
                            id='name'
                            placeholder='Enter Name'
                            onChange={this.handleChange}
                            value={`${this.state.data.name}`}
                        />
                    </div>

                    <div className='form-group  col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']}>{'Bank Name *'} </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            id='bank_name'
                            placeholder='Enter Bank Name'
                            value={this.state.data.bank_name}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                {/* Right side div */}
                <div className='col-sm-6'>
                    <div className='form-group  col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']}>{'Account No. *'} </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            id='account_number'
                            placeholder='Enter Account No.'
                            value={this.state.data.account_number}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className='form-group col-sm-12 text-left'>
                        <Label className={['d-inline-block', 'col-sm-3']} id='balance'> {'Balance *'} </Label>
                        <TextField
                            className={['form-control', 'col-sm-9', 'd-inline-block']}
                            name='balance'
                            id='balance'
                            placeholder='Enter Balance'
                            onChange={this.handleChange}
                            value={`${this.state.data.balance}`}
                        />
                    </div>

                </div>
            </Form>
        );
    }
}

export default Edit;